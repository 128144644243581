import React, { Component } from "react";
import { decodeToken } from "../../../services/auth";
import Layout from "../../../components/Layout";
import api from "../../../services/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import CardBody from "../../../components/CardBody";
import Form from "react-bootstrap/Form";
import Loading from "../../../components/Loading/index";
import Button from "react-bootstrap/Button";
import Modal from "../../../components/Modal";
import MaterialIcon from "material-icons-react";
import swal from "sweetalert";

export default class TaskClient extends Component {

    state = {
        session: decodeToken(),
        tasks: [],
        task: [],
        client: [],
        clientId: '',
        modal: false,
        loading: false
    }

    componentDidMount() {
        this.getLoad();
    }

    getLoad = async () => {
        let user = this.state.session.user;
        this.setState({ clientId: user.client});
        this.loadTasksClient(user.client);
    }

    loadTasksClient = async (client) => {
        this.setState({ loading: true });
        const response = await api.get(`/task-client/onboarding/${client}`);
        this.setState({ tasks: response.data, loading: false });
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.loadTasksClient(val);
        this.setState({clientId: val})
    }

    toggleModal = (task = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, task: task });
        document.body.classList.toggle("overflow-hidden");
        if(document.querySelectorAll("#text-area")){
            for (let a = 0; a < document.querySelectorAll("#text-area").length; a++){
                document.querySelectorAll("#text-area")[a].value = "";
            }
        }
        document.getElementById("wrapper").classList.toggle("blur");
    }


    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "file") {
            this.setState({
                task: {
                    ...this.state.task,
                    [nam]: event.target.files[0],
                    fileName: val
                }
            });
        } else {
            this.setState({
                task: {
                    ...this.state.task,
                    [nam]: val
                }
            });
        }
    }

    save = async (event) => {
        this.setState({loading: true})
        event.preventDefault();
        let taskClient = this.state.task;
        const formData = new FormData();
        const project = await api.get(`/onboarding-by-client/${this.state.clientId}`);
        if(taskClient.file !== undefined){
            formData.append('file', taskClient.file);
        }
        formData.append('title', taskClient.title);
        formData.append('dateEnd', taskClient.dateEnd);
        formData.append('description', taskClient.description);
        formData.append('projectId', project.data._id);
        formData.append('from', "Cliente");
        if(taskClient.client === undefined){ 
            formData.append('client', this.state.clientId);
        }else{
            formData.append('client', taskClient.client);
        }

        if(taskClient._id === undefined){
            await api.post("/task-client", formData, { headers: { 'content-type': 'multipart/form-data' } })
                .then(response => {
                    swal("Sucesso!", "Tarefa criada com sucesso!", "success");
                    this.loadTasksClient(this.state.clientId);
                })
                .catch(err => {
                    swal("Erro!", "Erro ao criar a tarefa, tente novamente mais tarde.", "error");
                });
        }else{
            formData.append('reply', taskClient.reply);
            await api.put(`/task-client/${taskClient._id}`, formData, { headers: { 'content-type': 'multipart/form-data' } })
                .then(response => {
                    swal("Sucesso!", "Tarefa criada com sucesso!", "success");
                    this.loadTasksClient(this.state.clientId);
                })
                .catch(err => {
                    swal("Erro!", "Erro ao criar a tarefa, tente novamente mais tarde.", "error");
                });
        }
        this.toggleModal();
    }

    render() {
        const {tasks, session} = this.state;
        const taskClient = this.state.task;

        return (
            <>
            <Layout>
                <Container fluid>
                    <Row className="mb-4 ">
                        <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                            <Header title="Minhas Tarefas" btn={true} callbackParent={this.toggleModal} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {tasks.map((item, index) => (
                                <CardBody key={index}>
                                    <Row>
                                        <Col xs={12} sm={12} className="justify-content-end text-right">
                                            <span className={(item.status) === "Pendente" ? "text-danger font-weight-bold" : "text-success font-weight-bold"}>{item.status}</span>
                                            {(item.from !== "Cliente" && item.status !== "Entregue") ? <span className="c-pointer ml-3 text-primary font-weight-bold no-hover text-uppercase small" onClick={() => this.toggleModal(item)}>Responder</span>
                                            : <></>}
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center text-muted py-3">
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Título:</span>{item.title}
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Prazo de entrega:</span>{item.dateEnd}
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Origem:</span>{item.from}
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={3} className="mt-sm-4">
                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Descrição:</span>{item.description}
                                        </Col>
                                        {(item.hasOwnProperty('file') !== false && item.file !== '') ?
                                            <Col xs={12} sm={3} className="mt-sm-4">
                                                <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Anexo:</span><a className="no-hover text-muted" target="_blank" rel="noopener noreferrer" href={item.file}>Clique aqui.</a>
                                            </Col>
                                        : ""}
                                    </Row>
                                </CardBody>
                            ))}


                            {(tasks.length === 0) ?
                                <p className="text-center text-muted mt-5">Nenhuma tarefa encontrada.</p>
                                : <></>}
                        </Col>
                    </Row>
                </Container>
            </Layout>
            <Modal show={this.state.modal} id="myForm">
                <Row className="mb-4">
                    <Col xs={6} className="d-flex align-items-center justify-content-start">
                        <h5 className="mb-0 text-primary font-weight-bold"> {(taskClient._id !== undefined) ? "Editar Tarefa" : "Nova Tarefa"}</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center justify-content-end">
                        <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                    </Col>
                </Row>
                {(taskClient._id === undefined) ? 
                <Form onSubmit={this.save} id="myForm">
                    <Row>
                        <Col xs={12} sm={(session.user.client.length > 1) ? 4 : 6} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Nome</Form.Label>
                            <Form.Control type="text" name="title" defaultValue={taskClient.title} onChange={(event) => this.myChangeHandler(event)} required />
                        </Col>
                        <Col xs={12} sm={(session.user.client.length > 1) ? 4 : 6} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Prazo de Entrega</Form.Label>
                            <Form.Control type="date" name="dateEnd" defaultValue={taskClient.dateEnd} onChange={(event) => this.myChangeHandler(event)} required />
                        </Col>
                    {/* {((session.user.client.length > 1) ? 
                        <Col xs={12} sm={4} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Cliente</Form.Label>
                            <Form.Control as="select" name="client" value={taskClient.client} onChange={this.myChangeHandler} required >
                                <option></option>
                                {session.user.client.map((item, index) => (
                                    <option key={index} value={item._id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        : <></>
                    )} */}
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Arquivo</Form.Label>
                            <label htmlFor="file" className="c-pointer form-label w-100 border mb-0 d-flex align-items-center justify-content-between">
                                <span className="ml-2 color-grey" style={{ fontSize: '12px' }}>{(taskClient._id === undefined) ? taskClient.fileName : ((taskClient.fileKey !== undefined) ? taskClient.fileKey.substring(0, 30) : taskClient.fileName)}</span>
                                <span className="p-2 bkg-primary color-white">Selecionar Arquivo</span>
                                <input type="file" name="file" id="file" className="d-none" onChange={(e) => this.myChangeHandler(e)} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Descrição</Form.Label>
                            <Form.Control rows="5" id="text-area" as="textarea" name="description" value={taskClient.description} onChange={(event) => this.myChangeHandler(event)} required />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" className="small" type="submit">
                                {(taskClient._id !== undefined) ? "Salvar" : "Adicionar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                : 
                <Form onSubmit={this.save} id="myForm">
                    <Row>
                        <Col xs={12} sm={12} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Descrição</Form.Label>
                            <p className="text-muted">{taskClient.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} className="mb-3">
                            <Form.Label className="text-primary font-weight-bold small mb-2">Resposta</Form.Label>
                            <Form.Control rows="5" id="text-area" as="textarea" name="reply" value={taskClient.reply} onChange={(event) => this.myChangeHandler(event)} required />
                        </Col>
                    </Row>
                        <Row className="mt-4">
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" className="small" type="submit">
                                {(taskClient._id !== undefined) ? "Salvar" : "Adicionar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                }
            </Modal>
            <Loading show={this.state.loading} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class ClientAnalytics extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        load: false,
        analytics: [],
        client: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({load: true});
        const { id } = this.props.match.params;
        const response = await api.get(`/analytics-client/${id}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({
            load: false,
            arr: docs,
            arrInfo,
            page
        });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            analytics: {
                ...this.state.analytics,
                [nam]: val
            }
        });
    }

    toggleModal = (analytics = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, analytics: analytics });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (analytics) => {
        swal({ title: "Atenção", text: "Deseja remover este analytics?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/analytics/${analytics._id}`)
                    .then(response => {
                        this.load(this.state.page, this.state.analytics._id);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const analytics = this.state.analytics;
        if (analytics._id !== undefined) {
            await api.put(`/analytics/${analytics._id}`, analytics)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Analytics editado com sucesso." });
                }).catch(error => {
                    this.load();
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o analytics, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            analytics.client = this.state.clientId;
            await api.post(`/analytics`, analytics)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Analytics adicionado com sucesso." });
                }).catch(error => {
                    this.load();
                    swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar o analytics, tente novamente mais tarde." });
                    console.log(error)
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const {client, modal, analytics} = this.state;        
        const ana = this.state.arr;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Analytics" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Analytics</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Link
                                        </Col>
                                        <Col xs={12} sm={3}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                            {(this.state.arrInfo.total > 0) ? 
                            ana.map((analytics, index) => (
                                <CardBody key={index}>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={6}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{analytics.name}
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Acessar:</span>
                                            {(analytics.hasOwnProperty('link') !== false && analytics.link !== '') ? <a href={analytics.link} target="_blank" rel="noopener noreferrer" className="not-underline">Clique aqui</a> : "Ignorado"}
                                        </Col>
                                        <Col xs={12} sm={3} className="d-flex justify-content-end align-items-center">
                                            <span className="c-pointer ml-3" onClick={() => this.toggleModal(analytics)}><MaterialIcon icon="edit" /></span>
                                            <span className="c-pointer ml-3" onClick={() => this.delete(analytics)}><MaterialIcon icon="close" /></span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            ))
                            :
                                <CardBody>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={12} className="text-center">
                                            Não há analytics cadastrado.
                                        </Col>
                                    </Row>
                                </CardBody>
                            }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(analytics._id !== undefined) ? "Editar analytics" : "Adicionar analytics"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Dashboard</Form.Label>
                                <Form.Control as="select" name="name" value={analytics.name} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Blackfriday", "Blog", "Dashboard Principal", "Desempenho da Plataforma", "E-commerce", "Facebook Ads", "Facebook Content", "Google Ads", "Instagram", "Newsletter", "Vendas Dia"].map((item, index) => (
                                        <option value={item} key={index}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Link</Form.Label>
                                <Form.Control type="url" name="link" value={analytics.link} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
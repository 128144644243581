import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import runrun_it from "../../services/runrunit";
import Loading from "../../components/Loading/index";

const styles = {
    ul: {
        listStyle: "none"
    },
    img: {
        width: "2.25rem",
        height: "2.25rem",
        borderRadius: "50%",
        border: "2px solid #f8f9fa",
        marginRight: "-10px"
    }
}

export default class UserOneClickView extends Component {

    state = {
        session: decodeToken(),
        users: [],
        clients: [],
        modal: false,
        load: false,
        client: [],
        user: [],
        teams: [],
        companyId: '',
        teamId: '',
        buttonName: "Adicionar"
    }

    componentDidMount() {
        this.getLoad();
    }

    getLoad = async () => {
        this.getInfomation();
        this.getTeams(process.env.REACT_APP_RUNRUNIT_APP_KEY,process.env.REACT_APP_RUNRUNIT_USER_TOKEN);
        this.getClients()

    }

    getInfomation = async (teamId = this.state.teamId) => {
        this.setState({ load: true });
        let session = this.state.session;
        const response = await api.get(`/user-all-run?company=${session.user.company._id}&teamId=${teamId}`);
        this.setState({ users: response.data, load: false });
    }

    getClients = async () => {
        let session = this.state.session;
        const clients = await api.get(`/client-all?company=${session.user.company._id}`);
        this.setState({ clients: clients.data });
    }

    getTeams = async () => {
        const response = await runrun_it.get(`teams`);
        this.setState({ teams: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let matches = this.state.user.client.filter(v => v._id.includes(val));
        if (matches.length > 0) {
            this.setState({ buttonName: "Remover" })
        } else {
            this.setState({ buttonName: "Adicionar" })
        }
        this.setState({
            client: { [nam]: val }
        });
    }


    toggleModal = (user = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, user: user });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        await api.put(`/user-toggle-client/${this.state.user._id}`, this.state.client)
            .then(response => {
                this.setState({ load: false });
                this.getInfomation()
            })
            .catch(error => {
                this.setState({ load: false });
                this.getInfomation()
                console.log({ error })
            });
        this.toggleModal();
    }

    render() {
        const { users, clients, modal, teams = [], session } = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={10} className="text-left">
                                <Header title="One Click View" />
                            </Col>
                            <Col xs={12} sm={2} className="text-right">
                                <div className="input-group mb-3">
                                    <Form.Control as="select" id="dropdown-basic-button22" value={this.state.teamId} className="dropdown-filter" onChange={(event) => { this.setState({ teamId: event.target.value }); this.getInfomation(event.target.value) }} required>
                                        <option value='' disabled selected>Filtro setor</option>
                                        <option value=''>Geral</option>
                                        {teams.map((team, index) => (
                                            <option key={index} value={team.id}>{team.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Setor
                                        </Col>
                                        {(((session.user.role === "admin" || session.user.role === "admin-company" || session.user.role === "manager")) &&
                                            <Col xs={12} sm={1}>
                                                Hrs/mês
                                        </Col>
                                        )}
                                        <Col xs={12} sm={((session.user.role === "admin" || session.user.role === "admin-company" || session.user.role === "manager")) ? 6 : 7}>
                                            Clientes
                                        </Col>
                                        <Col xs={12} sm={1}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {users.map((user, index) => {
                                    const userClients = user.client.sort(function (a, b) { var textA = a.name.toUpperCase(); var textB = b.name.toUpperCase(); return (textA < textB) ? -1 : (textA > textB) ? 1 : 0; });

                                    return (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{user.name}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Setor:</span>{user.teamName}
                                                </Col>
                                                {(((session.user.role === "admin" || session.user.role === "admin-company" || session.user.role === "manager")) &&
                                                    <Col xs={12} sm={1}>
                                                        <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Hrs/mês:</span>{(user.workedHours !== undefined) ? (user.workedHours / 3600).toFixed(2) : '0.00'}
                                                    </Col>
                                                )}
                                                <Col xs={12} sm={((session.user.role === "admin" || session.user.role === "admin-company" || session.user.role === "manager")) ? 6 : 7}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Clientes:</span>
                                                    {userClients.map((userClient, i) => (
                                                        <li key={i} className="d-inline-block">
                                                            <Link to={`/clientes/${userClient._id}/dashboard`}>
                                                                <img src={(userClient.avatar !== undefined) ? userClient.avatar : "https://my.jobsx.com.br/static/media/jobsx-gradient.b429725a.svg"} style={styles.img} title={userClient.name} alt={userClient.name} />
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </Col>
                                                <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModal(user)}>adicionar/remover</span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Adicionar ou remover cliente</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente</Form.Label>
                                <Form.Control as="select" name="client" onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {clients.map((client, index) => (
                                        <option key={index} value={client._id}>{client.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {this.state.buttonName}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
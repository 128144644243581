import axios from "axios";
require('dotenv').config();

const runrunit = axios.create({
    baseURL: process.env.REACT_APP_RUNRUNIT_BASE_URL
});

runrunit.interceptors.request.use(async config => {
    config.headers = {
        "App-Key": process.env.REACT_APP_RUNRUNIT_APP_KEY,
        "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
    }
    return config;
});

export default runrunit;
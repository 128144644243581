import React, { Component } from "react";
import { Link } from "react-router-dom";
import { decodeToken } from "../../services/auth";
import api from "../../services/api";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import LogoGradient from "../../assets/img/jobsx-gradient.svg";
import Loading from "../../components/Loading/index";
import swal from "sweetalert";
require('dotenv/config');

export default class Login extends Component {

    state = {
        user: {
            email: '',
            password: '',
        },
        session: decodeToken(localStorage.getItem('TOKEN_KEY')),
        error: true,
        loading: true,
        message: ''
    }

    componentDidMount() {
        //localStorage.clear();
        const { session } = this.state;
        if (session) {
            const { user = [] } = session;
            if (typeof user.company === "string") {
                window.location.href = "/dashboard";
            }
            if (typeof user.company === "object") {
                window.location.href = "/selecionar/agencia";
            }
        }
        document.getElementsByTagName('html')[0].classList.add("h-100");
        document.getElementsByTagName('body')[0].classList.add("h-100");
        document.getElementById('root').classList.add("h-100");
        this.setState({ loading: false })
    }

    changeUserToken = async (session) => {
        await api.post(`/create_token`, session)
            .then(response => {
                localStorage.setItem("TOKEN_KEY", response.data.token)
            }).catch(error => {
                console.log(error);
                swal({ icon: "error", title: "Erro!", text: "Erro ao selecionar Agência, tente novamente mais tarde." });
            });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            user: {
                ...this.state.user,
                [nam]: val
            }
        });
    }

    login = async (event) => {
        event.preventDefault();
        this.setState({ message: '' })
        await api.post("/login", this.state.user)
            .then(response => {
                localStorage.setItem("TOKEN_KEY", response.data.token);
                window.location.href = "/selecionar/agencia";
            }).catch(err => {
                this.setState({ error: true, message: "Usuário ou senha inválidos." });
            });
    }

    render() {
        return (
            <div className="login h-100">
                <Container className="h-100">
                    <Row className="h-100 align-items-stretch">
                        <Col xs={12} sm={12} className="d-flex align-items-start">
                            <Navbar className="fixed-top justify-content-start">
                                <Navbar.Brand href="https://www.jobsx.com.br"><img src={LogoGradient} className="img-fluid" alt="" /></Navbar.Brand>
                            </Navbar>
                        </Col>
                        <Col xs={12} sm={5} className="d-flex align-items-center ml-auto">
                            <div className="w-100">
                                <h1 className="pb-4">Área X</h1>
                                <p className="pb-3">Acompanhe os resultados dos clientes X.</p>
                                <Form className="w-100" onSubmit={this.login}>
                                    <Form.Group className="pb-3">
                                        <Form.Control type="email" placeholder="Seu e-mail" name="email" onChange={this.myChangeHandler} required />
                                    </Form.Group>
                                    <Form.Group className="pb-3">
                                        <Form.Control type="password" placeholder="Sua senha" name="password" onChange={this.myChangeHandler} required />
                                    </Form.Group>
                                    <Row className="py-4">
                                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                                            <Link to={"/esqueceu-sua-senha"}>Esqueceu sua senha?</Link>
                                        </Col>
                                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                                            <Button variant="primary" type="submit" className="text-uppercase">
                                                Entrar
                                            </Button>
                                        </Col>
                                        {(this.state.error) ? (
                                            <Col xs={12} className="d-flex align-items-center justify-content-center pt-5">
                                                <small className="text-danger">{this.state.message}</small>
                                            </Col>
                                        ) : <></>}
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <span>JobsX {new Date().getFullYear()} &copy; CNPJ 35.031.143/0001-45. Todos os direitos reservados. Desenvolvido por: <a href="https://www.domatech.com.br">Domatech</a></span>
                        </Col>
                    </Row>
                </Container>
                <Loading show={this.state.load} />
            </div>
        );
    }

}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import { formatDate, secondsToHms } from "../../services/utils";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Month from "../../components/Month";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Select from 'react-select';
import Loading from "../../components/Loading/index";
import runrun_it from "../../services/runrunit";

export default class OnboardingTask extends Component {

    state = {
        session: decodeToken(),
        onboarding: [],
        modalRunrunIt: false,
        modalClient: false,
        modalView: false,
        types: [],
        load: false,
        page: 1,
        arrInfo: [],
        count: '',
        users: [],
        usuarios: [],
        taskRunrunIt: [],
        task: [],
        comments: [],
        checklist: [],
        tasksCreatedInOnboardingRunrunIt: [],
        tasksInMonthInOnboardingRunrunIt: [],
        year_month: new Date().getFullYear(),
        taskClient: [],
        clearTaskClient: { dateEnd: null },
        tasksCreatedInOnboardingClient: []
    }

    componentDidMount() {
        this.getOnboarding();
        this.getCompany();
        this.loadTasksRunrunIt();
        this.loadTasksMonthRunrunIt();
        this.loadTasksClient();
    }

    getOnboarding = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/onboarding/${id}`);
        this.setState({ onboarding: response.data });
    }

    getCompany = async () => {
        const { user } = this.state.session;
        const response = await api.get(`/company/${user.company}`);
        this.loadUsers(response.data._id);
        this.loadTypes(response.data.runrunit_app_key, response.data.runrunit_user_token);
    }

    loadUsers = async (companyId) => {
        const response = await api.get(`/user-all-run?company=${companyId}`);
        const options = [];
        response.data.forEach(item => {
            options.push({
                value: item._id,
                label: item.name
            });
        });
        this.setState({ users: options });
    }

    loadTypes = async (appKey, userToken) => {
        const response = await runrun_it.get(`/task_types`);
        this.setState({ types: response.data });
    }

    toggleModalRunrunIt = (taskRunrunIt = {}) => {
        this.setState({ modalRunrunIt: !this.state.modalRunrunIt });
    }

    toggleModalClient = (taskClient = {}) => {
        document.getElementById("myFormNew").reset();
        document.getElementById("date-end").value = null;
        for (let a = 0; a < document.querySelectorAll("#text-area").length; a++) {
            document.querySelectorAll("#text-area")[a].value = "";
        }
        this.setState({ modalClient: !this.state.modalClient, taskClient: taskClient })
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('/');
    }

    myChangeHandlerTaskClient = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            taskClient: {
                ...this.state.taskClient,
                [nam]: val
            }
        });
    }

    myChangeHandlerTaskRunrunIt = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({
            taskRunrunIt: {
                ...this.state.taskRunrunIt,
                [nam]: val
            }
        });
    }

    handleAssignments = async (usuarios) => {
        if (usuarios !== null && usuarios.length > 0) {
            const response = await api.get(`/user/${usuarios[usuarios.length - 1].value}`);
            usuarios[usuarios.length - 1].team_id = response.data.teamId;
            usuarios[usuarios.length - 1].assignee_id = response.data.userId;
            this.setState({ usuarios });
        } else {
            this.setState({ usuarios: [] });
        }
    }

    createTaskRunrunIt = async (event) => {
        this.setState({ load: true });
        event.preventDefault();
        const { id } = this.props.match.params;
        let taskRunrunIt = this.state.taskRunrunIt;
        taskRunrunIt.projectId = id;
        taskRunrunIt.assignments = this.state.usuarios;
        let a = [];
        for (let i = 0; i < this.state.usuarios.length; i++) {
            a.push(this.state.usuarios[i].value)
        }
        taskRunrunIt.users = a;
        await api.post(`/task`, taskRunrunIt)
            .then(response => {
                swal("Sucesso!", "Tarefa criada com sucesso!", "success");
                this.setState({ load: false });
                this.loadTasksRunrunIt(this.state.page);
                this.loadTasksMonthRunrunIt();
            })
            .catch(err => {
                this.setState({ load: false });
                swal("Erro!", "Erro ao criar a tarefa, tente novamente mais tarde.", "error");
            });
        this.toggleModalRunrunIt();
    }

    loadTasksRunrunIt = async (page = 1) => {
        const { id } = this.props.match.params;
        const onboarding = await api.get(`/onboarding/${id}`);
        const response = await runrun_it.get(`/tasks?project_id=${onboarding.data.projectId}&sort=desired_date&sort_dir=asc&limit=10&page=${page}`);
        const count = await runrun_it.get(`/tasks?project_id=${onboarding.data.projectId}`);
        let arr = []
        arr.pages = parseFloat((count.data.length) / 10);
        if (arr.pages <= 1) {
            arr.pages = 1;
        }
        if (arr.pages > parseInt(arr.pages)) {
            arr.pages = parseInt((arr.pages + 1).toFixed())
        }
        this.setState({ tasksCreatedInOnboardingRunrunIt: response.data, load: false, arrInfo: arr, count: count.data.length });
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadTasksRunrunIt(pageNumber);
        this.setState({ page: pageNumber })
    }

    nextPage = () => {
        const { page } = this.state;
        if (page > this.state.count / 10) return;
        const pageNumber = page + 1;
        this.loadTasksRunrunIt(pageNumber);
        this.setState({ page: pageNumber })
    }

    deleteTasksRunrunIt = async (task) => {
        const { id } = this.props.match.params;
        swal({ title: "Atenção", text: "Deseja remover esta tarefa?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                this.setState({ load: true });
                if (res) {
                    await api.delete(`/task/${task.id}?onboarding=${id}`)
                        .then(response => {
                            swal("Sucesso!", "Tarefa removida com sucesso!", "success");
                            this.setState({ load: false });
                            this.loadTasksRunrunIt();
                            this.loadTasksMonthRunrunIt();
                        }).catch(error => {
                            swal("Erro!", "Erro ao remover a tarefa, tente novamente mais tarde.", "error");
                            this.setState({ load: false });
                        });
                }
                this.setState({ load: false });
            });
    }

    loadTasksMonthRunrunIt = async (year_month = this.state.year_month) => {
        this.setState({ load: true });
        const { id } = this.props.match.params;
        const onboarding = await api.get(`/onboarding/${id}`);
        const jan = [], fev = [], mar = [], abr = [], mai = [], jun = [], jul = [], ago = [], set = [], out = [], nov = [], dez = [];
        const response = await runrun_it.get(`/tasks?project_id=${onboarding.data.projectId}&sort=estimated_delivery_date&sort_dir=asc&bypass_status_default=true`);
        response.data.forEach(task => {
            if (new Date(task.scheduled_start_time).getFullYear() === parseInt(year_month)) {
                switch (new Date(task.scheduled_start_time).getMonth()) {
                    case 0:
                        jan.push(task);
                        break;
                    case 1:
                        fev.push(task);
                        break;
                    case 2:
                        mar.push(task);
                        break;
                    case 3:
                        abr.push(task);
                        break;
                    case 4:
                        mai.push(task);
                        break;
                    case 5:
                        jun.push(task);
                        break;
                    case 6:
                        jul.push(task);
                        break;
                    case 7:
                        ago.push(task);
                        break;
                    case 8:
                        set.push(task);
                        break;
                    case 9:
                        out.push(task);
                        break;
                    case 10:
                        nov.push(task);
                        break;
                    case 11:
                        dez.push(task);
                        break;
                    default:
                        break;
                }
            }
        });
        this.setState({ tasksInMonthInOnboardingRunrunIt: { jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez }, load: false });
    }

    nextMonth = () => {
        let year = parseInt(this.state.year_month);
        year++;
        this.setState({ year_month: year });
        this.loadTasksMonthRunrunIt(year);
    }
    backMonth = () => {
        let year = parseInt(this.state.year_month);
        year--;
        this.setState({ year_month: year });
        this.loadTasksMonthRunrunIt(year);
    }

    createTaskClient = async (event) => {
        this.setState({ load: true });
        event.preventDefault();
        const { id } = this.props.match.params;
        let taskClient = this.state.taskClient;
        if (taskClient._id !== undefined) {
            if (taskClient.reply !== "") {
                taskClient.status = "Entregue";
            }
            await api.put(`/task-client/${taskClient._id}`, taskClient)
                .then(response => {
                    swal("Sucesso!", "Tarefa editada com sucesso!", "success");
                    this.loadTasksClient();
                    this.setState({ load: false });
                })
                .catch(err => {
                    this.loadTasksClient();
                    swal("Erro!", "Erro ao editar a tarefa, tente novamente mais tarde.", "error");
                    this.setState({ load: false });
                });
        } else {
            taskClient.projectId = id;
            taskClient.from = "Admin"
            await api.post(`/task-client`, taskClient)
                .then(response => {
                    swal("Sucesso!", "Tarefa criada com sucesso!", "success");
                    this.loadTasksClient();
                    this.setState({ load: false });
                })
                .catch(err => {
                    this.loadTasksClient();
                    swal("Erro!", "Erro ao criar a tarefa, tente novamente mais tarde.", "error");
                    this.setState({ load: false });
                });
        }
        this.toggleModalClient();
    }

    loadTasksClient = async () => {
        this.setState({ load: true });
        const { id } = this.props.match.params;
        const response = await api.get(`/task-client/onboarding/${id}`);
        this.setState({ tasksCreatedInOnboardingClient: response.data });
    }

    deleteTasksClient = async (task) => {
        swal({ title: "Atenção", text: "Deseja remover esta tarefa?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                this.setState({ load: true });
                if (res) {
                    await api.delete(`/task-client/${task._id}`)
                        .then(response => {
                            swal("Sucesso!", "Tarefa removida com sucesso!", "success");
                            this.setState({ load: false });
                            this.loadTasksClient();
                        }).catch(error => {
                            swal("Erro!", "Erro ao remover a tarefa, tente novamente mais tarde.", "error");
                            this.setState({ load: false });
                        });
                }
            });
    }

    deliverTask = async (task) => {
        swal({ title: "Atenção", text: "Deseja entregar esta tarefa?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                this.setState({ load: true });
                if (res) {
                    await api.put(`/task-client/${task._id}`, { status: "Entregue" })
                        .then(response => {
                            swal("Sucesso!", "Tarefa entregue com sucesso!", "success");
                            this.setState({ load: false });
                            this.loadTasksClient();
                        }).catch(error => {
                            swal("Erro!", "Erro ao entregar a tarefa, tente novamente mais tarde.", "error");
                            this.setState({ load: false });
                        });
                }
                this.setState({ load: false });
            });
    }

    toggleModalView = async (task = {}) => {
        if (task.id !== undefined) {
            this.setState({ load: true });
            const response = await runrun_it.get(`/tasks/${task.id}/comments`);
            if (task.checklist_id !== null) {
                const checklist = await runrun_it.get(`/checklists/${task.checklist_id}/items`)
                this.setState({ checklist: checklist.data })
            } else {
                this.setState({ checklist: [] })
            };
            this.setState({ comments: response.data });
        }
        this.setState({ load: false, modalView: !this.state.modalView, task: task });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    render() {
        const { task, checklist, comments, onboarding, modalRunrunIt, modalClient, types, users, usuarios, taskRunrunIt, tasksCreatedInOnboardingRunrunIt, tasksCreatedInOnboardingClient, arrInfo, tasksInMonthInOnboardingRunrunIt, year_month, taskClient } = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title={onboarding.name}>
                            <Button variant="info" className="text-white rounded-0" onClick={() => this.toggleModalClient()}>Nova Tarefa Cliente</Button>
                            <Button variant="danger" className="text-white rounded-0 ml-2" onClick={() => this.toggleModalRunrunIt()}>Nova Tarefa Runrun.it</Button>
                        </Header>
                        {/* <Row className="mb-4">
                            <Col xs={12} sm={6} className="d-flex align-items-center justify-content-start">
                                <h2 className="mb-0 text-primary">{onboarding.name}</h2>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex align-items-center justify-content-center justify-content-sm-end mt-4 mt-sm-0">
                                <Button variant="info" className="text-white rounded-0" onClick={() => this.toggleModalClient()}>Nova Tarefa Cliente</Button>
                                <Button variant="danger" className="text-white rounded-0 ml-2" onClick={() => this.toggleModalRunrunIt()}>Nova Tarefa Runrun.it</Button>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={12}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row className="mb-3">
                                        <Col>
                                            <Nav variant="pills" className="nav nav-fill">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Tarefas do Runrun.it</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" >Calendário</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third" >Tarefas do Cliente</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <CardHeader>
                                                                <Row>
                                                                    <Col xs={12} sm={3}>
                                                                        Título
                                                                    </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Status
                                                                    </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Tipo
                                                                    </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Data de Início
                                                                    </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Entrega Prevista
                                                                    </Col>
                                                                    <Col xs={12} sm={1}>

                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                        </Col>
                                                        <Col xs={12}>
                                                            {tasksCreatedInOnboardingRunrunIt.map((item, index) => (
                                                                <CardBody key={index} >
                                                                    <Row className="align-items-center text-muted">
                                                                        <Col xs={12} sm={3}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Título:</span>{item.title}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span>{item.task_status_name}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo:</span>{item.type_name}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data de início:</span>
                                                                            {/* {(item.scheduled_start_time !== null) ? new Date(item.scheduled_start_time).toLocaleDateString() : new Date(item.estimated_at).toLocaleDateString()} */}
                                                                            {(item.scheduled_start_time !== null) ? new Date(item.scheduled_start_time).toLocaleDateString() : <span className="text-weight-bold text-danger">Não informado.</span>}

                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Entrega prevista:</span>
                                                                            <span className={item.overdue !== "on_schedule" ? "font-weight-bold text-warning" : ""}>
                                                                                {(item.desired_date) ? item.desired_date : (item.stage_depart_estimated_at !== null) ? new Date(item.stage_depart_estimated_at).toLocaleDateString() : <span className="font-weight-bold text-danger">Não informado.</span>}
                                                                            </span>
                                                                        </Col>
                                                                        <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                                            {/* <span className="c-pointer ml-3" onClick={() => this.toggleModal(onboarding)}><MaterialIcon icon="edit" /></span>*/}
                                                                            <span className="c-pointer ml-3" onClick={() => this.toggleModalView(item)}><MaterialIcon icon="remove_red_eye" /></span>
                                                                            <span className="c-pointer ml-3" onClick={() => this.deleteTasksRunrunIt(item)}><MaterialIcon icon="close" /></span>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                    <Pagination page={this.state.page} arrInfo={arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <Row className="d-flex calendar-grid bg-white">
                                                        <Month title={`Janeiro`} checklist={tasksInMonthInOnboardingRunrunIt.jan || []} />
                                                        <Month title={`Fevereiro`} checklist={tasksInMonthInOnboardingRunrunIt.fev || []} />
                                                        <Month title={`Março`} checklist={tasksInMonthInOnboardingRunrunIt.mar || []} />
                                                        <Month title={`Abril`} checklist={tasksInMonthInOnboardingRunrunIt.abr || []} />
                                                        <Month title={`Maio`} checklist={tasksInMonthInOnboardingRunrunIt.mai || []} />
                                                        <Month title={`Junho`} checklist={tasksInMonthInOnboardingRunrunIt.jun || []} />
                                                        <Month title={`Julho`} checklist={tasksInMonthInOnboardingRunrunIt.jul || []} />
                                                        <Month title={`Agosto`} checklist={tasksInMonthInOnboardingRunrunIt.ago || []} />
                                                        <Month title={`Setembro`} checklist={tasksInMonthInOnboardingRunrunIt.set || []} />
                                                        <Month title={`Outubro`} checklist={tasksInMonthInOnboardingRunrunIt.out || []} />
                                                        <Month title={`Novembro`} checklist={tasksInMonthInOnboardingRunrunIt.nov || []} />
                                                        <Month title={`Dezembro`} checklist={tasksInMonthInOnboardingRunrunIt.dez || []} />
                                                    </Row>
                                                    <Row className="mt-3 mb-5">
                                                        <Col xs={6} sm={6} className="px-0 d-flex align-items-center">
                                                            <span className="text-muted small">Ano {year_month}</span>
                                                        </Col>
                                                        <Col xs={6} sm={6} className="d-flex justify-content-end">
                                                            <ButtonGroup>
                                                                <Button onClick={() => this.backMonth()}>Anterior</Button>
                                                                <Button onClick={() => this.nextMonth()}>Próximo</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <Row>
                                                        <Col xs={12}>
                                                            {tasksCreatedInOnboardingClient.map((item, index) => (
                                                                <CardBody key={index}>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} className="justify-content-end text-right">
                                                                            <span className={(item.status) === "Pendente" ? "text-danger font-weight-bold" : "text-success font-weight-bold"}>{item.status}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-center text-muted py-3">
                                                                        <Col xs={12} sm={3}>
                                                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Título:</span>{item.title}
                                                                        </Col>
                                                                        <Col xs={12} sm={3}>
                                                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Prazo de entrega:</span>
                                                                            <span className={(new Date(item.dateEnd).toLocaleDateString() >= new Date().toLocaleDateString()) ? "" : "font-weight-bold text-danger"}>
                                                                                {item.dateEnd}
                                                                            </span>
                                                                        </Col>
                                                                        <Col xs={12} sm={3}>
                                                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Origem:</span>{item.from}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-center text-muted">
                                                                        <Col xs={12} sm={(item.reply !== undefined && item.reply !== '') ? ((item.hasOwnProperty('file') !== false && item.file !== '') ? 3 : 3) : 6} className="mt-sm-4">
                                                                            <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Descrição:</span>{item.description}
                                                                        </Col>
                                                                        {(item.hasOwnProperty('file') !== false && item.file !== '') ?
                                                                            <Col xs={12} sm={(item.reply !== undefined && item.reply !== '') ? 3 : 3} className="justify-content-start mt-sm-4">
                                                                                <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Anexo:</span><a className="no-hover text-muted" target="_blank" rel="noopener noreferrer" href={item.file}>Clique aqui.</a>
                                                                            </Col>
                                                                            : ""}
                                                                        {(item.reply !== undefined && item.reply !== '') ?
                                                                            <Col xs={12} sm={(item.hasOwnProperty('file') !== false && item.file !== '') ? 3 : 3} className="justify-content-start mt-sm-4">
                                                                                <span className="d-inline-flex d-sm-flex text-primary font-weight-bold mr-1 mb-sm-3">Resposta:</span>{item.reply}
                                                                            </Col>
                                                                            : ""}
                                                                        <Col xs={12} sm={(item.reply !== undefined && item.reply !== '') ? ((item.hasOwnProperty('file') !== false && item.file !== '') ? 3 : 6) : 6} className="justify-content-end text-right mt-sm-4">
                                                                            {(item.status !== "Pendente") ? <></> :
                                                                                <>
                                                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModalClient(item)}><MaterialIcon icon="edit" /></span>
                                                                                    <span className="c-pointer ml-3" onClick={() => this.deliverTask(item)}><MaterialIcon icon="done" /></span>
                                                                                </>
                                                                            }
                                                                            <span className="c-pointer ml-3" onClick={() => this.deleteTasksClient(item)}><MaterialIcon icon="close" /></span>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                {/* Modal RUNRUN.IT */}
                <Modal show={modalRunrunIt}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(taskRunrunIt._id !== undefined) ? "Editar tarefa" : "Nova tarefa"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalRunrunIt()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.createTaskRunrunIt} id="myForm">
                        <Row>
                            <Col xs={12} sm={4} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Título</Form.Label>
                                <Form.Control type="text" name="title" value={taskRunrunIt.title} required onChange={(event) => this.myChangeHandlerTaskRunrunIt(event)} />
                            </Col>
                            <Col xs={12} sm={4} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo</Form.Label>
                                <Form.Control as="select" name="typeId" value={taskRunrunIt.typeId} required onChange={(event) => this.myChangeHandlerTaskRunrunIt(event)}>
                                    <option></option>
                                    {types.map((type, index) => (
                                        <option key={index} value={type.id}>{type.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={4} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Atribuição</Form.Label>
                                <Select name="usuarios" placeholder="" value={usuarios} onChange={(event) => this.handleAssignments(event)} options={users} isMulti />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data de Início</Form.Label>
                                <Form.Control type="datetime-local" name="scheduledStartTime" value={taskRunrunIt.scheduledStartTime} required onChange={(event) => this.myChangeHandlerTaskRunrunIt(event)} />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data Desejada</Form.Label>
                                <Form.Control type="datetime-local" name="desiredDate" value={taskRunrunIt.desiredDate} required onChange={(event) => this.myChangeHandlerTaskRunrunIt(event)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Descrição</Form.Label>
                                <Form.Control rows="4" as="textarea" name="description" value={taskRunrunIt.description} required onChange={(event) => this.myChangeHandlerTaskRunrunIt(event)} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(taskRunrunIt._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                {/* Modal CLIENT */}
                <Modal show={modalClient}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold"> {(taskClient._id !== undefined) ? "Editar Tarefa" : "Nova Tarefa"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalClient()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.createTaskClient} id="myFormNew">
                        <Row>
                            <Col xs={12} sm={(taskClient._id !== undefined) ? 4 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome <sup className="ml-1 text-danger">*</sup> </Form.Label>
                                <Form.Control type="text" autoComplete="off" name="title" defaultValue={(taskClient._id !== undefined) ? taskClient.title : null} onChange={(event) => this.myChangeHandlerTaskClient(event)} required />
                            </Col>
                            <Col xs={12} sm={(taskClient._id !== undefined) ? 4 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Prazo de Entrega <sup className="ml-1 text-danger">*</sup> </Form.Label>
                                <Form.Control type="date" autoComplete="off" id="date-end" name="dateEnd" value={taskClient.dateEnd} onChange={(event) => this.myChangeHandlerTaskClient(event)} required />
                            </Col>
                            {(taskClient._id !== undefined) ?
                                <Col xs={12} sm={4} className="mb-3">
                                    <Form.Label className="text-primary font-weight-bold small mb-2">Status <sup className="ml-1 text-danger">*</sup> </Form.Label>
                                    <Form.Control as="select" name="status" defaultValue={(taskClient._id !== undefined) ? taskClient.status : null} onChange={(event) => this.myChangeHandlerTaskClient(event)} required >
                                        <option></option>
                                        {["Pendente", "Entregue"].map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                : ""}
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Descrição <sup className="ml-1 text-danger">*</sup> </Form.Label>
                                <Form.Control rows="5" autoComplete="off" as="textarea" id="text-area" name="description" value={taskClient.description} onChange={(event) => this.myChangeHandlerTaskClient(event)} required />
                            </Col>
                        </Row>
                        {(taskClient._id !== undefined && taskClient.from === "Cliente") ?
                            <Row>
                                <Col xs={12} sm={12} className="mb-3">
                                    <Form.Label className="text-primary font-weight-bold small mb-2">Resposta</Form.Label>
                                    <Form.Control rows="5" autoComplete="off" as="textarea" id="text-area" name="reply" value={taskClient.reply} onChange={(event) => this.myChangeHandlerTaskClient(event)} />
                                </Col>
                            </Row>
                            : ""}
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(taskClient._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={this.state.modalView}>
                    <Row className="mb-4">
                        <Col xs={10} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{task.title} - #{task.id}</h5>
                        </Col>
                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => { this.toggleModalView() }}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Status</h6>
                            <p className="text-muted">{task.task_status_name}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Tipo</h6>
                            <p className="text-muted">{task.type_name}</p>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Data de início</h6>
                            <p className="text-muted">{(task.scheduled_start_time !== null) ? formatDate(task.scheduled_start_time) : <span className="text-weight-bold text-danger">Não informado.</span>}</p>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Entrega Prevista</h6>
                            <span className={task.overdue !== "on_schedule" ? "font-weight-bold text-warning" : ""}>
                                {(task.desired_date !== null) ? formatDate(task.desired_date_with_time) : <span className="font-weight-bold text-danger">Não informado.</span>}
                            </span>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Tempo trabalhado</h6>
                            <p className="text-muted">{secondsToHms(task.time_worked).length > 0 ? secondsToHms(task.time_worked) : "0hrs"}.</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-2">
                            <h6 className="text-primary">Comentários</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="firstModal">
                                <Row className="mb-3">
                                    <Col>
                                        <Nav variant="pills" className="nav nav-fill">
                                            <Nav.Item>
                                                <Nav.Link eventKey="firstModal">Comentários</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled={checklist.length < 1} eventKey="secondModal">Checklist</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey="firstModal">
                                        {comments.map((item, index) => (
                                            <div className="card mb-2" key={index}>
                                                <div className="card-body comments-card">
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <h6 className="font-font-weight-bold">{item.user_id !== null ? `${item.commenter_name}` : "Sistema"}</h6>
                                                        </Col>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-end">
                                                            <h6 className="font-font-weight-bold">{new Date(item.created_at).toLocaleDateString()}</h6>
                                                        </Col>
                                                        <Col xs={12} sm={12}>
                                                            <span className="text-muted">{item.text.replace(/<(.*?)>/g, "")}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="secondModal">
                                        {checklist.map((item, index) => (
                                            <div className="card mb-2" key={index}>
                                                <div className="card-body bg-light comments-card">
                                                    <Row>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-start">
                                                            <span className="text-muted">{item.description}</span>
                                                        </Col>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-end">
                                                            {(item.checked_at !== null) ?
                                                                <span className="text-success"><MaterialIcon icon="check" /></span>
                                                                :
                                                                <span className="text-danger"><MaterialIcon icon="close" /></span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/img/jobsx-white.svg';
import { decodeToken } from "../../services/auth";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import api from "../../services/api";

const styles = {
    layout: {
        background: '#f7f7f7'
    }
}

export default class Sidebar extends Component {

    state = {
        user: decodeToken(),
        sidebar: true,
        company: [],
        client: []
    }

    componentDidMount() {
        this.getCompany();
    }

    getCompany = async () => {
        const { user = [] } = this.state;
        const response = await api.get(`/company/${user.user.company}`);
        this.setState({ company: response.data });
        let clientReponse = ""
        if(user.user.client){
            if (Array.isArray(user.user.client) === false) {
                clientReponse = await api.get(`/client/${user.user.client}`);
            } else {
                clientReponse = await api.get(`/client/${user.user.client[0]}`);
            }
        }
        this.setState({ client: clientReponse.data });
    }

    toggleSidebar = () => this.setState({ sidebar: !this.state.sidebar });

    logout = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    render() {
        const sidebar = this.state.sidebar;
        const user = this.state.user.user;
        const { company = [] } = this.state;
        // const client = this.state.user.user.client;

        return (
            <div className={(sidebar) ? "d-flex" : "d-flex toggled"} id="wrapper">
                <div id="sidebar-wrapper" className="bg-primary">
                    <div className="sidebar-heading d-inline-flex align-items-center justify-content-center my-5 w-100">
                        <img src={Logo} alt="" className="img-fluid" width={130} />
                    </div>
                    <Accordion>
                        {(user.role === "admin" || user.role === "user") ?
                            <>
                                <Card className="border-0 mt-3">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"dashboard"} name={"Dashboard"} link={"/dashboard"} />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"computer"} name={"Projetos"} link="/projetos" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"business"} name={"Clientes"} link="/clientes" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2" className="p-0 text-decoration-none border-0">
                                            <NavItem icon={"sentiment_satisfied_alt"} name={"QBR"} link="#" />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className="py-1">
                                            <Link to={"/reunioes"} className="d-flex ml-5 pb-2">Reuniões</Link>
                                            <Link to={"/reclamacoes"} className="d-flex ml-5 pb-2">Reclamações</Link>
                                            <Link to={"/elogios"} className="d-flex ml-5 pb-2">Elogios</Link>
                                            <Link to={"/fechamento-de-ciclo"} className="d-flex ml-5 pb-2">Fechamento de Ciclo</Link>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </>
                            : <></>}
                        {(user.role === "admin") ?
                            <>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"flight_takeoff"} name={"Onboarding"} link="/onboarding" />
                                    </Card.Header>
                                </Card>
                                <hr style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
                                <Card className="border-0 mt-3">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"domain"} name={"Agências"} link={"/agencias"} />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"group"} name={"Colaboradores"} link="/colaboradores" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"business"} name={"Novos clientes"} link="/novos-clientes" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"article"} name={"Contratos"} link="/contratos" />
                                    </Card.Header>
                                </Card>
                            </>
                            : <></>}
                        {(user.role === "client") ?
                            <>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"home"} name={"Início"} link="/inicio" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"library_books"} name={"Tarefas"} link="/tarefas" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"calendar_today"} name={"Calendário"} link="/calendario" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"star"} name={"Minhas Atividades"} link="/minhas-atividades" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"list"} name={"Relatórios"} link="/relatorios" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"people"} name={"Time"} link="/time" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"insert_chart_outlined"} name={"Analytics"} link="/analytics" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1" className="p-0 text-decoration-none border-0">
                                            <NavItem icon={"sentiment_satisfied_alt"} name={"QBR"} link="#" />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className="py-1">
                                            <Link to={"/minhas-reunioes"} className="d-flex ml-5 pb-2">Reuniões</Link>
                                            <Link to={"/minhas-reclamacoes"} className="d-flex ml-5 pb-2">Reclamações</Link>
                                            <Link to={"/meus-elogios"} className="d-flex ml-5 pb-2">Elogios</Link>
                                            <Link to={"/meus-ciclos"} className="d-flex ml-5 pb-2">Ciclos</Link>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </>
                            : <></>}
                        {/* {(user.role !== "client") ?
                         <>
                            <Card className="border-0 mt-3">
                                <Card.Header className="p-0 border-0">
                                    <NavItem icon={"dashboard"} name={"Dashboard"} link={"/dashboard"} />
                                </Card.Header>
                            </Card>
                            <Card className="border-0">
                                <Card.Header className="p-0 border-0">
                                    <NavItem icon={"computer"} name={"Projetos"} link="/projetos" />
                                </Card.Header>
                            </Card>
                        </>
                            : <></>}
                        {(user.role === "admin" || user.role === "admin-company" || user.role === "manager") ?
                            <>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"flight_takeoff"} name={"Onboarding"} link="/onboarding" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"group"} name={"Colaboradores"} link="/colaboradores" />
                                    </Card.Header>
                                </Card>
                            </>
                            : <></>}
                        {(user.role !== "client") ?
                            <>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <NavItem icon={"business"} name={"Clientes"} link="/clientes" />
                                    </Card.Header>
                                </Card>
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2" className="p-0 text-decoration-none border-0">
                                            <NavItem icon={"sentiment_satisfied_alt"} name={"QBR"} link="#" />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className="py-1">
                                            <Link to={"/reunioes"} className="d-flex ml-5 pb-2">Reuniões</Link>
                                            <Link to={"/reclamacoes"} className="d-flex ml-5 pb-2">Reclamações</Link>
                                            <Link to={"/elogios"} className="d-flex ml-5 pb-2">Elogios</Link>
                                            <Link to={"/fechamento-de-ciclo"} className="d-flex ml-5 pb-2">Fechamento de Ciclo</Link>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </>
                            : <></>}
                        {(user.role === "admin") && 
                                <Card className="border-0">
                                    <Card.Header className="p-0 border-0">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1" className="p-0 text-decoration-none border-0">
                                            <NavItem icon={"work"} name={"Administrativo"} link="#" />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className="py-1">
                                            <Link to={"/administrativo/agencias"} className="d-flex ml-5 pb-2">Agências</Link>
                                            <Link to={"/administrativo/contratos"} className="d-flex ml-5 pb-2">Contratos</Link>
                                            <Link to={"/administrativo/gastos-extras"} className="d-flex ml-5 pb-2">Gastos Extras</Link>
                                            <Link to={"/selecionar/agencia"} className="d-flex ml-5 pb-2">Selecionar Agência</Link>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                        }
                        */}
                    </Accordion>
                </div>
                <div id="page-content-wrapper" className="d-flex justify-content-between flex-column">
                    <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between" style={styles.layout}>
                        <div>
                            <Navbar collapseOnSelect expand="lg" className="py-1 bg-white">
                                <MaterialIcon icon="reorder" onClick={this.toggleSidebar} />
                                <Nav className="mr-auto p-0 ml-2">
                                    <Nav.Link className="p-0 c-pointer text-info text-uppercase font-weight-bold" href="/alterar-agencia">
                                        {(company) ? company.name : ""}
                                    </Nav.Link>
                                </Nav>
                                {(this.state.user.user.role === "client") &&
                                    <Nav className="mr-auto p-0 d-none d-sm-flex">
                                        {(Array.isArray(user.client) === false) ?
                                            <span className="p-0 c-default color-primary">Você está decolando conosco há <strong className="text-primary">{(this.state.client.entryDate !== undefined) ? Math.ceil(Math.abs(new Date().getTime() - new Date(this.state.client.entryDate).getTime()) / (1000 * 60 * 60 * 24)) : Math.ceil(Math.abs(new Date().getTime() - new Date(this.state.client.createdAt).getTime()) / (1000 * 60 * 60 * 24))}</strong> dias!</span>
                                            :
                                            <span className="p-0 c-default color-primary">Você está decolando conosco há <strong className="text-primary">{(user.client[0].entryDate !== undefined) ? Math.ceil(Math.abs(new Date().getTime() - new Date(user.client[0].entryDate).getTime()) / (1000 * 60 * 60 * 24)) : Math.ceil(Math.abs(new Date().getTime() - new Date(user.client[0].createdAt).getTime()) / (1000 * 60 * 60 * 24))}</strong> dias!</span>
                                        }
                                    </Nav>
                                }
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle split id="dropdown-user" className="d-flex align-items-center">
                                        {(user.role !== 'client') ?
                                            <img src={(user.avatar !== undefined) ? user.avatar : 'https://jobs-x-file-upload.s3.amazonaws.com/9ff7ccf839796d9de675f96e8346d2ea-jobsx-gradient.b429725a.png'} className="img-fluid" alt={user.name} />
                                            :
                                            <>
                                                <img src={(Array.isArray(user.client) === false) ? this.state.client.avatar : (user.client.length > 0) ? user.client[0].avatar : 'https://jobs-x-file-upload.s3.amazonaws.com/9ff7ccf839796d9de675f96e8346d2ea-jobsx-gradient.b429725a.png'} className="img-fluid" alt={user.name} />
                                            </>
                                        }
                                        <span className="d-none d-sm-flex">{user.name.split(" ", 1)}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.logout}>Sair</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Navbar>
                            <div className="px-0 py-4 p-sm-5">
                                {this.props.children}
                            </div>
                        </div>
                        <div className="w-100 py-3 text-center small text-muted">
                            &copy; {new Date().getFullYear()} Domatech - Todos os direitos reservados.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function NavItem(props) {
    return (
        <div className="list-group list-group-flush">
            <Link className="list-group-item list-group-item-action" to={props.link}>
                <span className="d-flex align-items-center">
                    <MaterialIcon icon={props.icon} /> {props.name}
                </span>
            </Link>
        </div>
    );
}

import React, { Component } from "react";
import api from "../../services/api";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import LogoGradient from "../../assets/img/jobsx-gradient.svg";
import Loading from "../../components/Loading/index";
import swal from "sweetalert";

export default class RenewPassword extends Component {

    state = {
        email: '',
        error: false,
        success: false,
        load: false,
        message: '',
        password: '',
        confirmPassword: '',
        user: []
    }

    componentDidMount() {
        this.getUser();
        document.getElementsByTagName('html')[0].classList.add("h-100");
        document.getElementsByTagName('body')[0].classList.add("h-100");
        document.getElementById('root').classList.add("h-100");
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    renew = async (event) => {
        this.setState({ load: !this.state.load });
        event.preventDefault();
        let user = this.state.user;
        user.password = this.state.password;
        user.forgotPassword = 0;
        await api.put(`/auth/renew-password/${user._id}`, user)
            .then(async () => {
                let userLogin = { email: user.email, password: user.password };
                await api.post("/login", userLogin)
                    .then(res => {
                        localStorage.setItem("TOKEN_KEY", res.data.token);
                        window.location.href = "/selecionar/agencia";
                    }).catch(err => {
                        this.setState({ error: true, message: "Usuário ou senha inválidos." });
                    });
                swal({ icon: "success", title: "Sucesso!", text: "Senha alterada com sucesso." })
                this.setState({ load: !this.state.load });
            })
            .catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao alterar a senha, tente novamente mais tarde." });
                this.setState({ load: !this.state.load });
            });
    }

    getUser = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/user/${id}`, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1cyI6IkF0aXZvIiwiX2lkIjoiNWU3MTI2Y2NiZGNlODY0ZDk0MjAwNmY0IiwibmFtZSI6Ikd1c3Rhdm8gVHJvcXVpbGhvIiwiZW1haWwiOiJndXN0YXZvdHJvcXVpbGhvQGpvYnNwYWNlLmNvbS5iciIsInJvbGUiOiJhZG1pbiIsInRlYW1JZCI6IjIyNDY5NyIsInVzZXJJZCI6ImRvdWdsYXMtZGUtcGF1bGEiLCJjb21wYW55Ijp7InN0YXR1cyI6IkF0aXZvIiwiX2lkIjoiNWUyMDVlMTQ0YjUyZGUyMDMwOTIyMGEyIiwibmFtZSI6IkpvYnNYIDEiLCJ0b2tlbiI6IiQyYiQxMCRrN3pVLzh2UFRybzlZVHhhR3dqWjFlV0syb2RrN2NLQWtMRW9nOGozVmZzRWkuOTNlNjdacSIsImNyZWF0ZWRBdCI6IjIwMjAtMDEtMTZUMTI6NTk6MDAuMjM1WiIsInVwZGF0ZWRBdCI6IjIwMjAtMDEtMTZUMTI6NTk6MDAuMjM1WiIsIl9fdiI6MH0sImF2YXRhciI6Imh0dHBzOi8vam9icy14LWZpbGUtdXBsb2FkLnMzLmFtYXpvbmF3cy5jb20vZTZkMGJlMTk3MWIwZDMxZTM3NTY1YzI2NGYwOTU5NjgtUm84ajdrWmhfNDAweDQwMC5qcGciLCJhdmF0YXJLZXkiOiJlNmQwYmUxOTcxYjBkMzFlMzc1NjVjMjY0ZjA5NTk2OC1SbzhqN2taaF80MDB4NDAwLmpwZyIsInRva2VuIjoiJDJiJDEwJDZRM1FRTEwudmxmOE5aNGFINkFEZmVnZEhLaTlUWmdLRW9WbDdCUmdBMEJmeVZyTVMvSzVTIiwicGFzc3dvcmQiOiIkMmIkMTAkMVRUcXZIcXA3YXdNWXhCNUxteFMzZW9SbXFUWnZmVmpzQ0Y5VjhNS2tuTjZSNHdEcTJkei4iLCJjcmVhdGVkQXQiOiIyMDIwLTAzLTE3VDE5OjM2OjQ0LjQyMFoiLCJ1cGRhdGVkQXQiOiIyMDIwLTA0LTA4VDIzOjMyOjI1LjE3NVoiLCJfX3YiOjAsImZvcmdvdFBhc3N3b3JkIjoxfSwiaWF0IjoxNTg2MzkyNzMxfQ.Wug14R20UrGYiOaUqw-4aQxpMPsr_vKTlGazp0NKtv4' } });
        const response2 = await api.get(`/user-client/${id}`, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1cyI6IkF0aXZvIiwiX2lkIjoiNWU3MTI2Y2NiZGNlODY0ZDk0MjAwNmY0IiwibmFtZSI6Ikd1c3Rhdm8gVHJvcXVpbGhvIiwiZW1haWwiOiJndXN0YXZvdHJvcXVpbGhvQGpvYnNwYWNlLmNvbS5iciIsInJvbGUiOiJhZG1pbiIsInRlYW1JZCI6IjIyNDY5NyIsInVzZXJJZCI6ImRvdWdsYXMtZGUtcGF1bGEiLCJjb21wYW55Ijp7InN0YXR1cyI6IkF0aXZvIiwiX2lkIjoiNWUyMDVlMTQ0YjUyZGUyMDMwOTIyMGEyIiwibmFtZSI6IkpvYnNYIDEiLCJ0b2tlbiI6IiQyYiQxMCRrN3pVLzh2UFRybzlZVHhhR3dqWjFlV0syb2RrN2NLQWtMRW9nOGozVmZzRWkuOTNlNjdacSIsImNyZWF0ZWRBdCI6IjIwMjAtMDEtMTZUMTI6NTk6MDAuMjM1WiIsInVwZGF0ZWRBdCI6IjIwMjAtMDEtMTZUMTI6NTk6MDAuMjM1WiIsIl9fdiI6MH0sImF2YXRhciI6Imh0dHBzOi8vam9icy14LWZpbGUtdXBsb2FkLnMzLmFtYXpvbmF3cy5jb20vZTZkMGJlMTk3MWIwZDMxZTM3NTY1YzI2NGYwOTU5NjgtUm84ajdrWmhfNDAweDQwMC5qcGciLCJhdmF0YXJLZXkiOiJlNmQwYmUxOTcxYjBkMzFlMzc1NjVjMjY0ZjA5NTk2OC1SbzhqN2taaF80MDB4NDAwLmpwZyIsInRva2VuIjoiJDJiJDEwJDZRM1FRTEwudmxmOE5aNGFINkFEZmVnZEhLaTlUWmdLRW9WbDdCUmdBMEJmeVZyTVMvSzVTIiwicGFzc3dvcmQiOiIkMmIkMTAkMVRUcXZIcXA3YXdNWXhCNUxteFMzZW9SbXFUWnZmVmpzQ0Y5VjhNS2tuTjZSNHdEcTJkei4iLCJjcmVhdGVkQXQiOiIyMDIwLTAzLTE3VDE5OjM2OjQ0LjQyMFoiLCJ1cGRhdGVkQXQiOiIyMDIwLTA0LTA4VDIzOjMyOjI1LjE3NVoiLCJfX3YiOjAsImZvcmdvdFBhc3N3b3JkIjoxfSwiaWF0IjoxNTg2MzkyNzMxfQ.Wug14R20UrGYiOaUqw-4aQxpMPsr_vKTlGazp0NKtv4' } })
        if (response.data !== null) {
            this.setState({ user: response.data });
            // if ((response.data.firstAccess === false && response.data.forgotPassword === 0) || (response.data.firstAccess === false && response.data.forgotPassword === "0")) {
            //     window.location.href = "/";
            // }
        }
        if (response2.data !== null) {
            this.setState({ user: response2.data });
            // if ((response2.data.firstAccess === false && response2.data.forgotPassword === 0) || (response2.data.firstAccess === false && response2.data.forgotPassword === "0")) {
            //     window.location.href = "/";
            // }
        }
    }

    render() {
        const load = this.state.load;
        const error = this.state.error;
        const success = this.state.success;
        const message = this.state.message;
        const password = this.state.password;
        const confirmPassword = this.state.confirmPassword;

        return (
            <div className="login h-100" id="password">
                <Container className="h-100">
                    <Row className="h-100 align-items-stretch">
                        <Col xs={12} sm={12} className="d-flex align-items-start">
                            <Navbar className="fixed-top justify-content-start">
                                <Navbar.Brand href="https://www.jobsx.com.br"><img src={LogoGradient} className="img-fluid" alt="" /></Navbar.Brand>
                            </Navbar>
                        </Col>
                        <Col xs={12} sm={5} className="d-flex align-items-center ml-auto">
                            <div className="w-100">
                                <h1 className="pb-4">Área X</h1>
                                <p className="pb-3">Alteração de senha.</p>
                                <Form className="w-100" onSubmit={this.renew}>
                                    <Form.Group className="pb-3">
                                        <Form.Control type="password" placeholder="Nova senha" name="password" onChange={this.myChangeHandler} required />
                                    </Form.Group>
                                    <Form.Group className="pb-3">
                                        <Form.Control type="password" placeholder="Confirmação de senha" name="confirmPassword" onChange={this.myChangeHandler} required />
                                    </Form.Group>
                                    <Row className="py-4">
                                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                                            <Button variant="primary" type="submit" className="text-uppercase" disabled={password !== confirmPassword}>
                                                Enviar
                                            </Button>
                                        </Col>
                                        <Col xs={12} className="text-center mt-4">
                                            {(error) ? <small className="text-danger font-weight-bold">{message}</small> : <></>}
                                            {(success) ? <small className="color-primary font-weight-bold">{message}</small> : <></>}
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <span>JobsX {new Date().getFullYear()} &copy; CNPJ 35.031.143/0001-45. Todos os direitos reservados. Desenvolvido por: <a href="https://www.domatech.com.br">Domatech</a></span>
                        </Col>
                    </Row>
                </Container>
                <Loading show={load} />
            </div>
        );
    }

}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class ClientFormat extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        load: false,
        format: [],
        client: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({load: true});
        const { id } = this.props.match.params;
        const response = await api.get(`/format-client/${id}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ load: false, arr: docs, arrInfo, page });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            format: {
                ...this.state.format,
                [nam]: val
            }
        });
    }

    toggleModal = (format = {}) => {
        document.getElementById("myForm").reset();
        document.getElementById("text-area").value = null;
        this.setState({ modal: !this.state.modal, format: format });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (format) => {
        swal({ title: "Atenção", text: "Deseja remover este formato?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/format/${format._id}`)
                    .then(response => {
                        this.load(this.state.page);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const format = this.state.format;
        if (format._id !== undefined) {
            await api.put(`/format/${format._id}`, format)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Formato editado com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o formato, tente novamente mais tarde." });
                    this.load();
                    console.log(error)
                });
        } else {
            format.client = this.state.clientId;
            await api.post(`/format`, format)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Formato adicionado com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar o formato, tente novamente mais tarde." });
                    this.load();
                    console.log(error)
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const {client = [], modal = false, format = [], arr = []} = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Formatos" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Formatos</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Tipo
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Formato
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            Comentários
                                        </Col>
                                        <Col xs={12} sm={1}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}> 
                                {(this.state.arrInfo.total > 0) ? 
                                    arr.map((format, index) => (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={3}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo:</span>{format.type}
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Formato:</span>{format.format}
                                                </Col>
                                                <Col xs={12} sm={5}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Comentários:</span>{format.comments}
                                                </Col>
                                                <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModal(format)}><MaterialIcon icon="edit" /></span>
                                                    <span className="c-pointer ml-3" onClick={() => this.delete(format)}><MaterialIcon icon="close" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    ))
                                    :
                                    <CardBody>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={12} className="text-center">
                                                Não há formatos cadastrados.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Col>
                        </Row>

                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(format._id !== undefined) ? "Editar formato" : "Adicionar formato"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="type" value={format.type} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Banner Facebook", "Banner Instagram", "Banner Site", "Facebook Canva", "Instagram Feed", "Instagram Stories", "Newsletter"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Formato <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="format" value={format.format} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Comentários</Form.Label>
                                <Form.Control rows="3" as="textarea" id="text-area" name="comments" type="text" value={format.comments} onChange={this.myChangeHandler} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(format._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import Layout from "../../../components/Layout";
import { decodeToken } from "../../../services/auth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import api from "../../../services/api";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Pagination from "../../../components/Pagination/index";
import Modal from "../../../components/Modal";
import MaterialIcon from "material-icons-react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import Loading from "../../../components/Loading/index";

export default class Cycle extends Component {

    state = {
        arr: [],
        arrInfo: {},
        session: decodeToken(),
        loading: false,
        page: 1,
        modal: false,
        modalView: false,
        reunion: [],
        clientId: ''
    }

    componentDidMount() {
        this.getLoad();
    }

    loadReunionClient = async (page = 1, clientId) => {
        this.setState({ loading: true });
        const response = await api.get(`/cycle-closing-meeting?page=${page}&client=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, loading: false });
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId });
        this.loadReunionClient(this.state.page, clientId);
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            reunion: {
                ...this.state.reunion,
                [nam]: val
            }
        });
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadReunionClient(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.loadReunionClient(pageNumber, this.state.clientId);
    }

    toggleModal = (reunion = {}) => {
        document.getElementById("myForm").reset();
        document.getElementById("text-area").value = null;
        this.setState({ modal: !this.state.modal, reunion: reunion })
    };

    toggleModalView = (reunion = {}) => this.setState({ modalView: !this.state.modalView, reunion: reunion });

    save = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        await api.put(`/cycle-closing-meeting/${this.state.reunion._id}`, this.state.reunion)
            .then(() => {
                this.loadReunionClient(this.state.page, this.state.clientId);
                swal({ icon: "success", title: "Sucesso!", text: "Avaliação cadastrada com sucesso." });
            })
            .catch(() => {
                this.loadReunionClient(this.state.page, this.state.clientId);
                swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a avaliação, tente novamente mais tarde." });
            });
        this.toggleModal();
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({ clientId: val })
        this.loadReunionClient(this.state.page, val);
    }

    render() {
        const reunions = this.state.arr;
        const { modal, reunion, session } = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                                <Header title="Ciclos" btn={false} />
                            </Col>
                            {/* {(session.user.client.length > 1 &&
                                <Col xs={12} sm={4} className="text-right">
                                    <div className="input-group mb-3">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.mySelectHandler} required>
                                            {session.user.client.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={9}>
                                            Avaliação
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {(reunions.length > 0) ? reunions.map((reunion, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={3} className="mb-4 mb-sm-0">
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Título:</span>{(reunion.onboarding !== undefined) ? reunion.onboarding.name : "-"}
                                            </Col>
                                            {(reunion.totalContent !== undefined && reunion.totalCreation !== undefined && reunion.totalCustomerSuccess !== undefined && reunion.totalMedia !== undefined && reunion.totalSocialMedia !== undefined) ?
                                                <>
                                                    <Col xs={12} sm={3} className="mb-4 mb-sm-0">
                                                        <div className="d-flex align-items-center mb-4">
                                                            <span className="text-primary mr-1">Conteúdo:</span>
                                                            <span className="text-muted">{reunion.totalContent}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <span className="text-primary mr-1">Criação:</span>
                                                            <span className="text-muted">{reunion.totalCreation}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={3} className="mb-4 mb-sm-0">
                                                        <div className="d-flex align-items-center mb-4">
                                                            <span className="text-primary mr-1">Customer Success:</span>
                                                            <span className="text-muted">{reunion.totalCustomerSuccess}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <span className="text-primary mr-1">Mídia:</span>
                                                            <span className="text-muted">{reunion.totalMedia}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={3} className="mb-4 mb-sm-0">
                                                        <div className="d-flex align-items-center mb-4">
                                                            <span className="text-primary mr-1">Social Media:</span>
                                                            <span className="text-muted">{reunion.totalSocialMedia}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col xs={12} sm={6} className="mb-4 mb-sm-0">
                                                        <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Avaliação:</span>Não Avaliado
                                                    </Col>
                                                    <Col xs={12} sm={3} className="d-flex justify-content-end align-items-center">
                                                        <span className="c-pointer ml-3 text-primary font-weight-bold no-hover text-uppercase small" onClick={() => this.toggleModal(reunion)}>Avaliar</span>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </CardBody>
                                )) :
                                    <p className="text-center text-muted mt-1">Nenhuma reunião encontrada.</p>
                                }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Avaliar - {(reunion.onboarding !== undefined) ? reunion.onboarding.name : ""}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row className="mb-4">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label className="text-primary font-weight-bold small mb-2">Avaliar setor de Conteúdo</Form.Label>
                                        <h4 className="text-primary mb-0">Total: {reunion.totalContent || 0}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Control name="totalContent" list="totalContent" type="range" defaultValue={reunion.totalContent || 0} min={0} max={10} onChange={this.myChangeHandler} required />
                                        <datalist id="totalContent">
                                            <option value="0" />
                                            <option value="1" />
                                            <option value="2" />
                                            <option value="3" />
                                            <option value="4" />
                                            <option value="5" />
                                            <option value="6" />
                                            <option value="7" />
                                            <option value="8" />
                                            <option value="9" />
                                            <option value="10" />
                                        </datalist>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label className="text-primary font-weight-bold small mb-2">Avaliar setor de Criação</Form.Label>
                                        <h4 className="text-primary mb-0">Total: {reunion.totalCreation || 0}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Control name="totalCreation" list="totalCreation" type="range" defaultValue={reunion.totalCreation || 0} min={0} max={10} onChange={this.myChangeHandler} required />
                                        <datalist id="totalCreation">
                                            <option value="0" />
                                            <option value="1" />
                                            <option value="2" />
                                            <option value="3" />
                                            <option value="4" />
                                            <option value="5" />
                                            <option value="6" />
                                            <option value="7" />
                                            <option value="8" />
                                            <option value="9" />
                                            <option value="10" />
                                        </datalist>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label className="text-primary font-weight-bold small mb-2">Avaliar setor de Customer Success</Form.Label>
                                        <h4 className="text-primary mb-0">Total: {reunion.totalCustomerSuccess || 0}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Control name="totalCustomerSuccess" list="totalCustomerSuccess" type="range" defaultValue={reunion.totalCustomerSuccess || 0} min={0} max={10} onChange={this.myChangeHandler} required />
                                        <datalist id="totalCustomerSuccess">
                                            <option value="0" />
                                            <option value="1" />
                                            <option value="2" />
                                            <option value="3" />
                                            <option value="4" />
                                            <option value="5" />
                                            <option value="6" />
                                            <option value="7" />
                                            <option value="8" />
                                            <option value="9" />
                                            <option value="10" />
                                        </datalist>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label className="text-primary font-weight-bold small mb-2">Avaliar setor de Mídia</Form.Label>
                                        <h4 className="text-primary mb-0">Total: {reunion.totalMedia || 0}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Control name="totalMedia" list="totalMedia" type="range" defaultValue={reunion.totalMedia || 0} min={0} max={10} onChange={this.myChangeHandler} required />
                                        <datalist id="totalMedia">
                                            <option value="0" />
                                            <option value="1" />
                                            <option value="2" />
                                            <option value="3" />
                                            <option value="4" />
                                            <option value="5" />
                                            <option value="6" />
                                            <option value="7" />
                                            <option value="8" />
                                            <option value="9" />
                                            <option value="10" />
                                        </datalist>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                                        <Form.Label className="text-primary font-weight-bold small mb-2">Avaliar setor de Social Media</Form.Label>
                                        <h4 className="text-primary mb-0">Total: {reunion.totalSocialMedia || 0}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Control name="totalSocialMedia" list="totalSocialMedia" type="range" defaultValue={reunion.totalSocialMedia || 0} min={0} max={10} onChange={this.myChangeHandler} required />
                                        <datalist id="totalSocialMedia">
                                            <option value="0" />
                                            <option value="1" />
                                            <option value="2" />
                                            <option value="3" />
                                            <option value="4" />
                                            <option value="5" />
                                            <option value="6" />
                                            <option value="7" />
                                            <option value="8" />
                                            <option value="9" />
                                            <option value="10" />
                                        </datalist>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Comentários</Form.Label>
                                <Form.Control as="textarea" rows="3" id="text-area" name="clientComments" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    Avaliar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.loading} />
            </>
        );
    }
}
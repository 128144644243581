import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class ClientSync extends Component {

    state = {
        session: decodeToken(),
        clients: [],
        companies: [],

        modal: false,
        modalExport: false,
        client: [],
        company: [],
        active: true,
        load: false,
        search: '',

        filterStatus: 'active',
        filterOldStatus: 'Ativo',
    }

    componentDidMount() {
        this.getClients();
        this.getCompanies();
    }

    getClients = async () => {
        const response = await api.get(`/client-new?order=asc&sortBy=name&limit=1000`);
        this.setState({ clients: response.data });
    }

    getCompanies = async () => {
        const response = await api.get(`/company?order=asc&sortBy=name&limit=1000`);
        this.setState({ companies: response.data });
    }

    myChangeHandler = (event) => this.setState({ client: { ...this.state.client, [event.target.name]: event.target.value } });

    toggleModal = (client = {}) => {
        console.log(client)
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, client: client });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        let { client } = this.state;
        client.isNewClient = false;
        if (client._id !== undefined) {
            await api.put(`/client/${client._id}`, client)
                .then(() => {
                    this.setState({ load: false })
                    this.getClients();
                    swal({ icon: "success", title: "Sucesso!", text: "Cliente editado com sucesso." });
                }).catch(error => {
                    this.setState({ load: false });
                    this.getClients();
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o cliente, tente novamente mais tarde." });
                    console.log(error)
                });
        }
        this.toggleModal({});
    }

    render() {
        const { clients, load, modal, client, filterStatus, filterOldStatus, companies = [] } = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-1">
                            <Col xs={12} sm={12} className="text-left">
                                <Header title="Novos Clientes" btn={false} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={10}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={2} />
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {clients
                                    .filter(client => client.status === filterStatus || client.status === filterOldStatus)
                                    .map((client, index) => (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={10}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{client.name}
                                                </Col>
                                                <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" title="Editar" onClick={() => this.toggleModal(client)}><MaterialIcon icon="edit" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    ))}
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(client._id !== undefined) ? "Editar cliente" : "Adicionar cliente"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome</Form.Label>
                                <Form.Control type="text" name="name" value={client.name} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Agência</Form.Label>
                                <Form.Control as="select" name="company" value={(client.company) ? client.company._id : ""} onChange={this.myChangeHandler} required>
                                    <option></option>
                                    {companies.map((item, index) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">CNPJ</Form.Label>
                                <Form.Control type="text" name="cnpj" value={client.cnpj} onChange={this.myChangeHandler} />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Status </Form.Label>
                                <Form.Control as="select" name="status" value={client.status} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {[{ name: "Ativo", value: "active" }, { name: "Inativo", value: "inactive" }].map((item, index) => (
                                        <option key={index} value={item.value}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Categoria </Form.Label>
                                <Form.Control as="select" name="category" value={client.category} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Branding", "E-commerce", "Franquia", "Institucional", "Indústria", "Startup"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data de entrada </Form.Label>
                                <Form.Control type="date" name="entryDate" value={String(client.entryDate).substr(0, 10)} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">E-mail</Form.Label>
                                <Form.Control type="email" name="email" value={client.email} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Telefone </Form.Label>
                                <Form.Control type="text" name="phone" value={client.phone} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Pagante? </Form.Label>
                                <Form.Control as="select" name="paying" value={client.paying} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {[{ name: "Sim", value: "true" }, { name: "Não", value: "false" }].map((item, index) => (
                                        <option value={item.value} key={index}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(client._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={load} />
            </>
        );
    }
}
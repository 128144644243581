import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert1 from "../../assets/img/icons/a-1.png";
// import Alert3 from "../../assets/img/icons/a-3.png";
import Alert4 from "../../assets/img/icons/a-4.png";

function Month(props) {
    const checklist = [];
    props.checklist.forEach((item, index) => {
        if (item.status === "Entregue" || item.task_status_name === "Entregue" || item.task_status_name === "Entregues") {
            checklist.push(<TaskDelivery title={item.title} key={index} />);
        } else {
            checklist.push(<TaskWaiting title={item.title} key={index} />);
        }
    });

    return (
        <Col xs={12} sm={4} className={(checklist.length > 0) ? "mb-4 calendar-month" : "d-none"}>
            <Row className="bg-white mx-1 py-4 px-2">
                <Col xs={12} className="mb-2">
                    <h3 className="font-weight-bold">{props.title}</h3>
                </Col>
                <Col xs={12} className="tasks">
                    <div className="text-muted">
                        {(checklist.length > 0) ? checklist : <span>Nenhuma atividade cadastrada.</span>}
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

function TaskDelivery(props) {
    return (
        <Row className="mb-2">
            <Col xs={10} className="d-flex justify-content-start">
                <h6 className="mb-0">{props.title}</h6>
            </Col>
            <Col xs={2} className="d-flex justify-content-end">
                <img src={Alert1} className="img-fluid" alt="" style={{ width: '15px', height: '15px' }} />
            </Col>
        </Row>
    );
}
function TaskWaiting(props) {
    return (
        <Row className="mb-2">
            <Col xs={10} className="d-flex justify-content-start">
                <h6 className="mb-0">{props.title}</h6>
            </Col>
            <Col xs={2} className="d-flex justify-content-end">
                <img src={Alert4} className="img-fluid" alt="" style={{ width: '15px', height: '15px' }} />
            </Col>
        </Row>
    );
}

export default Month;
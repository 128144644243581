import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isUser, isAdmin, isAdminOnboarding, isManager, isClient } from "./services/auth";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import RenewPassword from "./pages/RenewPassword";
import Dashboard from "./pages/Dashboard";
import Projects from "./pages/Projects";
import User from "./pages/User";
import UserOneClickView from "./pages/UserOneClickView";
import Client from "./pages/Client";
import ClientDashboard from "./pages/ClientDashboard";
import AdminCompanies from "./pages/Admin/Agencies";
import AdminContracts from "./pages/Admin/Contracts";
// import AdminExtraExpenses from "./pages/Admin/ExtraExpenses";
// import AdminSingleExtraExpenses from "./pages/Admin/ExtraExpenses/SingleExtraExpenses";
import UsersClient from "./pages/UsersClient";
import ClientAccess from "./pages/ClientAccess";
import ClientAnalytics from "./pages/ClientAnalytics";
import ClientFormat from "./pages/ClientFormat";
import ClientReport from "./pages/ClientReport";
import ClientTeam from "./pages/ClientTeam";
import Onboarding from "./pages/Onboarding";
import OnboardingTask from "./pages/OnboardingTask";
import Reunion from "./pages/Reunion";
import Complaint from "./pages/Complaint";
import Compliment from "./pages/Compliment";
import SelectCompany from "./pages/SelectCompany";

// Client
import Index from "./pages/_client/Index";
import Calendar from "./pages/_client/Calendar";
import Task from "./pages/_client/Task";
import TaskClient from "./pages/_client/TaskClient";
import ReunionClient from "./pages/_client/Reunion";
import ComplaintClient from "./pages/_client/Complaint";
import ComplimentClient from "./pages/_client/Compliment";
import ReportClient from "./pages/_client/Report";
import TeamClient from "./pages/_client/Team";
import DashboardsClient from "./pages/_client/Dashboards";
import CycleClosingMeeting from "./pages/CycleClosingMeeting";
import Cycle from "./pages/_client/Cycle";
import ClientSync from "./pages/ClientSync";
import ChangeCompany from "./pages/ChangeCompany";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isUser() ? (
                <Component {...props} />
            ) : (
                isClient() ? (<Redirect to={{ pathname: "/inicio", state: { from: props.location } }} />) : (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)
            )
        }
    />
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAdmin() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
            )
        }
    />
);

const AdminOnboardingRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            (isAdmin() || isAdminOnboarding() || isManager()) ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
            )
        }
    />
);

const ClientRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isClient() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
            )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/esqueceu-sua-senha" component={ForgotPassword} />
            <Route exact path="/altere-sua-senha/:id" component={RenewPassword} />

            {/* Interno */}
            <PrivateRoute exact path="/selecionar/agencia" component={SelectCompany} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/alterar-agencia" component={ChangeCompany} />

            {/* Admin */}
            <AdminRoute exact path="/agencias" component={AdminCompanies} />
            <AdminRoute exact path="/colaboradores" component={User} />
            <AdminRoute exact path="/novos-clientes" component={ClientSync} />
            <AdminRoute exact path="/contratos" component={AdminContracts} />
            {/* <AdminRoute exact path="/gastos-extras" component={AdminExtraExpenses} />
            <AdminRoute exact path="/gastos-extras/:id" component={AdminSingleExtraExpenses} /> */}
            

            {/* Geral */}
            <PrivateRoute exact path="/clientes" component={Client} />
            <PrivateRoute exact path="/clientes/:id/dashboard" component={ClientDashboard} />
            <PrivateRoute exact path="/clientes/:id/usuarios" component={UsersClient} />
            <PrivateRoute exact path="/clientes/:id/acessos" component={ClientAccess} />
            <PrivateRoute exact path="/clientes/:id/analytics" component={ClientAnalytics} />
            <PrivateRoute exact path="/clientes/:id/time" component={ClientTeam} />
            <PrivateRoute exact path="/clientes/:id/relatorios" component={ClientReport} />
            <PrivateRoute exact path="/clientes/:id/formatos" component={ClientFormat} />
            <PrivateRoute exact path="/reunioes" component={Reunion} />
            <PrivateRoute exact path="/reclamacoes" component={Complaint} />
            <PrivateRoute exact path="/elogios" component={Compliment} />

            <PrivateRoute exact path="/projetos" component={Projects} />

            <AdminOnboardingRoute exact path="/onboarding" component={Onboarding} />
            <AdminOnboardingRoute exact path="/onboarding/tarefas/:id" component={OnboardingTask} />
            <PrivateRoute exact path="/colaboradores/one-click-view" component={UserOneClickView} />
            <PrivateRoute exact path="/fechamento-de-ciclo" component={CycleClosingMeeting} />


            

        
            {/* Client */}
            <ClientRoute exact path="/inicio" component={Index} />
            <ClientRoute exact path="/calendario" component={Calendar} />
            <ClientRoute exact path="/tarefas" component={Task} />
            <ClientRoute exact path="/relatorios" component={ReportClient} />
            <ClientRoute exact path="/time" component={TeamClient} />
            <ClientRoute exact path="/analytics" component={DashboardsClient} />
            <ClientRoute exact path="/minhas-atividades" component={TaskClient} />
            <ClientRoute exact path="/minhas-reunioes" component={ReunionClient} />
            <ClientRoute exact path="/minhas-reclamacoes" component={ComplaintClient} />
            <ClientRoute exact path="/meus-elogios" component={ComplimentClient} />
            <ClientRoute exact path="/meus-ciclos" component={Cycle} />

            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
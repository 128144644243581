import React, { Component } from "react";
import { Link } from "react-router-dom";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";
import Select from "react-select";
import runrun_it from "../../services/runrunit";

const styles = {
    ul: {
        listStyle: "none"
    },
    img: {
        width: "2.25rem",
        height: "2.25rem",
        borderRadius: "50%",
        border: "2px solid #f8f9fa",
        marginRight: "-10px"
    }
}

export default class User extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        load: false,

        teamsSelect: true,
        user: [],
        companies: [],
        companiesOptions: [],
        clientsOptions: [],
        selectedCompanies: [],
        selectedClients: [],
        companyId: '',
        teamId: '',
        teams: [],
        workedHoursColumn: true,

        status: 'active'
    }

    componentDidMount() {
        this.getUsers();
        this.getCompanies();
        this.getClients();
        this.getTeams();
    }

    getUsers = async (page = 1, status = "active") => {
        const response = await api.get(`/user?paginate=true&page=${page}&sortBy=name&order=asc&status=${status}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page });
    }

    changeStatus = async () => {
        const { status } = this.state;
        let newStatus = "";
        if(status === "active"){
            newStatus = "inactive";
        }else{
            newStatus = "active";
        }
        this.setState({ status: newStatus })
        this.getUsers(1, newStatus);
    }

    getUsersByTeam = async (page = 1, team = "", status = 'active') => {
        const response = await api.get(`/user-by-team/${team}?paginate=true&page=${page}&sortBy=name&order=asc&status=${status}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page });
    }

    getCompanies = async () => {
        const response = await api.get(`/company?order=asc`);
        const { data = [] } = response;
        const companiesOptions = [];
        for (let i = 0; i < data.length; i++) {
            companiesOptions.push({ value: data[i]._id, label: data[i].name });
        }
        this.setState({ companies: response.data, companiesOptions });
    }

    getClients = async () => {
        const response = await api.get(`/client?order=asc&&status=active`);
        const { data = [] } = response;
        const clientsOptions = [];
        for (let i = 0; i < data.length; i++) {
            clientsOptions.push({ value: data[i]._id, label: data[i].name });
        }
        this.setState({ clients: response.data, clientsOptions});
    }

    getTeams = async () => {
        const response = await runrun_it.get(`/teams`);
        this.setState({ teams: response.data })
    }

    filterByTeam = async (team) => {
        if (!team) {
            this.getUsers();
        } else {
            this.getUsersByTeam(1, team, this.state.status);
        }
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getUsers(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getUsers(pageNumber);
    }

    toggleModal = (user = {}) => {
        document.getElementById("myForm").reset();
        if (user._id !== undefined) {
            const { company = [], client = [] } = user;
            const selectedCompanies = [];
            const selectedClients = [];
            for (let i = 0; i < company.length; i++) {
                selectedCompanies.push({ value: company[i]._id, label: company[i].name });
            }
            for (let i = 0; i < client.length; i++) {
                selectedClients.push({ value: client[i]._id, label: client[i].name });
            }
            this.setState({ modal: !this.state.modal, user: user, selectedCompanies: selectedCompanies, selectedClients: selectedClients });
        }
        this.setState({ modal: !this.state.modal, user: user });
    }

    myChangeHandler = (event) => this.setState({ user: { ...this.state.user, [event.target.name]: event.target.value } });

    selectCompanies = selectedCompanies => this.setState({ selectedCompanies });
    selectClients = selectedClients => this.setState({ selectedClients });

    save = async (event) => {
        this.setState({ load: true });
        event.preventDefault();
        let { user } = this.state;

        const { selectedCompanies = [], selectedClients = [] } = this.state;
        user.company = (selectedCompanies.length > 0) ? selectedCompanies.map(item => item.value) : [];
        user.client = (selectedClients.length > 0) ? selectedClients.map(item => item.value) : [];

        if (user._id !== undefined) {
            await api.put(`/user/${user._id}`, user)
                .then(() => {
                    this.getUsers(this.state.page, this.state.status);
                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." });
                    this.setState({ load: false, selectedCompanies: [], selectedClients: [] });
                }).catch(error => {
                    this.getUsers(this.state.page, this.state.status);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o colaborador, tente novamente mais tarde." });
                    console.log(error)
                    this.setState({ load: false, selectedCompanies: [], selectedClients: [] });
                });
        }
        this.toggleModal({});
    }

    render() {
        const { status, modal, user, selectedCompanies = [], selectedClients = [], companiesOptions = [], clientsOptions = [], arr: users = [], teams = [] } = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-between">
                                <Header title="Colaboradores" btn={false} callbackParent={this.toggleModal} />
                                <div className="d-flex align-items-center w-25">
                                    <span className="d-flex w-50 small text-primary pl-2 font-weight-bold">Filtrar por time:</span>
                                    <Form.Control as="select" name="team" onChange={(event) => this.filterByTeam(event.target.value)} required>
                                        <option></option>
                                        {teams.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <span className="text-uppercase text-muted c-pointer small font-weight-bold" onClick={this.changeStatus}>Ver colaboradores {(status === "inactive") ? "Ativos" : "Inativos"} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            E-mail
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            Clientes
                                        </Col>
                                        <Col xs={12} sm={1}>
                                            Status
                                        </Col>
                                        <Col xs={12} sm={1}>
                                            Horas{/*  gastas mês */}
                                        </Col>
                                        <Col xs={12} sm={1}>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {users.map((user, index) => {
                                    const { client: userClients = [] } = user;
                                    return (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{user.name}
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">E-mail:</span>{user.email}
                                                </Col>
                                                <Col xs={12} sm={4}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Clientes: </span>
                                                    {userClients.map((uc, i) => (
                                                        <li key={i} className="d-inline-block">
                                                            <Link to={`/clientes/${uc._id}/dashboard`}>
                                                                <img src={(uc.avatar !== undefined) ? uc.avatar : "https://my.jobsx.com.br/static/media/jobsx-gradient.b429725a.svg"} style={styles.img} title={uc.name} alt={uc.name} />
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </Col>
                                                <Col xs={12} sm={1}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span>{(user.status === "active") ? "Ativo" : "Inativo"}
                                                </Col>
                                                <Col xs={12} sm={1}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Horas gastas mês: </span>{(user.workedHours !== undefined) ? (user.workedHours / 3600).toFixed(2) : '0.00'}hrs
                                                </Col>
                                                <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModal(user)}><MaterialIcon icon="edit" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(user._id !== undefined) ? "Editar colaborador" : "Adicionar colaborador"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome</Form.Label>
                                <Form.Control type="text" name="name" value={user.name || ""} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">E-mail</Form.Label>
                                <Form.Control type="email" name="email" value={user.email || ""} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={(this.state.user.companyId === '') ? 12 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nível de acesso</Form.Label>
                                <Form.Control as="select" name="role" value={user.role} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {[{ name: "Administrador", value: "admin" }, { name: "Colaborador", value: "user" }].map((item, index) => (
                                        <option value={item.value} key={index}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Status</Form.Label>
                                <Form.Control as="select" name="status" value={user.status} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {[{ name: "Ativo", value: "active" }, { name: "Inativo", value: "inactive" }].map((item, index) => (
                                        <option value={item.value} key={index}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Clientes<sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Select name="tasks1" placeholder="" value={selectedClients} onChange={this.selectClients} options={clientsOptions} isMulti />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Agências<sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Select name="tasks" placeholder="" value={selectedCompanies} onChange={this.selectCompanies} options={companiesOptions} isMulti />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(user._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
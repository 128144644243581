import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import axios from "axios";
import Loading from "../../components/Loading/index";

export default class ClientAnalytics extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        team: [],
        client: [],
        users: [],
        load: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({ load: true });
        const { id } = this.props.match.params;
        const response = await api.get(`/team-client/${id}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const { session } = this.state;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
        this.getUsers(session.user.company);
    }

    getUsers = async (companyId) => {
        const response = await api.get(`/user-all-run?company=${companyId}`);
        this.setState({ users: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            team: {
                ...this.state.team,
                [nam]: val
            }
        });
    }

    toggleModal = (team = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, team: team });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (team) => {
        swal({ title: "Atenção", text: `Deseja remover este colaborador do cliente ${this.state.client.name}?`, icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/team/${team._id}`)
                        .then(response => {
                            this.load(this.state.page, this.state.team._id);
                        }).catch(error => {
                            console.log(error)
                        });
                }
            });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        const usuario = await api.get(`/user/${this.state.team.user}`);
        const team = this.state.team;
        team.client = this.state.clientId;
        team.userName = usuario.data.name;
        team.teamId = usuario.data.teamId;
        if (usuario.data.role !== "admin" || usuario.data.role !== "admin-company") {
            await axios.get(`https://runrun.it/api/v1.0/teams/${usuario.data.teamId}`, { headers: { 'App-Key': this.state.client.company.runrunit_app_key, 'User-Token': this.state.client.company.runrunit_user_token } })
                .then(response => {
                    team.userSector = response.data.name;
                }).catch(error => {
                    team.userSector = usuario.data.teamName;
                    console.log(error)
                });;
        } else {
            team.userSector = "Admin"
        }
        await api.post(`/team`, team)
            .then(response => {
                this.load();
                swal({ icon: "success", title: "Sucesso!", text: "Colaborador adicionado com sucesso." });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar o colaborador, tente novamente mais tarde." });
                this.load();
                console.log(error)
            });
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const { client, modal, team, users } = this.state
        const teams = this.state.arr;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Time" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Time</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Setor
                                        </Col>
                                        <Col xs={12} sm={3}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {(this.state.arrInfo.total > 0) ?
                                    teams.map((team, index) => (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={6}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{team.userName}
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Setor:</span>{team.userSector}
                                                </Col>
                                                <Col xs={12} sm={3} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.delete(team)}><MaterialIcon icon="close" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    ))
                                    :
                                    <CardBody>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={12} className="text-center">
                                                Não há time cadastrado.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(team._id !== undefined) ? "Editar time" : "Adicionar time"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Colaborador</Form.Label>
                                <Form.Control as="select" name="user" value={team.user} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {users.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(team._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
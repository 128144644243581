import React, { Component } from "react";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import api from "../../services/api";
import { decodeToken } from "../../services/auth";
import WidgetNumber from "../../components/WidgetNumber";
import { faUsers, faIdCard, faChartArea, faUserAstronaut, faFilePdf, faImages } from '@fortawesome/free-solid-svg-icons';

export default class Client extends Component {

    state = {
        user: decodeToken(),
        clientId: '',
        client: [],
        count: []
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.getClient();
        this.getCount()
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    getCount = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client-count/${id}`);
        this.setState({ count: response.data });
    }

    render() {
        const client = this.state.client;
        const clientId = this.state.clientId;
        const count = this.state.count;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title={client.name}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item active>{client.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row className="mb-5">
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/acessos`} title="Acessos" value={(count.access > 0 ? count.access : 0)} icon={faIdCard} iconColor={"#b747b2"} />
                            </Col>
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/analytics`} title="Analytics" value={(count.analytics > 0 ? count.analytics : 0)} icon={faChartArea} iconColor={"#b747b2"} />
                            </Col>
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/relatorios`} title="Relatórios" value={(count.report > 0 ? count.report : 0)} icon={faFilePdf} iconColor={"#b747b2"} />
                            </Col>
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/time`} title="Time" value={(count.team > 0 ? count.team : 0)} icon={faUserAstronaut} iconColor={"#b747b2"} />
                            </Col>
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/usuarios`} title="Usuários" value={(count.userClient > 0 ? count.userClient : 0)} icon={faUsers} iconColor={"#b747b2"} />
                            </Col>
                            <Col xs={12} sm={4} className="my-2">
                                <WidgetNumber link={`/clientes/${clientId}/formatos`} title="Formatos" value={(count.format > 0 ? count.format : 0)} icon={faImages} iconColor={"#b747b2"} />
                            </Col>
                        </Row>
                    </Container>
                </Layout>
            </>
        );
    }
}
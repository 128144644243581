import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WidgetNumber from "../../components/WidgetNumber";
import { Form } from "react-bootstrap";
import { faAngry } from '@fortawesome/free-regular-svg-icons';
import { faMeh } from '@fortawesome/free-regular-svg-icons';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from "../../services/api";
import Loading from "../../components/Loading";
import { startDate, endDate } from "../../services/utils";
import runrun_it from "../../services/runrunit";

export default class Dashboard extends Component {

    state = {
        session: decodeToken(),
        good: 0,
        regular: 0,
        bad: 0,
        percent: 0,
        jan: { good: 0, regular: 0, bad: 0, percent: 0 },
        fev: { good: 0, regular: 0, bad: 0, percent: 0 },
        mar: { good: 0, regular: 0, bad: 0, percent: 0 },
        abr: { good: 0, regular: 0, bad: 0, percent: 0 },
        mai: { good: 0, regular: 0, bad: 0, percent: 0 },
        jun: { good: 0, regular: 0, bad: 0, percent: 0 },
        jul: { good: 0, regular: 0, bad: 0, percent: 0 },
        ago: { good: 0, regular: 0, bad: 0, percent: 0 },
        set: { good: 0, regular: 0, bad: 0, percent: 0 },
        out: { good: 0, regular: 0, bad: 0, percent: 0 },
        nov: { good: 0, regular: 0, bad: 0, percent: 0 },
        dez: { good: 0, regular: 0, bad: 0, percent: 0 },
        companies: [],
        month: new Date().toLocaleDateString('pt-BR', { month: 'long' }).substring(0, 3),
        users: [],
        clients: [],
        clientsHours: [],
        usersHours: [],
        load: false,
        startDate: startDate(),
        endDate: endDate(),
        teams: [],
        team: "",
    }

    componentDidMount() {
        this.getInfomation();
        this.getTeams();
    }

    getData = async () => {
        this.setState({ load: true });
        let session = this.state.session;
        await api.get(`/reunion-dashboard?companies=${session.user.company}`).then(response => {
            const { feedback, feedbackChart } = response.data;
            this.setState({ load: false, good: feedback.good, regular: feedback.regular, bad: feedback.bad, percent: feedback.percent, jan: feedbackChart.jan, fev: feedbackChart.fev, mar: feedbackChart.mar, abr: feedbackChart.abr, mai: feedbackChart.mai, jun: feedbackChart.jun, jul: feedbackChart.jul, ago: feedbackChart.ago, set: feedbackChart.set, out: feedbackChart.out, nov: feedbackChart.nov, dez: feedbackChart.dez });
        }).catch(error => {
            this.setState({ load: false })
            console.log(error.error);
        });
    }

    getUsersByTeam = async (page = 1, team = "", status = 'active') => {
        const response = await api.get(`/user-by-team/${team}?paginate=true&page=${page}&sortBy=name&order=asc&status=${status}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page });
    }

    getTeams = async () => {
        const response = await runrun_it.get(`/teams`);
        this.setState({ teams: response.data })
    }

    filterByTeam = async (team) => {
        if (!team) {
           // this.getUsers();
        } else {
            this.getUsersByTeam(1, team, this.state.status);
        }
    }

    getDatabyMonth = async () => {
        this.setState({ load: true });
        let session = this.state.session;
        await api.get(`/reunion-dashboard-month?companies=${session.user.company}&startDate=${startDate}&endDate=${endDate}`).then(response => {
            const { feedback } = response.data;
            this.setState({ load: false, good: feedback.good, regular: feedback.regular, bad: feedback.bad, percent: feedback.percent });
        }).catch(error => {
            this.setState({ load: false })
            console.log(error.error);
        });
    }

    getClientHours = async (month = this.state.month) => {
        let session = this.state.session;
        await api.get(`home/clients/worked-hours-array?company=${session.user.company}&month=${month}`).then(response => {
            this.setState({ clientsHours: response.data, load: false });
        }).catch(error => {
            console.log(error.error);
        });
    }

    getUsersHours = async (month = this.state.month, team = this.state.team) => {
        let session = this.state.session;
        await api.get(`/home/users/worked-hours-array?company=${session.user.company}&month=${month}&teamId=${team}`).then(response => {
            this.setState({ usersHours: response.data, load: false });
        }).catch(error => {
            console.log(error.error);
        });
    }

    getDataClient = async (client) => {
        await api.get(`/reunion-dashboard-client/${client}`).then(response => {
            const { feedback, feedbackChart } = response.data;
            this.setState({ load: false, good: feedback.good, regular: feedback.regular, bad: feedback.bad, percent: feedback.percent, jan: feedbackChart.jan, fev: feedbackChart.fev, mar: feedbackChart.mar, abr: feedbackChart.abr, mai: feedbackChart.mai, jun: feedbackChart.jun, jul: feedbackChart.jul, ago: feedbackChart.ago, set: feedbackChart.set, out: feedbackChart.out, nov: feedbackChart.nov, dez: feedbackChart.dez });
        }).catch(error => {
            console.log(error.error);
        });
    }

    filterDashboardPerMonth = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        const { startDate, endDate } = this.state;
        if (event.target.name === "startDate") {
            this.getReunionsPerMonth(event.target.value, endDate);
        }
        if (event.target.name === "endDate") {
            this.getReunionsPerMonth(startDate, event.target.value);
        }
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === 'companies') {
            if (val !== '') {
                this.getInfomation(val)
            } else {
                this.getInfomation();
            }
            document.getElementsByName("clients")[0].value = ''
        }
        if (nam === 'clients') {
            if (val !== '') {
                this.getDataClient(val)
            } else {
                this.setState({ load: false, good: 0, regular: 0, bad: 0, percent: 0, jan: 0, fev: 0, mar: 0, abr: 0, mai: 0, jun: 0, jul: 0, ago: 0, set: 0, out: 0, nov: 0, dez: 0 });
                this.getData();
            }
        }
    }

    getInfomation = async () => {
        this.setState({ load: true });
        const response = await api.get(`/client?limit=9999`);
        const users = await api.get(`/user`);
        this.setState({ clients: response.data.filter(item => item.status === "active"), users: users.data.filter(item => item.status === "active") });
        this.getData();
        this.getClientHours();
        this.getUsersHours();
    }

    render() {
        const { good, regular, bad, percent, jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez, clients, session, clientsHours, usersHours, teams = []} = this.state;

        const optionsAdminClients = {
            // colors: ['rgba(183, 71, 178, .9)', 'rgba(183, 71, 178, .5)'],
            colors: ['rgba(0, 170, 0, .7)', 'rgba(170, 0, 0, .7)'],
            title: '',
            tooltip: {
                headerFormat: '',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{point.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true, enabled: true, distance: -50,
                        formatter: function () {
                            if (this.y > 0) {
                                return `<p class="fontBunueloSemiBold">${parseInt(Highcharts.numberFormat(this.point.percentage, 1)) + '%'}</p>`
                            }
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '65%'],
                    size: '110%'
                }
            },
            series: [{ type: 'pie', innerSize: '50%', data: [['Total de Clientes', clients.length], { name: 'Posições Restantes', y: (30 - clients.length), dataLabels: false }] }]
        }

        const optionsAdminCollaborators = {
            // colors: ['rgba(183, 71, 178, .9)', 'rgba(183, 71, 178, .5)'],
            colors: ['rgba(0, 170, 0, .7)', 'rgba(170, 0, 0, .7)'],
            title: '',
            tooltip: {
                headerFormat: '',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{point.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true, enabled: true, distance: -50,
                        formatter: function () {
                            if (this.y > 0) {
                                return `<p class="fontBunueloSemiBold">${parseInt(Highcharts.numberFormat(this.point.percentage, 1)) + '%'}</p>`
                            }
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '65%'],
                    size: '110%'
                }
            },
            series: [{ type: 'pie', innerSize: '50%', data: [['Total de Colaboradores', this.state.users.length], { name: 'Posições Restantes', y: (15 - this.state.users.length), dataLabels: false }] }]
        }

        const optionsClientsHours = {
            colors: ['rgba(0, 170, 0, .7)'],
            chart: { type: 'column' },
            title: { text: '' },
            xAxis: [{
                className: 'fontBunueloSemiBold', type: 'category', labels: {
                    formatter: function () {
                        return this.value.split(' ')[0];
                    }, rotation: -90, style: { fontSize: '13px' }
                }
            }],
            yAxis: [{ className: 'fontBunueloSemiBold', min: 0, title: { text: '' } }],
            tooltip: {
                headerFormat: '<span class="text-primary fontBunueloSemiBold" style="padding: 0; font-size: 14px">{point.key}</span><table>',
                pointFormat: '<tr><td class="fontBunuelo" style="padding: 0; font-size: 12px">{series.name}: </td>' +
                    '<td class="fontBunueloSemiBold" style="padding: 0; font-size: 12px"><b> {point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{ data: clientsHours, name: 'Horas trabalhadas', dataLabels: { className: 'fontBunueloSemiBold', enabled: true, rotation: -90, y: 10, align: 'center', format: '{point.y:.1f}', style: { fontSize: '16px' } } }]
        }

        const optionsUsersHours = {
            colors: ['rgba(0, 170, 0, .7)'],
            chart: { type: 'column' },
            title: { text: '' },
            xAxis: [{
                className: 'fontBunueloSemiBold', type: 'category', labels: {
                    formatter: function () {
                        return this.value.split(' ')[0];
                    }, rotation: -90, style: { fontSize: '13px' }
                }
            }],
            yAxis: [{ className: 'fontBunueloSemiBold', min: 0, title: { text: '' } }],
            tooltip: {
                headerFormat: '<span class="text-primary fontBunueloSemiBold" style="padding: 0; font-size: 14px">{point.key}</span><table>',
                pointFormat: '<tr><td class="fontBunuelo" style="padding: 0; font-size: 12px">{series.name}: </td>' +
                    '<td class="fontBunueloSemiBold" style="padding: 0; font-size: 12px"><b> {point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{ data: usersHours, name: 'Horas trabalhadas', dataLabels: { className: 'fontBunueloSemiBold', enabled: true, rotation: -90, y: 10, align: 'center', format: '{point.y:.1f}', style: { fontSize: '16px' } } }]
        }

        const options = {
            colors: ['#0A0', "#fcb827", "#A00", "#14bdee"],
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Performance'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                crosshair: true
            },
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: 'Total de Reuniões'
                    }
                },
                {
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Performance'
                    },
                    opposite: true
                }
            ],
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Bom',
                type: 'column',
                data: [jan.good, fev.good, mar.good, abr.good, mai.good, jun.good, jul.good, ago.good, set.good, out.good, nov.good, dez.good]

            }, {
                name: 'Regular',
                type: 'column',
                data: [jan.regular, fev.regular, mar.regular, abr.regular, mai.regular, jun.regular, jul.regular, ago.regular, set.regular, out.regular, nov.regular, dez.regular]

            }, {
                name: 'Ruim',
                type: 'column',
                data: [jan.bad, fev.bad, mar.bad, abr.bad, mai.bad, jun.bad, jul.bad, ago.bad, set.bad, out.bad, nov.bad, dez.bad]

            }, {
                name: 'Performance',
                type: 'spline',
                yAxis: 1,
                data: [jan.percent, fev.percent, mar.percent, abr.percent, mai.percent, jun.percent, jul.percent, ago.percent, set.percent, out.percent, nov.percent, dez.percent]

            }]
        }

        return (
            <>
                <Layout>
                    <Container fluid>
                        {(session.user.role === "admin") &&
                            <>
                                <Row className="mb-3">
                                    <Col xs={12} sm={6} className="my-2">
                                        <Card className="border-0">
                                            <Card.Header className="bg-white border-0">
                                                <h5 className="py-2 text-center color-grey">Clientes</h5>
                                            </Card.Header>
                                            <Card.Body className="border-0">
                                                <HighchartsReact highcharts={Highcharts} options={optionsAdminClients} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} sm={6} className="my-2">
                                        <Card className="border-0">
                                            <Card.Header className="bg-white border-0">
                                                <h5 className="py-2 text-center color-grey">Pessoas</h5>
                                            </Card.Header>
                                            <Card.Body className="border-0">
                                                <HighchartsReact highcharts={Highcharts} options={optionsAdminCollaborators} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="d-flex calendar-grid mb-2 mr-1 ml-1">
                                    <Col xs={12} className="p-0 text-right">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.month} className="dropdown-filter" onChange={(event) => { this.setState({ month: event.target.value, load: true }); this.getClientHours(event.target.value); this.getUsersHours(event.target.value) }} required>
                                            {[{ name: "Janeiro", value: "jan" }, { name: "Fevereiro", value: "fev" }, { name: "Março", value: "mar" }, { name: "Abril", value: "abr" }, { name: "Maio", value: "mai" }, { name: "Junho", value: "jun" }, { name: "Julho", value: "jul" }, { name: "Agosto", value: "ago" }, { name: "Setembro", value: "set" }, { name: "Outubro", value: "out" }, { name: "Novembro", value: "nov" }, { name: "Dezembro", value: "dez" }].map((item, index) => (
                                                <option value={item.value} key={index}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12} sm={12} className="my-2">
                                        <Card className="border-0">
                                            <Card.Header className="bg-white border-0">
                                                <h5 className="py-2 text-center color-grey">Horas Clientes, {this.state.month}/{new Date().getFullYear()}</h5>
                                            </Card.Header>
                                            <Card.Body className="border-0">
                                                <HighchartsReact highcharts={Highcharts} options={optionsClientsHours} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="d-flex calendar-grid mb-2 mr-1 ml-1">
                                    <Col xs={12} className="p-0 text-right">
                                        <Form.Control as="select" id="dropdown-basic-button22" name="team" value={this.state.team} className="dropdown-filter mr-2"  onChange={(event) => { this.setState({ team: event.target.value, load: true }); this.getUsersHours(this.state.month,  event.target.value)}} required>
                                            <option value="">Todos</option>
                                            {teams.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.month} className="dropdown-filter" onChange={(event) => { this.setState({ month: event.target.value, load: true }); this.getClientHours(event.target.value); this.getUsersHours(event.target.value) }} required>
                                            {[{ name: "Janeiro", value: "jan" }, { name: "Fevereiro", value: "fev" }, { name: "Março", value: "mar" }, { name: "Abril", value: "abr" }, { name: "Maio", value: "mai" }, { name: "Junho", value: "jun" }, { name: "Julho", value: "jul" }, { name: "Agosto", value: "ago" }, { name: "Setembro", value: "set" }, { name: "Outubro", value: "out" }, { name: "Novembro", value: "nov" }, { name: "Dezembro", value: "dez" }].map((item, index) => (
                                                <option value={item.value} key={index}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12} sm={12} className="my-2">
                                        <Card className="border-0">
                                            <Card.Header className="bg-white border-0">
                                                <h5 className="py-2 text-center color-grey">Horas Usuários, {this.state.month}/{new Date().getFullYear()} </h5>
                                            </Card.Header>
                                            <Card.Body className="border-0">
                                                <HighchartsReact highcharts={Highcharts} options={optionsUsersHours} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className="d-flex calendar-grid mb-2 mr-1 ml-1">
                            <Col xs={12} className="p-0 text-right">
                                <Form.Control as="select" id="dropdown-basic-button22" defaultValue="" className="dropdown-filter mb-3" name="clients" onChange={this.myChangeHandler} required>
                                    <option value="">Geral</option>
                                    {this.state.clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                                {/* <InputGroup className="d-flex align-items-center justify-content-end">
                                    <Form.Control type="date" name="startDate" value={startDate} className="dropdown-filter w-25" onChange={this.filterDashboardPerMonth.bind(this)} />
                                    <Form.Control type="date" name="endDate" value={endDate} className="dropdown-filter w-25" onChange={this.filterDashboardPerMonth.bind(this)} />
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Boas" link="#" value={good} icon={faSmileBeam} iconColor={"#0a0"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Regulares" link="#" value={regular} icon={faMeh} iconColor={"#ffcf1d"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Ruins" link="#" value={bad} icon={faAngry} iconColor={"#a00"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber link="#" title="Total" value={(percent !== null) ? percent + "%" : 0 + "%"} icon={(percent > 89) ? faSmileBeam : (percent > 79 && percent < 90) ? faMeh : faAngry} iconColor={(percent > 89) ? "#0a0" : (percent > 79 && percent < 90) ? "#ffcf1d" : "#a00"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card className="border-0">
                                    <Card.Header className="bg-white border-0">
                                        <h5 className="py-2 text-center color-grey">Reuniões no Ano</h5>
                                    </Card.Header>
                                    <Card.Body className="border-0">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={options}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";
import Moment from "moment"

export default class Reunion extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        modalView: false,
        load: false,
        reunion: [],
        clients: [],
        clientId: '',
        reunionClient: [],
        reunionUser: [],
        clearReunion: { client: '', comments: '', userEvaluation: '', date: '' }
    }

    componentDidMount() {
        this.getLoad();
    }

    resetForm = () => {
        this.setState({ reunion: this.state.reunion })
    }

    getLoad = async () => {
        this.getInformation(1, this.state.clientId);
        this.getClients()
    }

    getInformation = async (page = 1, clientId = '') => {
        this.setState({ load: true })
        let session = this.state.session;
        const response = await api.get(`/reunion?page=${page}&company=${session.user.company}&clientId=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false });
    }

    getClients = async () => {
        const clients = await api.get(`/client?sortBy=name&order=ASC&limit=9999&status=active`);
        this.setState({ clients: clients.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "company") {
            this.getClients(val)
            this.setState({
                reunion: {
                    ...this.state.reunion,
                    [nam]: val
                }
            });
        } else {
            this.setState({
                reunion: {
                    ...this.state.reunion,
                    [nam]: val
                }
            });
        }
    }

    myChangeSelectHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "clients") {
            this.getInformation(1, val)
            this.setState({ clientId: val });
        }
    }

    toggleModal = (reunion = {}) => {
        document.querySelector("form").reset();
        document.getElementById("text-area").value = null;
        document.getElementById("date").value = null;
        this.setState({ modal: !this.state.modal, reunion: this.state.clearReunion });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
        if (this.state.session.user.role === "admin") {
            this.getClients()
        }
    }

    toggleModalView = (reunion = {}, reunionClient = {}, reunionUser = {}) => {
        this.setState({ modalView: !this.state.modalView, reunion: reunion, reunionClient: reunionClient, reunionUser: reunionUser });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    delete = async (reunion) => {
        swal({ title: "Atenção", text: "Deseja remover esta reunião?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/reunion/${reunion._id}`)
                        .then(response => {
                            this.getInformation(this.state.page, this.state.clientId);
                        }).catch(error => {
                            this.getInformation(this.state.page, this.state.clientId);
                            console.log(error)
                        });
                }
            });
    }

    save = async (event) => {
        this.setState({ load: true })
        event.preventDefault();
        const reunion = this.state.reunion;
        let session = this.state.session;
        reunion.company = session.user.company._id;
        if (reunion._id !== undefined) {
            await api.put(`/reunion/${reunion._id}`, reunion)
                .then(response => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Reunião editada com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar a reunião, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            await api.post(`/reunion`, reunion)
                .then(response => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Reunião cadastrada com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    console.log(error);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a reunião, tente novamente mais tarde." });
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const reunions = this.state.arr;
        const { modal, modalView, reunion, clients, reunionClient, reunionUser } = this.state;
        
        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={10} className="text-left">
                                <Header title="Reuniões" btn={true} callbackParent={this.toggleModal} />
                            </Col>
                            <Col xs={12} sm={2} className="text-right">
                                <div className="input-group mb-3">
                                    <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.myChangeSelectHandler} required>
                                        <option value='' disabled selected>Filtro cliente</option>
                                        <option value=''>Geral</option>
                                        {clients.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            Cliente
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Responsável
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Data
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Avaliação responsável
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Avaliação cliente
                                        </Col>
                                        <Col xs={12} sm={2}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {reunions.map((reunion, index) => (
                                    <CardBody key={index}>
                                        <Row className={(reunion.userEvaluation === reunion.clientEvaluation ||  reunion.clientEvaluation === undefined) ? "align-items-center text-muted" : "align-items-center text-danger"}>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Cliente:</span>{reunion.client.name}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Responsável:</span>{(reunion.user !== undefined && reunion.user !== null) ? reunion.user.name : "-"}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data:</span>{Moment(reunion.date).format('DD/MM/YYYY')}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Avaliação responsável:</span>{reunion.userEvaluation}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Avaliação cliente:</span>{(reunion.clientEvaluation !== undefined) ? reunion.clientEvaluation : "Não informado"}
                                            </Col>
                                            <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                <span className="c-pointer ml-3" onClick={() => this.toggleModalView(reunion, reunion.client, reunion.user)}><MaterialIcon icon="remove_red_eye" /></span>
                                                {/* <span className="c-pointer ml-3" onClick={() => this.toggleModal(reunion)}><MaterialIcon icon="edit" /></span> */}
                                                <span className="c-pointer ml-3" onClick={() => this.delete(reunion)}><MaterialIcon icon="close" /></span>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                ))}
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(reunion._id !== undefined) ? "Editar reunião" : "Adicionar reunião"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="client" autoComplete="off" selected={(reunion._id !== undefined) ? reunion.client : null} onChange={(event) => this.myChangeHandler(event)} required >
                                    <option></option>
                                    {clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="date" autoComplete="off" id="date" selected={(reunion._id !== undefined) ? reunion.date : null} onChange={(event) => this.myChangeHandler(event)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Título</Form.Label>
                                <Form.Control type="text" name="title" autoComplete="off" selected={(reunion._id !== undefined) ? reunion.title : null} onChange={(event) => this.myChangeHandler(event)} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Avaliação <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="userEvaluation" autoComplete="off" selected={(reunion._id !== undefined) ? reunion.userEvaluation : null} onChange={(event) => this.myChangeHandler(event)} required >
                                    <option></option>
                                    {["Boa", "Regular", "Ruim"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Observações <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control rows="3" as="textarea" id="text-area" name="comments" autoComplete="off" selected={(reunion._id !== undefined) ? reunion.comments : null} onChange={(event) => this.myChangeHandler(event)} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(reunion._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={modalView}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Reunião - {Moment(reunion.date).format('DD/MM/YYYY')}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalView()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Cliente</h6>
                            <p className="text-muted">{reunionClient.name}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Título</h6>
                            <p className="text-muted">{reunion.title}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Avaliação cliente</h6>
                            <p className="text-muted">{(reunion.clientEvaluation !== undefined) ? reunion.clientEvaluation : "Não informado"}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Responsável</h6>
                            <p className="text-muted">{(reunionUser !== undefined && reunionUser !== null) ? reunionUser.name : "-"}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Avaliação responsável</h6>
                            <p className="text-muted">{reunion.userEvaluation}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Observações</h6>
                            <p className="text-muted">{reunion.comments}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Avaliação do cliente</h6>
                            <p className="text-muted">{(reunion.clientEvaluation !== undefined) ? reunion.clientEvaluation : "Não informado"}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Observações do cliente</h6>
                            <p className="text-muted">{(reunion.clientComments !== undefined) ? reunion.clientComments : "Não informado"}</p>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
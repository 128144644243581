import React, { Component } from "react";
import Sidebar from "../Sidebar";

export default class Layout extends Component {

    render() {

        return (
            <Sidebar>
                {this.props.children}
            </Sidebar>
        );
    }
}
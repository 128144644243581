import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class ClientAccess extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        load: false,
        access: [],
        client: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({load: true});
        const { id } = this.props.match.params;
        const response = await api.get(`/access-client/${id}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ load: false, arr: docs, arrInfo, page });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            access: {
                ...this.state.access,
                [nam]: val
            }
        });
    }

    toggleModal = (access = {}) => {
        document.getElementById("myForm").reset();
        document.getElementById("text-area").value = null;
        this.setState({ modal: !this.state.modal, access: access });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (access) => {
        swal({ title: "Atenção", text: "Deseja remover este acesso?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/access/${access._id}`)
                    .then(response => {
                        this.load(this.state.page, this.state.access._id);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const access = this.state.access;
        if (access._id !== undefined) {
            await api.put(`/access/${access._id}`, access)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Acesso editado com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o acesso, tente novamente mais tarde." });
                    this.load();
                    console.log(error)
                });
        } else {
            access.client = this.state.clientId;
            await api.post(`/access`, access)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Acesso adicionado com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar o acesso, tente novamente mais tarde." });
                    this.load();
                    console.log(error)
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const client = this.state.client;
        const acc = this.state.arr;
        const modal = this.state.modal;
        const access = this.state.access;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Acessos" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Acessos</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Tipo
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Login
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Password
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Acessar
                                        </Col>
                                        <Col xs={12} sm={3}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}> 
                                {(this.state.arrInfo.total > 0) ? 
                                    acc.map((access, index) => (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={3}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo:</span>{access.type}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Login:</span>{access.login}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Password:</span>{access.password}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Acessar:</span>
                                                    {(access.hasOwnProperty('url') !== false && access.url !== '') ? <a href={access.url} target="_blank" rel="noopener noreferrer" className="not-underline">Clique aqui</a> : "Ignorado"}
                                                </Col>
                                                <Col xs={12} sm={3} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModal(access)}><MaterialIcon icon="edit" /></span>
                                                    <span className="c-pointer ml-3" onClick={() => this.delete(access)}><MaterialIcon icon="close" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    ))
                                    :
                                    <CardBody>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={12} className="text-center">
                                                Não há acessos cadastrados.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Col>
                        </Row>

                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(access._id !== undefined) ? "Editar acesso" : "Adicionar acesso"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="type" value={access.type} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Banco de Dados", "Bing Ads", "Blog", "cPanel", "Developer Apple", "Dominio", "E-consulters", "Facebook", "FTP", "Giphy", "Google", "Gmail", "Hospedagem", "Hotjar", "Instagram", "Instamizer/Postgrain", "LinkedIn", "Linktree", "Locaweb", "Mailchimp", "Merchant Center", "Pinterest", "RD Station", "Registro BR", "Seekr", "Shutterstock", "Site", "Smarthint", "Tik Tok", "Trip Advisor", "Twitter", "Wix", "Wordpress", "Youtube", "Outros"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">URL <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="url" value={access.url || ""} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Login <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="login" value={access.login || ""} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Password <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="password" value={access.password || ""} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Comentários</Form.Label>
                                <Form.Control rows="3" as="textarea" id="text-area" name="comments" type="text" value={access.comments || ""} onChange={this.myChangeHandler} />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(access._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class ClientReport extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        report: [],
        client: [],
        load: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({load: true})
        const { id } = this.props.match.params;
        const response = await api.get(`/report-client/${id}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({
            load: false,
            arr: docs,
            arrInfo,
            page
        });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "file") {
            this.setState({
                report: {
                    ...this.state.report,
                    [nam]: event.target.files[0],
                    fileName: val
                }
            });
        } else {
            this.setState({
                report: {
                    ...this.state.report,
                    [nam]: val
                }
            });
        }
    }

    toggleModal = (report = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, report: report });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (report) => {
        swal({ title: "Atenção", text: "Deseja remover este relatório?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/report/${report._id}`)
                    .then(response => {
                        this.load(this.state.page, this.state.report._id);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    save = async (event) => {
        const { id } = this.props.match.params;
        event.preventDefault();
        this.setState({load: true});
        const report = this.state.report;
        const formData = new FormData();
        formData.append('file', report.file);
        formData.append('name', report.name);
        formData.append('type', report.type);
        formData.append('month', report.month);
        formData.append('year', report.year);
        formData.append('client', id);

        await api.post("/report", formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(response => {
                this.load(this.state.page);
                this.toggleModal();
                swal({ icon: "success", title: "Sucesso!", text: "Relatório cadastrado com sucesso." });
            })
            .catch(err => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar o relatório, tente novamente mais tarde." });
                this.setState({load: false})
                console.log(err);
            });
        document.getElementsByTagName("form")[0].reset();
    }

    render() {
        const client = this.state.client;
        const repo = this.state.arr;
        const modal = this.state.modal;
        const report = this.state.report;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Relatórios" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Relatórios</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                            <Col xs={12} sm={3}>Nome</Col>
                                            <Col xs={12} sm={3}>Período</Col>
                                            <Col xs={12} sm={2}>Mês/Ano</Col>
                                            <Col xs={12} sm={2}>Relatório</Col>
                                            <Col xs={12} sm={2}></Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                            {(this.state.arrInfo.total > 0) ? 
                            repo.map((report, index) => (
                                <CardBody key={index}>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{report.name}
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Período:</span>{report.type}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Mês/Ano:</span>{report.month}/{report.year}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Acessar:</span>
                                            {(report.hasOwnProperty('file') !== false && report.file !== '') ? <a href={report.file} target="_blank" rel="noopener noreferrer" className="not-underline">Clique aqui</a> : "Ignorado"}
                                        </Col>
                                        <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                            <span className="c-pointer ml-3" onClick={() => this.delete(report)}><MaterialIcon icon="close" /></span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            ))
                            :
                                <CardBody>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={12} className="text-center">
                                            Não há relatórios cadastrado.
                                        </Col>
                                    </Row>
                                </CardBody>
                            }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(report._id !== undefined) ? "Editar acesso" : "Adicionar acesso"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome</Form.Label>
                                <Form.Control type="text" name="name" onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Período</Form.Label>
                                <Form.Control as="select" name="type" onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Diário", "Semanal", "Mensal", "Trimestral", "Semestral", "Anual"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Mês</Form.Label>
                                <Form.Control as="select" name="month" onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Ano</Form.Label>
                                <Form.Control as="select" name="year" onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {[2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Arquivo</Form.Label>
                                <label htmlFor="file" className="c-pointer form-label w-100 border mb-0 d-flex align-items-center justify-content-between">
                                    <span className="ml-2 color-grey" style={{ fontSize: '12px' }}> {report.fileName}</span>
                                    <span className="p-2 bkg-primary color-white">Selecionar Arquivo</span>
                                    <input type="file" name="file" id="file" accept=".pdf" className="d-none" onChange={(e) => this.myChangeHandler(e)} required />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(report._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import Layout from "../../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WidgetNumber from "../../../components/WidgetNumber";
import { decodeToken } from "../../../services/auth";
import { faAngry } from '@fortawesome/free-regular-svg-icons';
import { faMeh } from '@fortawesome/free-regular-svg-icons';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from "../../../services/api";
import Loading from "../../../components/Loading";

export default class Dashboard extends Component {

    state = {
        session: decodeToken(),
        good: 0,
        regular: 0,
        bad: 0,
        percent: 0,
        jan: { good: 0, regular: 0, bad: 0 },
        fev: { good: 0, regular: 0, bad: 0 },
        mar: { good: 0, regular: 0, bad: 0 },
        abr: { good: 0, regular: 0, bad: 0 },
        mai: { good: 0, regular: 0, bad: 0 },
        jun: { good: 0, regular: 0, bad: 0 },
        jul: { good: 0, regular: 0, bad: 0 },
        ago: { good: 0, regular: 0, bad: 0 },
        set: { good: 0, regular: 0, bad: 0 },
        out: { good: 0, regular: 0, bad: 0 },
        nov: { good: 0, regular: 0, bad: 0 },
        dez: { good: 0, regular: 0, bad: 0 },
        clients: [],
        load: false
    }

    componentDidMount() {
        this.getLoad();
    }

    getData = async (clientId) => {
        let user = this.state.session.user;
        this.setState({ load: true });
        if (user.client.length <= 1) {
            await api.get(`/reunion-dashboard-client/${user.client}`).then(response => {
                const { feedback, feedbackChart } = response.data;
                this.setState({ load: false, good: feedback.good, regular: feedback.regular, bad: feedback.bad, percent: feedback.percent, jan: feedbackChart.jan, fev: feedbackChart.fev, mar: feedbackChart.mar, abr: feedbackChart.abr, mai: feedbackChart.mai, jun: feedbackChart.jun, jul: feedbackChart.jul, ago: feedbackChart.ago, set: feedbackChart.set, out: feedbackChart.out, nov: feedbackChart.nov, dez: feedbackChart.dez });
            }).catch(error => {
                console.log(error.error);
            });
        } else {
            this.setState({ clientId })
            await api.get(`/reunion-dashboard-client/${clientId}`).then(response => {
                const { feedback, feedbackChart } = response.data;
                this.setState({ load: false, good: feedback.good, regular: feedback.regular, bad: feedback.bad, percent: feedback.percent, jan: feedbackChart.jan, fev: feedbackChart.fev, mar: feedbackChart.mar, abr: feedbackChart.abr, mai: feedbackChart.mai, jun: feedbackChart.jun, jul: feedbackChart.jul, ago: feedbackChart.ago, set: feedbackChart.set, out: feedbackChart.out, nov: feedbackChart.nov, dez: feedbackChart.dez });
            }).catch(error => {
                console.log(error.error);
            });
        }
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId });
        this.getData(clientId);
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({ clientId: val })
        this.getData(val);
    }

    render() {
        const { good, regular, bad, percent, jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez } = this.state;

        const options = {
            colors: ['rgba(0, 170, 0, .7)', 'rgba(255, 207, 29, .7)', 'rgba(170, 0, 0, .7)'],
            chart: { type: 'column' },
            title: { text: '' },
            xAxis: { categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'], crosshair: true },
            yAxis: [{ className: 'highcharts-color-0', min: 0, title: { text: '' } }, { className: 'highcharts-color-1', min: 0, title: { text: '' } }, { className: 'highcharts-color-2', min: 0, title: { text: '' } },],
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Boas',
                    data: [jan.good, fev.good, mar.good, abr.good, mai.good, jun.good, jul.good, ago.good, set.good, out.good, nov.good, dez.good]
                },
                {
                    name: 'Regulares',
                    data: [jan.regular, fev.regular, mar.regular, abr.regular, mai.regular, jun.regular, jul.regular, ago.regular, set.regular, out.regular, nov.regular, dez.regular]

                },
                {
                    name: 'Ruins',
                    data: [jan.bad, fev.bad, mar.bad, abr.bad, mai.bad, jun.bad, jul.bad, ago.bad, set.bad, out.bad, nov.bad, dez.bad]

                }
            ]
        }

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-5">
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Boas" link={'#'} value={good} icon={faSmileBeam} iconColor={"#0a0"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Regulares" link={'#'} value={regular} icon={faMeh} iconColor={"#ffcf1d"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Reuniões Ruins" link={'#'} value={bad} icon={faAngry} iconColor={"#a00"} />
                            </Col>
                            <Col xs={12} sm={3} className="my-2">
                                <WidgetNumber title="Total" link={'#'} value={(percent !== null) ? percent + "%" : 0 + "%"} icon={(percent > 89) ? faSmileBeam : (percent > 79 && percent < 90) ? faMeh : faAngry} iconColor={(percent > 89) ? "#0a0" : (percent > 79 && percent < 90) ? "#ffcf1d" : "#a00"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card className="border-0">
                                    <Card.Header className="bg-white border-0">
                                        <h5 className="py-2 text-center color-grey">Reuniões no Ano</h5>
                                    </Card.Header>
                                    <Card.Body className="border-0">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={options}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
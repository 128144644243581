import React, { Component } from "react";
import Layout from "../../../components/Layout";
import { decodeToken } from "../../../services/auth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import CardHeader from "../../../components/CardHeader";
import CardBody from "../../../components/CardBody";
import Loading from "../../../components/Loading/index";
import Pagination from "../../../components/Pagination/index";
import api from "../../../services/api";
import runrun_it from "../../../services/runrunit";

export default class Task extends Component {

    state = {
        tasks: [],
        session: decodeToken(),
        loading: false,
        page: 1,
        projectId: '',
        client: '',
        arrLength: [],
        count: ''
    }

    componentDidMount() {
        this.loadOnboarding();
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadTasksClient(pageNumber);
        this.setState({page: pageNumber})
    }

    nextPage = () => {
        const { page } = this.state;
        if (page > this.state.count/10) return;
        const pageNumber = page + 1;
        this.loadTasksClient(pageNumber);
        this.setState({page: pageNumber})
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.loadOnboarding(val);
    }

    loadOnboarding = async (client = this.state.session.user.client) => {
        const onboarding = await api.get(`/onboarding-by-client/${client}`);
        this.setState({projectId: onboarding.data.projectId, client: client})
        this.loadTasksClient();
    }

    loadTasksClient = async (page = 1) => {
        this.setState({ loading: true });
        let projectId = this.state.projectId
        const response = await runrun_it.get(`/tasks?project_id=${projectId}&sort=estimated_delivery_date&sort_dir=asc&limit=10&page=${page}`);
        const count = await runrun_it.get(`/tasks?project_id=${projectId}`);
        let arr = []
        arr.pages = parseFloat((count.data.length)/10);
        if(arr.pages <= 1){
            arr.pages = 1;
        }
        if(arr.pages > parseInt(arr.pages)){
            arr.pages = parseInt((arr.pages + 1))
        }
        this.setState({ tasks: response.data, loading: false, arrLength: arr, count: count.data.length });
    }

    render() {
        const {tasks, session} = this.state;
        const arrLength = this.state.arrLength;

        return (
            <>
            <Layout>
                <Container fluid>
                    <Row className="mb-4 ">
                        <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                            <Header title="Tarefas" btn={false} />
                        </Col>
                    </Row>
                    <Row>
                        {(tasks.length > 0) ?
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Título
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Tipo
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Time
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Data de Entrega
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Status
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            : <></>}
                        <Col xs={12}>
                            {tasks.map((task, index) => (
                                <CardBody key={index}>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Título:</span>{task.title}
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo:</span>{task.type_name}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Time:</span>{(task.team_name !== null) ? task.team_name : "Jobs X"}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data de entrega:</span>{(task.desired_date !== null) ? new Date(task.desired_date).toLocaleDateString() : new Date(task.estimated_delivery_date).toLocaleDateString()}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span>{task.board_stage_name}
                                        </Col>
                                    </Row>
                                </CardBody>
                            ))}


                            {(tasks.length === 0) ?
                                <p className="text-center text-muted mt-5">Nenhuma tarefa encontrada.</p>
                                : <></>}
                        </Col>
                    </Row>
                    <Pagination page={this.state.page} arrInfo={arrLength} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                </Container>
            </Layout>
            <Loading show={this.state.loading} />
            </>
        );
    }
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class WidgetNumber extends Component {

    render() {

        const styles = {
            link: {
                textDecoration: 'none'
            },
            card: {
                border: 0,
                backgroundColor: "#fff"
            },
            cardBody: {
                i: {
                    fontSize: "2em",
                    color: this.props.iconColor
                },
                strong: {
                    fontSize: "1.5em",
                    color: "#333"
                },
                small: {
                    color: (this.props.textColor === undefined) ? "inherit" : this.props.textColor,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }
            }
        }

        return (
            <Link to={this.props.link} style={styles.link} className={(this.props.link === "#") ? "c-default" : "c-pointer"}>
                <Card style={styles.card}>
                    <Card.Body>
                        <Row>
                            <Col xs={4} className="d-flex align-items-center">
                                <FontAwesomeIcon icon={this.props.icon} style={styles.cardBody.i} />
                            </Col>
                            <Col xs={8} className="d-flex align-items-center justify-content-end flex-column text-right">
                                <small className="d-block w-100 color-grey" style={styles.cardBody.small} title={this.props.title}>{this.props.title}</small>
                                <strong className="d-block w-100" style={styles.cardBody.strong}>{this.props.value}</strong>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Link>
        );
    }
}


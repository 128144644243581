import React, { Component } from "react";
import Layout from "../../../components/Layout";
import { decodeToken } from "../../../services/auth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import api from "../../../services/api";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Form from "react-bootstrap/Form";
import Modal from "../../../components/Modal";
import Button from "react-bootstrap/Button";
import MaterialIcon from "material-icons-react";
import swal from "sweetalert";
import Loading from "../../../components/Loading/index";
import Moment from "moment"

export default class Complaint extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        complaint: [],
        loading: false,
        modal: false,
        modalView: false,
        checklist: [],
        complaintClient: [],
        complaintUser: [],
        complaintChecklist: [],
        clientId: ''
    }

    componentDidMount() {
        this.getLoad();
        this.getComplaintChecklist();
    }

    loadComplaintClient = async (page = 1, clientId) => {
        this.setState({ loading: true });
        const response = await api.get(`/complaint?page=${page}&client=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({arr: docs, arrInfo, page, loading: false});
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId});
        this.loadComplaintClient(this.state.page, clientId);
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadComplaintClient(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.loadComplaintClient(pageNumber, this.state.clientId);
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            complaint: {
                ...this.state.complaint,
                [nam]: val
            }
        });
    }

    getComplaintChecklist = async () => {
        const response = await api.get("/complaint-checklist-all");
        this.setState({ checklist: response.data });
    }

    toggleModal = (complaint = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, complaint: complaint });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    toggleModalView = (complaint = {}, complaintClient = {}, complaintUser = {}, complaintChecklist = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modalView: !this.state.modalView, complaint: complaint, complaintClient: complaintClient, complaintUser: complaintUser, complaintChecklist: complaintChecklist });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }


    save = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        let complaint = this.state.complaint;
        if(complaint.client === undefined){
            complaint.client = this.state.clientId;
        }
        await api.post(`/complaint`, complaint)
            .then(response => {
                this.loadComplaintClient(this.state.page, this.state.clientId);
                swal({ icon: "success", title: "Sucesso!", text: "Reclamação cadastrada com sucesso." });
            }).catch(error => {
                this.loadComplaintClient(this.state.page, this.state.clientId);
                swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a reclamação, tente novamente mais tarde." });
            });
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({clientId: val})
        this.loadComplaintClient(this.state.page, val);
    }

    render() {
        const complaints = this.state.arr;
        const {modal, modalView, complaint, checklist, complaintChecklist, session} = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                                <Header title="Reclamações" btn={true} callbackParent={this.toggleModal} />
                            </Col>
                            {/* {( session.user.client.length > 1 && 
                                <Col xs={12} sm={4} className="text-right">
                                    <div className="input-group mb-3">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.mySelectHandler} required>
                                            {session.user.client.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Data
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Setor
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            Motivo
                                        </Col>
                                        <Col xs={12} sm={2}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {(complaints.length > 0) ? complaints.map((complaint, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data:</span>{Moment(complaint.date).format('DD/MM/YYYY')}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Setor:</span>{complaint.sector}
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Motivo:</span>{complaint.checklist.name}
                                            </Col>
                                            <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                <span className="c-pointer ml-3" onClick={() => this.toggleModalView(complaint, complaint.client, complaint.user, complaint.checklist)}><MaterialIcon icon="remove_red_eye" /></span>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                )) : 
                                    <p className="text-center text-muted mt-1">Nenhuma reclamação encontrada.</p>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(complaint._id !== undefined) ? "Editar reclamação" : "Adicionar reclamação"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={(session.user.client.length > 1) ? 4 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="date" defaultValue={Moment(complaint.date).format('DD/MM/YYYY')} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={(session.user.client.length > 1) ? 4 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Setor <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="sector" value={complaint.sector} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Content Marketing", "Criação", "Customer Success", "Mídia", "Planejamento", "Projetos", "Social Media", "XQuad"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        {/* {((session.user.client.length > 1) ? 
                            <Col xs={12} sm={4} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente</Form.Label>
                                <Form.Control as="select" name="client" value={complaint.client} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {session.user.client.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            : <></>
                        )} */}
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Motivo <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="checklist" value={complaint.checklist} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {checklist.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Observações <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control rows="3" as="textarea" name="comments" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(complaint._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={modalView}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Reclamação - {Moment(complaint.date).format('DD/MM/YYYY')}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalView()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Setor</h6>
                            <p className="text-muted">{complaint.sector}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Motivo</h6>
                            <p className="text-muted">{complaintChecklist.name}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Observações</h6>
                            <p className="text-muted">{complaint.comments}</p>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.loading} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading/index";

export default class Onboarding extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        onboarding: [],
        clients: [],
        active: true,
        clientId: '',
        load: false,
    }

    componentDidMount() {
        this.getClients()
        this.getInformation(1, (this.state.active ? 'Ativo' : 'Inativo'));
    }

    getClients = async () => {
        const clients = await api.get(`/client?sortBy=name&order=ASC&limit=9999`);
        this.setState({ clients: clients.data });
    }

    getInformation = async (page = 1, status = (this.state.active ? 'Ativo' : 'Inativo'), clientId = this.state.clientId) => {
        this.setState({ load: true });
        const { session } = this.state;
        const response = await api.get(`/onboarding?page=${page}&company=${session.user.company._id}&client=${clientId}&status=${status}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ load: false, arr: docs, arrInfo, page });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "company") {
            this.getClients(val)
            this.setState({
                onboarding: {
                    ...this.state.onboarding,
                    [nam]: val
                }
            });
        } else {
            this.setState({
                onboarding: {
                    ...this.state.onboarding,
                    [nam]: val
                }
            });
        }
    }

    myChangeSelectHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "clients") {
            this.getInformation(1,'Ativo', val)
            this.setState({ clientId: val, active: true });
        }
    }

    toggleModal = (onboarding = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, onboarding: onboarding });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
        if (this.state.session.user.role === "admin") {
            this.getClients()
        }
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInformation(pageNumber, (this.state.active ? 'Ativo' : 'Inativo'));
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInformation(pageNumber, (this.state.active ? 'Ativo' : 'Inativo'));
    }

    delete = async (onboarding) => {
        swal({ title: "Atenção", text: "Deseja remover este onboarding?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/onboarding/${onboarding._id}`)
                        .then(response => {
                            this.getInformation(this.state.page, (this.state.active ? 'Ativo' : 'Inativo'));
                        }).catch(error => {
                            console.log(error)
                        });
                }
            });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        const { session } = this.state;
        let onboarding = this.state.onboarding;
        onboarding.company = session.user.company._id

        let client = await api.get(`/client/${onboarding.client}`)
        onboarding.runrunitId = client.data.runrunitId;
        if (onboarding._id !== undefined) {
            await api.put(`/onboarding/${onboarding._id}`, onboarding)
                .then(response => {
                    this.getInformation(this.state.page, (this.state.active ? 'Ativo' : 'Inativo'));
                    swal({ icon: "success", title: "Sucesso!", text: "Onboarding editado com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, (this.state.active ? 'Ativo' : 'Inativo'));
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o onboarding, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            await api.post(`/onboarding`, onboarding)
                .then(response => {
                    this.getInformation(this.state.page, (this.state.active ? 'Ativo' : 'Inativo'));
                    swal({ icon: "success", title: "Sucesso!", text: "Onboarding cadastrado com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, (this.state.active ? 'Ativo' : 'Inativo'));
                    console.log(error);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar o onboarding, tente novamente mais tarde." });
                });
        }
        this.toggleModal({});
    }

    render() {
        const onboardings = this.state.arr;
        const { modal, onboarding, clients, session } = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-1">
                            <Col xs={12} sm={(session.user.role === "admin") ? 10 : 12} className="text-left">
                                <Header title="Onboarding" btn={false}  />
                            </Col>
                            <Col xs={12} sm={2} className="text-right">
                                <div className="input-group mb-3">
                                    <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.myChangeSelectHandler} required>
                                        <option value='' disabled defaultValue>Filtro cliente</option>
                                        <option value=''>Geral</option>
                                        {clients.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} sm={12} className="text-right">
                                <span className="text-muted c-pointer" onClick={() => {
                                    const { active } = this.state;
                                    console.log(active);
                                    this.setState({ active: !active })
                                    this.getInformation(1, (active ? 'Inativo' : 'Ativo'));
                                }}>Ver {(this.state.active ? 'inativos' : 'ativos')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Cliente
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Projeto
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Criado em
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Horas gastas mês
                                        </Col>
                                        <Col xs={12} sm={1}>
                                            Status
                                        </Col>
                                        <Col xs={12} sm={1}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {onboardings.map((onboarding, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Cliente: </span>{onboarding.client.name}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Projeto: </span>{onboarding.name}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Criado em: </span>{new Date(onboarding.createdAt).toLocaleDateString()}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Horas gastas mês: </span>{(onboarding.workedHours !== undefined) ? (onboarding.workedHours / 3600).toFixed(2) : '0.00'}hrs.
                                            </Col>
                                            <Col xs={12} sm={1}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span><span className={(onboarding.status === 'Ativo') ? "textActive" : "textInactive"}>{onboarding.status}</span>
                                            </Col>
                                            <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                <Link to={`/onboarding/tarefas/${onboarding._id}`} className="c-pointer ml-3 text-primary font-weight-bold no-hover text-uppercase small">Gerenciar</Link>
                                                {/* <span className="c-pointer ml-3" onClick={() => this.toggleModal(onboarding)}><MaterialIcon icon="edit" /></span>
                                                <span className="c-pointer ml-3" onClick={() => this.delete(onboarding)}><MaterialIcon icon="close" /></span> */}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                ))}
                                {onboardings.length === 0 &&
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} sm={12} className="d-flex justify-content-center text-muted align-items-center">
                                                Não foram encontrados onboardings.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(onboarding._id !== undefined) ? "Editar onboarding" : "Criar projeto de onboarding"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="name" value={onboarding.name} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="client" value={onboarding.client} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(onboarding._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import { formatDate, secondsToHms } from "../../services/utils";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import Month from "../../components/Month";
import swal from "sweetalert";
import axios from "axios";
import Loading from "../../components/Loading/index";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class Projects extends Component {

    state = {
        session: decodeToken(),
        jan: [], fev: [], mar: [], abr: [], mai: [], jun: [], jul: [], ago: [], set: [], out: [], nov: [], dez: [],
        year_month: new Date().getFullYear(),
        tasks: [],
        task: [],
        comments: [],
        checklist: [],
        types: [],
        page: 1,
        arrLength: [],
        count: '',
        modal: false,
        modalView: false,
        load: false,
        editor: [],
        teams: [],
        responsible_arr: []
    }

    componentDidMount() {
        this.load();
        this.loadTypes();

        ClassicEditor
            .create(document.querySelector('#editor'), {
                removePlugins: ['Heading'],
                toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link']
            })
            .then(editor => {
                this.setState({ editor: editor });
            })
            .catch(error => {
                console.error(error);
            });
        this.getTeamResponsible();
    }

    load = async (page = 1, year_month = this.state.year_month) => {
        this.setState({ load: true });
        const responseCalendar = await axios.get(`https://runrun.it/api/v1.0/tasks?project_id=2206519&sort=desired_date&sort_dir=asc&bypass_status_default=true`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } });
        const response = await axios.get(`https://runrun.it/api/v1.0/tasks?project_id=2206519&sort=desired_date&sort_dir=asc&limit=10&page=${page}`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } });
        const count = await axios.get(`https://runrun.it/api/v1.0/tasks?project_id=2206519&sort=desired_date&sort_dir=asc`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } });
        const jan = [], fev = [], mar = [], abr = [], mai = [], jun = [], jul = [], ago = [], set = [], out = [], nov = [], dez = [];
        let arr = []
        arr.pages = ((count.data.length / 10) > parseInt((count.data.length / 10)) ? parseInt((count.data.length / 10)) + 1 : parseInt((count.data.length / 10)));
        if (arr.pages <= 0) {
            arr.pages = 1;
        }
        responseCalendar.data.forEach(task => {
            if (new Date(task.scheduled_start_time).getFullYear() === parseInt(year_month)) {
                switch (new Date(task.desired_date).getMonth()) {
                    case 0:
                        jan.push(task);
                        break;
                    case 1:
                        fev.push(task);
                        break;
                    case 2:
                        mar.push(task);
                        break;
                    case 3:
                        abr.push(task);
                        break;
                    case 4:
                        mai.push(task);
                        break;
                    case 5:
                        jun.push(task);
                        break;
                    case 6:
                        jul.push(task);
                        break;
                    case 7:
                        ago.push(task);
                        break;
                    case 8:
                        set.push(task);
                        break;
                    case 9:
                        out.push(task);
                        break;
                    case 10:
                        nov.push(task);
                        break;
                    case 11:
                        dez.push(task);
                        break;
                    default:
                        break;
                }
            }
        });
        this.setState({ tasks: response.data, load: false, arrLength: arr, count: count.data.length, jan: jan, fev: fev, mar: mar, abr: abr, mai: mai, jun: jun, jul: jul, ago: ago, set: set, out: out, nov: nov, dez: dez });
    }

    loadTypes = async () => {
        let page = 0;
        let response = [];
        const data = [];
        do {
            response = await axios.get(`https://runrun.it/api/v1.0/task_types?page=${page}`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } });
            console.log(response.data.length)
            for (let i = 0; i < response.data.length; i++) {
                data.push(response.data[i]);
            }
            page++;
        } while (response.data.length !== 0);
        this.setState({
            types: data.filter(function (a) {
                return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
            }, Object.create(null))
        });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            task: {
                ...this.state.task,
                [nam]: val
            }
        });
    }


    toggleModal = (task = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, task: task });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    toggleModalView = async (task = {}) => {
        if (task.id !== undefined) {
            this.setState({ load: true });
            const response = await axios.get(`https://runrun.it/api/v1.0/tasks/${task.id}/comments`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } });
            if (task.checklist_id !== null) {
                const checklist = await axios.get(`https://runrun.it/api/v1.0/checklists/${task.checklist_id}/items`, { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } })
                this.setState({ checklist: checklist.data })
            } else {
                this.setState({ checklist: [] })
            };
            this.setState({ comments: response.data });
        }
        this.setState({ load: false, modalView: !this.state.modalView, task: task });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
        this.setState({ page: pageNumber })
    }

    nextPage = () => {
        const { page } = this.state;
        if (page > this.state.count / 10) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
        this.setState({ page: pageNumber })
    }

    delete = async (task) => {
        const config = { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } }
        swal({ title: "Atenção", text: "Deseja remover esta tarefa?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    this.setState({ load: true });
                    await axios.delete(`https://cors-anywhere.herokuapp.com/https://runrun.it/api/v1.0/tasks/${task.id}`, config)
                        .then(response => {
                            swal("Sucesso!", "Tarefa removida com sucesso!", "success");
                            this.setState({ load: false });
                            this.load();
                        }).catch(error => {
                            swal("Erro!", "Erro ao remover a tarefa, tente novamente mais tarde.", "error");
                            this.setState({ load: false });
                        });
                }
            });
    }

    getTeamResponsible = async () => {
        const config = { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } }
        const response = await axios.get("https://runrun.it/api/v1.0/users?team_id=116280", config);
        this.setState({ responsible_arr: response.data.filter(item => item.email === "diegofreitas@jobspace.com.br") })
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: !this.state.load });
        const editorData = this.state.editor.getData();
        let task = this.state.task;
        task.task_description = editorData;

        const config = { headers: { 'App-Key': "2c8f61e8e1251724574fa390b7eff99d", 'User-Token': "WrnZXM3P5Oyd43k0UCF" } }
        task.board_id = 307232;
        task.board_stage_id = 1197957;
        task.task_status_id = 1069406;
        task.project_id = 2206519;
        task.assignments = [{ "assignee_id": task.responsible, "team_id": 116280 }];

        await axios.post("https://runrun.it/api/v1.0/tasks", { task: task }, config)
            .then(response => {
                swal("Sucesso!", "Task criada com sucesso!", "success");
                if (task.task_description) {
                    let abc = {
                        created_at: response.data.scheduled_start_time,
                        description: this.state.task.task_description,
                        id: null,
                        task_id: response.data.id,
                        updated_at: response.data.scheduled_start_time
                    };
                    axios.put(`https://runrun.it/api/v1.0/tasks/${response.data.id}/description`, { task_description: abc }, config)
                        .then(response => {

                        }).catch(e => {
                            console.log("error " + e);
                        });
                }
            }).catch(error => {
                swal("Erro!", "Erro ao criar a task.", "error");
            });
        this.load();
        this.toggleModal();
    }

    render() {
        const { tasks, arrLength, task, types, checklist, comments, responsible_arr = [] } = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Tarefas - Projetos" btn={true} callbackParent={this.toggleModal} />
                        <Row>
                            <Col xs={12}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row className="mb-3">
                                        <Col>
                                            <Nav variant="pills" className="nav nav-fill">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Tarefas do Runrun.it</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" >Calendário</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <CardHeader>
                                                                <Row>
                                                                    <Col xs={12} sm={3}>
                                                                        Título
                                                            </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Status
                                                            </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Tipo
                                                            </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Data de Início
                                                            </Col>
                                                                    <Col xs={12} sm={2}>
                                                                        Entrega Prevista
                                                            </Col>
                                                                    <Col xs={12} sm={1}>

                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                        </Col>
                                                        <Col xs={12}>
                                                            {tasks.map((item, index) => (
                                                                <CardBody key={index} >
                                                                    <Row className="align-items-center text-muted">
                                                                        <Col xs={12} sm={3}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Título:</span>{item.title}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span>{item.task_status_name}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo:</span>{item.type_name}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data de início:</span>
                                                                            {/* {(item.scheduled_start_time !== null) ? new Date(item.scheduled_start_time).toLocaleDateString() : new Date(item.estimated_at).toLocaleDateString()} */}
                                                                            {(item.scheduled_start_time !== null) ? formatDate(item.scheduled_start_time) : <span className="text-weight-bold text-danger">Não informado.</span>}
                                                                        </Col>
                                                                        <Col xs={12} sm={2}>
                                                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Entrega prevista:</span>
                                                                            <span className={item.overdue !== "on_schedule" ? "font-weight-bold text-warning" : ""}>
                                                                                {(item.desired_date !== null) ? formatDate(item.desired_date_with_time) : <span className="font-weight-bold text-danger">Não informado.</span>}
                                                                            </span>
                                                                        </Col>
                                                                        <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                                            <span className="c-pointer ml-3" onClick={() => this.toggleModalView(item)}><MaterialIcon icon="remove_red_eye" /></span>
                                                                            <span className="c-pointer ml-3" onClick={() => this.delete(item)}><MaterialIcon icon="close" /></span>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                    <Pagination page={this.state.page} arrInfo={arrLength} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <Row className="d-flex calendar-grid bg-white">
                                                        <Month title={`Janeiro`} checklist={this.state.jan || []} />
                                                        <Month title={`Fevereiro`} checklist={this.state.fev || []} />
                                                        <Month title={`Março`} checklist={this.state.mar || []} />
                                                        <Month title={`Abril`} checklist={this.state.abr || []} />
                                                        <Month title={`Maio`} checklist={this.state.mai || []} />
                                                        <Month title={`Junho`} checklist={this.state.jun || []} />
                                                        <Month title={`Julho`} checklist={this.state.jul || []} />
                                                        <Month title={`Agosto`} checklist={this.state.ago || []} />
                                                        <Month title={`Setembro`} checklist={this.state.set || []} />
                                                        <Month title={`Outubro`} checklist={this.state.out || []} />
                                                        <Month title={`Novembro`} checklist={this.state.nov || []} />
                                                        <Month title={`Dezembro`} checklist={this.state.dez || []} />
                                                    </Row>
                                                    <Row className="mt-3 mb-5">
                                                        <Col xs={12} sm={12} className="d-flex align-items-center">
                                                            <span className="text-muted small">Ano {this.state.year_month}</span>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Modal show={this.state.modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(task._id !== undefined) ? "Editar Tarefa" : "Adicionar Tarefa"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Título</Form.Label>
                                <Form.Control type="text" name="title" defaultValue={task.title} required onChange={(event) => this.myChangeHandler(event)} />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo</Form.Label>
                                <Form.Control as="select" name="type_id" value={task.type_id} required onChange={(event) => this.myChangeHandler(event)}>
                                    <option></option>
                                    {types.map((type, index) => (
                                        <option key={index} value={type.id}>{type.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data de Início</Form.Label>
                                <Form.Control type="datetime-local" name="scheduled_start_time" defaultValue={task.scheduled_start_time} required onChange={(event) => this.myChangeHandler(event)} />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data Desejada</Form.Label>
                                <Form.Control type="datetime-local" name="desired_date" defaultValue={task.desired_date} required onChange={(event) => this.myChangeHandler(event)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Responsável</Form.Label>
                                <Form.Control as="select" name="responsible" value={task.responsible} required onChange={(event) => this.myChangeHandler(event)}>
                                    <option></option>
                                    {responsible_arr.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Descrição</Form.Label>
                                <div id="editor"></div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(task._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={this.state.modalView}>
                    <Row className="mb-4">
                        <Col xs={10} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{task.title} - #{task.id}</h5>
                        </Col>
                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => { this.toggleModalView() }}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Status</h6>
                            <p className="text-muted">{task.task_status_name}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Tipo</h6>
                            <p className="text-muted">{task.type_name}</p>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Data de início</h6>
                            <p className="text-muted">{(task.scheduled_start_time !== null) ? formatDate(task.scheduled_start_time) : <span className="text-weight-bold text-danger">Não informado.</span>}</p>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Entrega Prevista</h6>
                            <span className={task.overdue !== "on_schedule" ? "font-weight-bold text-warning" : ""}>
                                {(task.desired_date !== null) ? formatDate(task.desired_date_with_time) : <span className="font-weight-bold text-danger">Não informado.</span>}
                            </span>
                        </Col>
                        <Col xs={12} sm={4} className="mb-4">
                            <h6 className="text-primary">Tempo trabalhado</h6>
                            <p className="text-muted">{secondsToHms(task.time_worked).length > 0 ? secondsToHms(task.time_worked) : "0hrs"}.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="firstModal">
                                <Row className="mb-3">
                                    <Col>
                                        <Nav variant="pills" className="nav nav-fill">
                                            <Nav.Item>
                                                <Nav.Link eventKey="firstModal">Comentários</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link disabled={checklist.length < 1} eventKey="secondModal">Checklist</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey="firstModal">
                                        {comments.map((item, index) => (
                                            <div className="card mb-2" key={index}>
                                                <div className="card-body comments-card">
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <h6 className="font-font-weight-bold">{item.user_id !== null ? `${item.commenter_name}` : "Sistema"}</h6>
                                                        </Col>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-end">
                                                            <h6 className="font-font-weight-bold">{new Date(item.created_at).toLocaleDateString()}</h6>
                                                        </Col>
                                                        <Col xs={12} sm={12}>
                                                            <span className="text-muted">{item.text.replace(/<(.*?)>/g, "")}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="secondModal">
                                        {checklist.map((item, index) => (
                                            <div className="card mb-2" key={index}>
                                                <div className="card-body bg-light comments-card">
                                                    <Row>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-start">
                                                            <span className="text-muted">{item.description}</span>
                                                        </Col>
                                                        <Col xs={12} sm={6} className="d-flex align-items-center justify-content-end">
                                                            {(item.checked_at !== null) ?
                                                                <span className="text-success"><MaterialIcon icon="check" /></span>
                                                                :
                                                                <span className="text-danger"><MaterialIcon icon="close" /></span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}

import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";
import Moment from "moment";

export default class Compliment extends Component {

    state = {
        session: decodeToken(),
        load: false,
        arr: [],
        arrInfo: {},
        page: 1,
        typeCompliment: 0,
        modal: false,
        modalView: false,
        compliment: [],
        clients: [],
        companies: [],
        clientId: '',
        checklist: [],
        complimentClient: [],
        complimentUser: [],
        complimentChecklist: [],
        users: []
    }

    componentDidMount() {
        this.getLoad();
        this.getComplimentChecklist();
    }

    getLoad = async () => {
        this.getInformation(1,this.state.clientId);
        this.getClients();
        this.getUsers();
    }

    getInformation = async (page = 1, clientId = '') => {
        this.setState({ load: true });
        let session = this.state.session;
        const response = await api.get(`/compliment?page=${page}&company=${session.user.company._id}&clientId=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false });
    }

    getClients = async () => {
        const clients = await api.get(`/client?sortBy=name&order=ASC&limit=9999&status=active`);
        this.setState({ clients: clients.data });
    }

    getUsers = async () => {
        const { session } = this.state;
        const response = await api.get(`/user-all-run?company=${session.user.company}`);
        this.setState({ users: response.data });
    }

    getComplimentChecklist = async () => {
        const response = await api.get("/compliment-checklist-all");
        this.setState({ checklist: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === 'typeCompliment') {
            this.setState({ typeCompliment: parseInt(val) })
        }
        if (nam === "company") {
            this.getClients(val)
            this.getUsers(val)
            this.setState({
                compliment: {
                    ...this.state.compliment,
                    [nam]: val
                }
            });
        } else {
            this.setState({
                compliment: {
                    ...this.state.compliment,
                    [nam]: val
                }
            });
        }
    }

    myChangeSelectHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "clients") {
            this.getInformation(1, val)
            this.setState({ clientId: val });
        }
    }

    toggleModal = (compliment = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, compliment: compliment, typeCompliment: 0 });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
        if (this.state.session.user.role === "admin") {
            this.getClients()
            this.getUsers()
        }
    }

    toggleModalView = (compliment = {}, complimentClient = {}, complimentUser = {}, complimentChecklist = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modalView: !this.state.modalView, compliment: compliment, complimentClient: complimentClient, complimentUser: complimentUser, complimentChecklist: complimentChecklist });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    delete = async (compliment) => {
        swal({ title: "Atenção", text: "Deseja remover este elogio?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/compliment/${compliment._id}`)
                        .then(response => {
                            this.getInformation(this.state.page, this.state.clientId);
                        }).catch(error => {
                            this.getInformation(this.state.page, this.state.clientId);
                            console.log(error)
                        });
                }
            });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        const compliment = this.state.compliment;
        let session = this.state.session;
        compliment.company = session.user.company._id;
        if (compliment._id !== undefined) {
            await api.put(`/compliment/${compliment._id}`, compliment)
                .then(response => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Elogio editado com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o elogio, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            await api.post(`/compliment`, compliment)
                .then(response => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Elogio cadastrada com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page, this.state.clientId);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    console.log(error);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar o elogio, tente novamente mais tarde." });
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const compliments = this.state.arr;
        const { modal, modalView, compliment, clients, complimentClient, complimentUser, checklist, complimentChecklist, users } = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={10} className="text-left">
                                <Header title="Elogios" btn={true} callbackParent={this.toggleModal} />
                            </Col>
                            <Col xs={12} sm={2} className="text-right">
                                <div className="input-group mb-3">
                                    <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.myChangeSelectHandler} required>
                                        <option value='' disabled selected>Filtro cliente</option>
                                        <option value=''>Geral</option>
                                        {clients.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            Cliente
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Colaborador
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Data
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Setor/Colaborador
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Origem
                                        </Col>
                                        <Col xs={12} sm={2}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {compliments.map((compliment, index) => {

                                    const userName = (compliment.user !== undefined) ? compliment.user.name : "Cliente";

                                    return (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Cliente:</span>{compliment.client.name}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Colaborador:</span>{userName}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data:</span>{Moment(compliment.date).format('DD/MM/YYYY')}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Setor/Colaborador:</span>{(compliment.sector !== undefined) ? compliment.sector : compliment.userCompliment}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Origem:</span>{compliment.origin}
                                                </Col>
                                                <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModalView(compliment, compliment.client, compliment.user, compliment.checklist)}><MaterialIcon icon="remove_red_eye" /></span>
                                                    {/* <span className="c-pointer ml-3" onClick={() => this.toggleModal(compliment)}><MaterialIcon icon="edit" /></span> */}
                                                    <span className="c-pointer ml-3" onClick={() => this.delete(compliment)}><MaterialIcon icon="close" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(compliment._id !== undefined) ? "Editar elogio" : "Adicionar elogio"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="client" value={compliment.client} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="date" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={(this.state.typeCompliment !== 0) ? 6 : 12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Elogio para <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="typeCompliment" onChange={this.myChangeHandler} required >
                                    <option></option>
                                    <option value="1">Colaborador</option>
                                    <option value="2">Setor ou XQuad</option>
                                </Form.Control>
                            </Col>
                            {(this.state.typeCompliment === 1 &&
                                <Col xs={12} sm={6} className="mb-3">
                                    <Form.Label className="text-primary font-weight-bold small mb-2">Colaborador <sup className="ml-1 text-danger">*</sup></Form.Label>
                                    <Form.Control as="select" name="userCompliment" value={compliment.userCompliment} onChange={this.myChangeHandler} required >
                                        <option></option>
                                        {users.map((item, index) => (
                                            <option key={index} value={item.name}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            )}
                            {(this.state.typeCompliment === 2 &&
                                <Col xs={12} sm={6} className="mb-3">
                                    <Form.Label className="text-primary font-weight-bold small mb-2">Setor <sup className="ml-1 text-danger">*</sup></Form.Label>
                                    <Form.Control as="select" name="sector" value={compliment.sector} onChange={this.myChangeHandler} required >
                                        <option></option>
                                        {["Content Marketing", "Criação", "Customer Success", "Mídia", "Planejamento", "Projetos", "Social Media", "XQuad"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Motivo <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="checklist" value={compliment.checklist} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {checklist.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Origem <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="origin" value={compliment.origin} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Cliente", "Interno"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Observações <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control rows="3" as="textarea" name="comments" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(compliment._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={modalView}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Elogio - {Moment(compliment.date).format('DD/MM/YYYY')}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                           <span className="c-pointer" onClick={() => this.toggleModalView()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Cliente</h6>
                            <p className="text-muted">{complimentClient.name}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Colaborador</h6>
                            <p className="text-muted">{(complimentUser.name !== undefined) ? complimentUser.name : "Cliente"}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">{(compliment.sector !== undefined) ? 'Setor' : 'Colaborador'}</h6>
                            <p className="text-muted">{(compliment.sector !== undefined) ? compliment.sector : compliment.userCompliment}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Origem</h6>
                            <p className="text-muted">{compliment.origin}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Motivo</h6>
                            <p className="text-muted">{complimentChecklist.name}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Observações</h6>
                            <p className="text-muted">{compliment.comments}</p>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
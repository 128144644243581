import React, { Component } from "react";
import Layout from "../../../components/Layout";
import { decodeToken } from "../../../services/auth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../../../services/api";
import Header from "../../../components/Header";
import Month from "../../../components/Month";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Loading from "../../../components/Loading/index";
import runrun_it from "../../../services/runrunit";

export default class Calendar extends Component {

    state = {
        tasks: [],
        session: decodeToken(),
        year_month: new Date().getFullYear(),
        loading: false,
        projectId: '',
        client: ''
    }

    componentDidMount() {
        this.loadOnboarding();
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.loadOnboarding(val);
    }

    loadOnboarding = async (client = this.state.session.user.client) => {
        const onboarding = await api.get(`/onboarding-by-client/${client}`);
        this.setState({projectId: onboarding.data.projectId, client: client})
        this.loadTasksClient();
    }

    loadTasksClient = async (year_month = this.state.year_month) => {
        this.setState({ loading: true });
        let projectId = this.state.projectId
        const jan = [], fev = [], mar = [], abr = [], mai = [], jun = [], jul = [], ago = [], set = [], out = [], nov = [], dez = [];
        const response = await runrun_it.get(`/tasks?project_id=${projectId}&sort=estimated_delivery_date&sort_dir=asc&bypass_status_default=true`);
        response.data.forEach(task => {
            if (new Date(task.scheduled_start_time).getFullYear() === parseInt(year_month)) {
                switch (new Date(task.scheduled_start_time).getMonth()) {
                    case 0:
                        jan.push(task);
                        break;
                    case 1:
                        fev.push(task);
                        break;
                    case 2:
                        mar.push(task);
                        break;
                    case 3:
                        abr.push(task);
                        break;
                    case 4:
                        mai.push(task);
                        break;
                    case 5:
                        jun.push(task);
                        break;
                    case 6:
                        jul.push(task);
                        break;
                    case 7:
                        ago.push(task);
                        break;
                    case 8:
                        set.push(task);
                        break;
                    case 9:
                        out.push(task);
                        break;
                    case 10:
                        nov.push(task);
                        break;
                    case 11:
                        dez.push(task);
                        break;
                    default:
                        break;
                }
            }
        });
        this.setState({ tasks: {jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez}, loading: false });
    }

    nextMonth = () => {
        let year = parseInt(this.state.year_month);
        year++;
        this.setState({ year_month: year });
        this.loadTasksClient(year);
    }

    backMonth = () => {
        let year = parseInt(this.state.year_month);
        year--;
        this.setState({ year_month: year });
        this.loadTasksClient(year);
    }

    render() {
        const {tasks, session} = this.state;
        const year_month = this.state.year_month;

        return (
            <>
            <Layout>
                <Container fluid>
                    <Row className="mb-4 ">
                        <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                            <Header title="Calendário" btn={false} />
                        </Col>
                    </Row>
                    <Row className="d-flex calendar-grid bg-white">
                        <Month title={`Janeiro`} checklist={tasks.jan || []} />
                        <Month title={`Fevereiro`} checklist={tasks.fev || []} />
                        <Month title={`Março`} checklist={tasks.mar || []} />
                        <Month title={`Abril`} checklist={tasks.abr || []} />
                        <Month title={`Maio`} checklist={tasks.mai || []} />
                        <Month title={`Junho`} checklist={tasks.jun || []} />
                        <Month title={`Julho`} checklist={tasks.jul || []} />
                        <Month title={`Agosto`} checklist={tasks.ago || []} />
                        <Month title={`Setembro`} checklist={tasks.set || []} />
                        <Month title={`Outubro`} checklist={tasks.out || []} />
                        <Month title={`Novembro`} checklist={tasks.nov || []} />
                        <Month title={`Dezembro`} checklist={tasks.dez || []} />
                    </Row>
                    <Row className="mt-3 mb-5">
                        <Col xs={6} sm={6} className="px-0 d-flex align-items-center">
                            <span className="text-muted small">Ano {year_month}</span>
                        </Col>
                        <Col xs={6} sm={6} className="d-flex justify-content-end">
                            <ButtonGroup>
                                <Button onClick={() => this.backMonth()}>Anterior</Button>
                                <Button onClick={() => this.nextMonth()}>Próximo</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </Layout>
            <Loading show={this.state.loading} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";
import Moment from "moment"

export default class Complaint extends Component {

    state = {
        session: decodeToken(),
        load: false,
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        modalView: false,
        complaint: [],
        clients: [],
        companies: [],
        clientId: '',
        checklist: [],
        complaintClient: [],
        complaintUser: [],
        complaintChecklist: [],
    }

    componentDidMount() {
        this.getLoad();
        this.getComplaintChecklist();
    }

    getLoad = async () => {
        this.getInformation(1, this.state.clientId);
        this.getClients()
    }

    getInformation = async (page = 1, clientId = '') => {
        this.setState({ load: true });
        const response = await api.get(`/complaint?page=${page}&clientId=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false });
    }

    getClients = async () => {
        const response = await api.get(`/client?sortBy=name&order=ASC&limit=9999&status=active`);
        this.setState({ clients: response.data });
    }

    getComplaintChecklist = async () => {
        const response = await api.get("/complaint-checklist-all");
        this.setState({ checklist: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            complaint: {
                ...this.state.complaint,
                [nam]: val
            }
        });
    }

    myChangeSelectHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "clients") {
            this.getInformation(1, val)
            this.setState({ clientId: val });
        }
    }


    toggleModal = (complaint = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, complaint: complaint });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
        if (this.state.session.user.role === "admin") {
            this.getClients()
        }
    }

    toggleModalView = (complaint = {}, complaintClient = {}, complaintUser = {}, complaintChecklist = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modalView: !this.state.modalView, complaint: complaint, complaintClient: complaintClient, complaintUser: complaintUser, complaintChecklist: complaintChecklist });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInformation(pageNumber, this.state.clientId);
    }

    delete = async (complaint) => {
        swal({ title: "Atenção", text: "Deseja remover esta reclamação?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/complaint/${complaint._id}`)
                        .then(response => {
                            this.getInformation(this.state.page);
                        }).catch(error => {
                            this.getInformation(this.state.page);
                            console.log(error)
                        });
                }
            });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true });
        const complaint = this.state.complaint;
        let session = this.state.session;
        complaint.company = session.user.company._id;
        if (complaint._id !== undefined) {
            await api.put(`/complaint/${complaint._id}`, complaint)
                .then(response => {
                    this.getInformation(this.state.page);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Reclamação editada com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar a reclamação, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            await api.post(`/complaint`, complaint)
                .then(response => {
                    this.getInformation(this.state.page);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    swal({ icon: "success", title: "Sucesso!", text: "Reclamação cadastrada com sucesso." });
                }).catch(error => {
                    this.getInformation(this.state.page);
                    if (this.state.session.user.role === "admin") {
                        this.getClients()
                    }
                    console.log(error);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a reclamação, tente novamente mais tarde." });
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const complaints = this.state.arr;
        const { modal, modalView, complaint, clients, complaintClient, complaintUser, checklist, complaintChecklist } = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={10} className="text-left">
                                <Header title="Reclamações" btn={true} callbackParent={this.toggleModal} />
                            </Col>
                            <Col xs={12} sm={2} className="text-right">
                                <div className="input-group mb-3">
                                    <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.myChangeSelectHandler} required>
                                        <option value='' disabled selected>Filtro cliente</option>
                                        <option value=''>Geral</option>
                                        {clients.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            Cliente
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Criado por
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Data
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Setor
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Origem
                                        </Col>
                                        <Col xs={12} sm={2}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {complaints.map((complaint, index) => {

                                    const userName = (complaint.user !== undefined) ? complaint.user.name : "Cliente";

                                    return (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Cliente:</span>{complaint.client.name}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Criado por:</span>{userName}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data:</span>{Moment(complaint.date).format('DD/MM/YYYY')}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Setor:</span>{complaint.sector}
                                                </Col>
                                                <Col xs={12} sm={2}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Origem:</span>{complaint.origin}
                                                </Col>
                                                <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModalView(complaint, complaint.client, complaint.user, complaint.checklist)}><MaterialIcon icon="remove_red_eye" /></span>
                                                    {/* <span className="c-pointer ml-3" onClick={() => this.toggleModal(complaint)}><MaterialIcon icon="edit" /></span> */}
                                                    <span className="c-pointer ml-3" onClick={() => this.delete(complaint)}><MaterialIcon icon="close" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(complaint._id !== undefined) ? "Editar reclamação" : "Adicionar reclamação"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="client" value={complaint.client} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="date" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Setor <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="sector" value={complaint.sector} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Content Marketing", "Criação", "Customer Success", "Mídia", "Planejamento", "Projetos", "Social Media", "XQuad"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Origem <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="origin" value={complaint.origin} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Cliente", "Interno"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Motivo <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="checklist" value={complaint.checklist} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {checklist.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Observações <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control rows="3" as="textarea" name="comments" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(complaint._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={modalView}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Reclamação - {Moment(complaint.date).format('DD/MM/YYYY')}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalView()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Cliente</h6>
                            <p className="text-muted">{complaintClient.name}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Criado por</h6>
                            <p className="text-muted">{(complaintUser.name !== undefined) ? complaintUser.name : "Cliente"}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Setor</h6>
                            <p className="text-muted">{complaint.sector}</p>
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                            <h6 className="text-primary">Origem</h6>
                            <p className="text-muted">{complaint.origin}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Motivo</h6>
                            <p className="text-muted">{complaintChecklist.name}</p>
                        </Col>
                        <Col xs={12} sm={12} className="mb-4">
                            <h6 className="text-primary">Observações</h6>
                            <p className="text-muted">{complaint.comments}</p>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../components/Header";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../components/CardHeader/index";
import CardBody from "../../components/CardBody";
import Pagination from "../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../components/Loading/index";

export default class UserClient extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        load: false,
        page: 1,
        modal: false,
        user: [],
        client: [],
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ clientId: id });
        this.load();
        this.getClient();
    }

    load = async (page = 1) => {
        this.setState({load: true})
        const { id } = this.props.match.params;
        const response = await api.get(`/user-client-access/${id}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({
            load: false,
            arr: docs,
            arrInfo,
            page
        });
    }

    getClient = async () => {
        const { id } = this.props.match.params;
        const response = await api.get(`/client/${id}`);
        this.setState({ client: response.data });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "file") {
            this.setState({
                user: {
                    ...this.state.user,
                    [nam]: event.target.files[0],
                    fileName: val
                }
            });
        } else {
            this.setState({
                user: {
                    ...this.state.user,
                    [nam]: val
                }
            });
        }
    }

    toggleModal = (user = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, user: user });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.load(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.load(pageNumber);
    }

    delete = async (user) => {
        swal({ title: "Atenção", text: "Deseja remover este usuário?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/user-client/${user._id}`)
                    .then(response => {
                        this.load(this.state.page, this.state.client._id);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({load: true})
        const { id } = this.props.match.params;
        const user = this.state.user;
        const formData = new FormData();
        formData.append('file', user.file);
        formData.append('name', user.name);
        formData.append('email', user.email);
        if (user._id !== undefined) {
            await api.put(`/user-client/${user._id}`, user)
                .then(response => {
                    this.load();
                    swal({ icon: "success", title: "Sucesso!", text: "Usuário editado com sucesso." });
                }).catch(error => {
                    this.load();
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o usuário, tente novamente mais tarde." });
                    console.log(error)
                });
        } else {
            formData.append('runrunitId', this.state.client.runrunitId);
            formData.append('client', id);
            formData.append('company', this.state.client.company._id);
            formData.append('role', "client");
            await api.post(`/user-client`, formData, { headers: { 'content-type': 'multipart/form-data' } })
                .then(response => {
                    this.load(this.state.page, this.state.clientId);
                    swal({ icon: "success", title: "Sucesso!", text: "Usuário adicionado com sucesso." });
                }).catch(error => {
                    this.load();
                    swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar o usuário, tente novamente mais tarde." });
                    console.log(error)
                });
        }
        this.toggleModal({});
        document.getElementById("myForm").reset();
    }

    render() {
        const client = this.state.client;
        const users = this.state.arr;
        const modal = this.state.modal;
        const user = this.state.user;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Usuários" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="/clientes">Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/clientes/${client._id}/dashboard`}>{client.name}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Usuários</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>
                                            Nome
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            E-mail
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            Status
                                        </Col>
                                        <Col xs={12} sm={3}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                            {(this.state.arrInfo.total > 0) ? 
                                users.map((user, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{user.name}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">E-mail:</span>{user.email}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Status:</span>{user.status}
                                            </Col>
                                            <Col xs={12} sm={3} className="d-flex justify-content-end align-items-center">
                                                <span className="c-pointer ml-3" onClick={() => this.toggleModal(user)}><MaterialIcon icon="edit" /></span>
                                                <span className="c-pointer ml-3" onClick={() => this.delete(user)}><MaterialIcon icon="close" /></span>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                ))
                            :
                                <CardBody>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={12} className="text-center">
                                            Não há usuários cadastrados.
                                        </Col>
                                    </Row>
                                </CardBody>
                            }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(user._id !== undefined) ? "Editar usuário" : "Adicionar usuário"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome</Form.Label>
                                <Form.Control type="text" name="name" value={user.name || ""} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">E-mail</Form.Label>
                                <Form.Control type="email" name="email" value={user.email || ""} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Status</Form.Label>
                                <Form.Control as="select" name="status" value={user.status} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Ativo", "Inativo"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            {(user._id === undefined) ?
                                <Col xs={12} sm={6} className="mb-3">
                                    <Form.Label className="text-primary font-weight-bold small mb-2">Foto de Perfil</Form.Label>
                                    <label htmlFor="file" className="c-pointer form-label w-100 border mb-0 d-flex align-items-center justify-content-between">
                                        <span className="ml-2 color-grey" style={{ fontSize: '12px' }}> {user.fileName}</span>
                                        <span className="p-2 small">Selecionar Arquivo</span>
                                        <input type="file" name="file" id="file" className="d-none" onChange={(e) => this.myChangeHandler(e)} />
                                    </label>
                                </Col>
                                : <></>}
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(user._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
/***
 * 
 * Calcular diferença para datas do PROP
 */
export const dateCalculation = (d1, d2) => {
    return (new Date(d2).getTime() - new Date(d1).getTime()) / (24 * 60 * 60 * 1000);
}

export const isFloat = (value) => {
    return value === +value && value !== (value|0)
}

export const isInt = (value) => {
    return value === +value && value === (value|0);
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [day, month, year].join('/');
}

export const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hora, " : " horas, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto, " : " minutos, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " segundo" : " segundos") : "";
    return hDisplay + mDisplay + sDisplay; 
}

const leftPad = (value, totalWidth, paddingChar) => {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar || '0') + value;
}

export const startDate = () => String(new Date().getFullYear() + "-" + leftPad(parseInt(new Date().getMonth() + 1), 2) + "-01");

export const endDate = () => {
    const d = new Date();
    const lastDay = new Date(d.getFullYear(), leftPad(parseInt(d.getMonth() + 1), 2), 0).getDate();
    return String(d.getFullYear() + "-" + leftPad(parseInt(d.getMonth() + 1), 2) + "-" + lastDay);
}
import React, { Component } from "react";
import { decodeToken } from "../../../services/auth";
import Layout from "../../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Loading from "../../../components/Loading/index";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Pagination from "../../../components/Pagination/index";

export default class Report extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        load: false,
        clientId: ''
    }

    componentDidMount() {
        this.getLoad();
    }

    loadReportClient = async (page = 1, clientId) => {
        this.setState({load: true})
        const response = await api.get(`/report-client/${clientId}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false });
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId});
        this.loadReportClient(this.state.page, clientId);
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadReportClient(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.loadReportClient(pageNumber, this.state.clientId);
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({clientId: val})
        this.loadReportClient(this.state.page, val);
    }

    render() {
        const repo = this.state.arr;
        const {session} = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                                <Header title="Relatórios" btn={false} />
                            </Col>
                            {/* {( session.user.client.length > 1 && 
                                <Col xs={12} sm={4} className="text-right">
                                    <div className="input-group mb-3">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.mySelectHandler} required>
                                            {session.user.client.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                            <Col xs={12} sm={3}>Nome</Col>
                                            <Col xs={12} sm={3}>Período</Col>
                                            <Col xs={12} sm={3}>Mês/Ano</Col>
                                            <Col xs={12} sm={3}>Relatório</Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {repo.map((report, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{report.name}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Período:</span>{report.type}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Mês/Ano:</span>{report.month}/{report.year}
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Acessar:</span>
                                                {(report.hasOwnProperty('file') !== false && report.file !== '') ? <a href={report.file} target="_blank" rel="noopener noreferrer" className="not-underline">Clique aqui</a> : "Ignorado"}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                ))}
                                {(repo.length === 0) ?
                                    <p className="text-center text-muted mt-1">Nenhum relatório encontrado.</p>
                                : <></>}
                            </Col>
                        </Row>
                        {(repo.length > 0) ?
                            <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                        : <></>}
                    </Container>
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import api from "../../services/api";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import LogoGradient from "../../assets/img/jobsx-gradient.svg";
import Loading from "../../components/Loading/index";

export default class ForgotPassword extends Component {

    state = {
        email: '',
        error: false,
        success: false,
        load: false,
        message: '',
    };

    componentDidMount() {
        document.getElementsByTagName('html')[0].classList.add("h-100");
        document.getElementsByTagName('body')[0].classList.add("h-100");
        document.getElementById('root').classList.add("h-100");
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    forgotPassword = async (event) => {
        this.setState({ error: false, load: !this.state.load, message: '' });
        event.preventDefault();
        document.getElementById("password").classList.toggle("overflow-hidden", "blur");
        await api.post("/auth/forgot-password", this.state)
        .then(res => {
            if(res.data.success === true){
                this.setState({ success: true, load: false, message: "E-mail enviado para " + this.state.email });
            }
            else{
                this.setState({ error: true, load: false, message: res.data.message });
            }
        }).catch(err => {
            console.log("err",err);
            this.setState({ error: true, load: !this.state.load, message: "Erro! Não fora possível enviar e-mail." });
        });
    }

    render() {
        const load = this.state.load;
        const error = this.state.error;
        const success = this.state.success;
        const message = this.state.message;

        return (
            <div className="login h-100" id="password">
                <Container className="h-100">
                    <Row className="h-100 align-items-stretch">
                        <Col xs={12} sm={12} className="d-flex align-items-start">
                            <Navbar className="fixed-top justify-content-start">
                                <Navbar.Brand href="https://www.jobsx.com.br"><img src={LogoGradient} className="img-fluid" alt="" /></Navbar.Brand>
                            </Navbar>
                        </Col>
                        <Col xs={12} sm={5} className="d-flex align-items-center ml-auto">
                            <div className="w-100">
                                <h1 className="pb-4 pb-sm-0">Área X</h1>
                                <p className="pb-3">Alteração de senha.</p>
                                <Form className="w-100" onSubmit={this.forgotPassword}>
                                    <Form.Group className="pb-1">
                                        <Form.Control type="email" placeholder="Coloque seu e-mail." name="email" onChange={this.myChangeHandler} required />
                                    </Form.Group>
                                    <Row>
                                        <Col xs={12} className="text-center mb-2">
                                            {(error) ? <small className="text-danger font-weight-bold">{message}</small> : <></>}
                                            {(success) ? <small className="color-primary font-weight-bold">{message}</small> : <></>}
                                        </Col>
                                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                                            <Button variant="primary" type="submit" className="text-uppercase">
                                                Enviar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <span>JobsX {new Date().getFullYear()} &copy; CNPJ 35.031.143/0001-45. Todos os direitos reservados. Desenvolvido por: <a href="https://www.domatech.com.br">Domatech</a></span>
                        </Col>
                    </Row>
                </Container>
                <Loading show={load} />
            </div>
        );
    }

}
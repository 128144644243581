import React, { Component } from "react";
import { decodeToken } from "../../../services/auth";
import Layout from "../../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Loading from "../../../components/Loading/index";
// import Form from "react-bootstrap/Form";

export default class Dashboards extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        load: false,
        clientId: ''
    }

    componentDidMount() {
        this.getLoad();
    }

    getLoadDashboards = async (page = 1, clientId) => {
        this.setState({load: true})
        const response = await api.get(`/analytics-client/${clientId}&${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, load: false});
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId});
        this.getLoadDashboards(this.state.page, clientId);
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getLoadDashboards(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getLoadDashboards(pageNumber, this.state.clientId);
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({clientId: val})
        this.getLoadDashboards(this.state.page, val);
    }

    render() {
        const analytics = this.state.arr;
        const {session} = this.state;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                                <Header title="Analytics"/>
                            </Col>
                            {/* {( session.user.client.length > 1 && 
                                <Col xs={12} sm={4} className="text-right">
                                    <div className="input-group mb-3">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.mySelectHandler} required>
                                            {session.user.client.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {(analytics.length > 0) ? analytics.map((analytics, index) => (
                                    <Row key={index} className="mb-5">
                                        <Col xs={12}>
                                            <h4>{analytics.name}</h4>
                                        </Col>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe className="embed-responsive-item" title="This is a unique title" id="iframe1" src={analytics.link} allowFullScreen></iframe>
                                        </div>
                                    </Row>
                                )) : "Não há dashboards a serem apresentados."}
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
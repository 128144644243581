import React, { Component } from "react";
import Layout from "../../../components/Layout";
import { decodeToken } from "../../../services/auth";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../components/Header";
import api from "../../../services/api";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Pagination from "../../../components/Pagination/index";
import Modal from "../../../components/Modal";
import MaterialIcon from "material-icons-react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import Loading from "../../../components/Loading/index";
import Moment from "moment"

export default class ReunionClient extends Component {

    state = {
        arr: [],
        arrInfo: {},
        session: decodeToken(),
        loading: false,
        page: 1,
        modal: false,
        modalView: false,
        reunion: [],
        clientId: ''
    }

    componentDidMount() {
        this.getLoad();
    }

    loadReunionClient = async (page = 1, clientId) => {
        this.setState({ loading: true });
        const response = await api.get(`/reunion?page=${page}&client=${clientId}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ arr: docs, arrInfo, page, loading: false });
    }

    getLoad = async (clientId = this.state.session.user.client) => {
        this.setState({ clientId: clientId});
        this.loadReunionClient(this.state.page, clientId);
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            reunion: {
                ...this.state.reunion,
                [nam]: val
            }
        });
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadReunionClient(pageNumber, this.state.clientId);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.loadReunionClient(pageNumber, this.state.clientId);
    }

    toggleModal = (reunion = {}) => {
        document.getElementById("myForm").reset();
        document.getElementById("text-area").value = null;
        this.setState({ modal: !this.state.modal, reunion: reunion })
    };

    toggleModalView = (reunion = {}) => this.setState({ modalView: !this.state.modalView, reunion: reunion });

    save = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        await api.put(`/reunion/${this.state.reunion._id}`, this.state.reunion)
            .then(() => {
                this.loadReunionClient(this.state.page, this.state.clientId);
                swal({ icon: "success", title: "Sucesso!", text: "Avaliação cadastrada com sucesso." });
            })
            .catch(() => {
                this.loadReunionClient(this.state.page, this.state.clientId);
                swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a avaliação, tente novamente mais tarde." });
            });
        this.toggleModal();
    }

    mySelectHandler = (event) => {
        let val = event.target.value;
        this.setState({clientId: val})
        this.loadReunionClient(this.state.page, val);
    }

    render() {
        const reunions = this.state.arr;
        const {modal, modalView, reunion, session} = this.state

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4 ">
                            <Col xs={12} sm={(session.user.client.length > 1) ? 8 : 12} className="text-left">
                                <Header title="Reuniões" btn={false} />
                            </Col>
                            {/* {( session.user.client.length > 1 && 
                                <Col xs={12} sm={4} className="text-right">
                                    <div className="input-group mb-3">
                                        <Form.Control as="select" id="dropdown-basic-button22" value={this.state.clientId} className="dropdown-filter" name="clients" onChange={this.mySelectHandler} required>
                                            {session.user.client.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={4}>
                                            Título
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            Data
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            Sua Avaliação
                                        </Col>
                                        <Col xs={12} sm={2}>

                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {(reunions.length > 0) ? reunions.map((reunion, index) => (
                                    <CardBody key={index}>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={4}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Título:</span>{(reunion.title !== undefined) ? reunion.title : "Não informado."}
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data:</span>{Moment(reunion.date).format('DD/MM/YYYY')}
                                            </Col>
                                            <Col xs={12} sm={2}>
                                                <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Sua Avaliação:</span>
                                                {/* {(reunion.clientEvaluation === "Boa") ? <i className="material-icons" style={{ fontSize: "40px", color: "#0A0" }}>sentiment_very_satisfied</i> : <></>}
                                                {(reunion.clientEvaluation === "Regular") ? <i className="material-icons" style={{ fontSize: "40px", color: "#ffc600" }}>sentiment_dissatisfied</i> : <></>}
                                                {(reunion.clientEvaluation === "Ruim") ? <i className="material-icons" style={{ fontSize: "40px", color: "#A00" }}>sentiment_very_dissatisfied</i> : <></>} */}
                                                {(reunion.clientEvaluation === undefined) ? "Não avaliado" : reunion.clientEvaluation}
                                            </Col>
                                            <Col xs={12} sm={2} className="d-flex justify-content-end align-items-center">
                                                {(reunion.clientEvaluation === undefined) ?
                                                    <span className="c-pointer ml-3 text-primary font-weight-bold no-hover text-uppercase small" onClick={() => this.toggleModal(reunion)}>Avaliar</span>
                                                    :
                                                    <span className="c-pointer ml-3" onClick={() => this.toggleModalView(reunion)}><MaterialIcon icon="remove_red_eye" /></span>
                                                }

                                            </Col>
                                        </Row>
                                    </CardBody>
                                )) : 
                                    <p className="text-center text-muted mt-1">Nenhuma reunião encontrada.</p>
                                }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Avaliar reunião - {Moment(reunion.date).format('DD/MM/YYYY')} - {(reunion.title !== undefined) ? reunion.title : "Título não informado."}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Avaliação</Form.Label>
                                <Form.Control as="select" name="clientEvaluation" onChange={this.myChangeHandler} required>
                                    <option></option>
                                    <option value="Boa">Boa</option>
                                    <option value="Regular">Regular</option>
                                    <option value="Ruim">Ruim</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Comentários</Form.Label>
                                <Form.Control as="textarea" rows="3" id="text-area" name="clientComments" onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    Avaliar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal show={modalView}>
                    <Row className="mb-4">
                        <Col xs={10} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">Reunião - {Moment(reunion.date).format('DD/MM/YYYY')}</h5>
                        </Col>
                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModalView()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} className="text-center">
                            {(reunion.clientEvaluation === "Boa") ? <i className="material-icons" style={{ fontSize: "100px", color: "#0A0" }}>sentiment_very_satisfied</i> : <></>}
                            {(reunion.clientEvaluation === "Regular") ? <i className="material-icons" style={{ fontSize: "100px", color: "#ffc600" }}>sentiment_dissatisfied</i> : <></>}
                            {(reunion.clientEvaluation === "Ruim") ? <i className="material-icons" style={{ fontSize: "100px", color: "#A00" }}>sentiment_very_dissatisfied</i> : <></>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p className="text-justify px-0 px-sm-4">{reunion.clientComments}</p>
                        </Col>
                    </Row>
                </Modal>
                <Loading show={this.state.loading} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../../services/auth";
import Layout from "../../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Pagination from "../../../components/Pagination/index";
import swal from "sweetalert";
import NumberFormat from 'react-number-format';
import Loading from "../../../components/Loading/index";

export default class Contracts extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        arrInfo: {},
        page: 1,
        modal: false,
        clientId: '',
        contract: [],
        clients: [],
        companies: [],
        load: false,
        dateOfPayment: false
    }

    componentDidMount() {
        this.getLoad();
    }

    getLoad = async () => {
        this.getInfomation();
        this.getClients();
    }

    getClients = async () => {
        const clients = await api.get(`/client?sortBy=name&order=ASC`);
        this.setState({clients: clients.data});
    }

    getInfomation = async (page = 1) => {
        this.setState({load: true});
        let session = this.state.session;
        const response = await api.get(`/contract?page=${page}&company=${session.user.company._id}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ load: false, arr: docs, arrInfo, page});
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "file") {
            this.setState({
                contract: {
                    ...this.state.contract,
                    [nam]: event.target.files[0],
                    fileName: val
                }
            });
        }else{
            if(nam === "typeOfPayment" && val === "Pós"){this.setState({dateOfPayment: true})}
            if(nam === "typeOfPayment" && val === "Pré"){this.setState({dateOfPayment: false})}
            this.setState({
                contract: {
                    ...this.state.contract,
                    [nam]: val
                }
            });
        }
    }

    toggleModal = (contract = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, dateOfPayment: false, contract });
        if(contract.typeOfPayment === "Pós"){this.setState({ dateOfPayment: true });}
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
        if(this.state.session.user.role === "admin"){
            this.getClients()
        }
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInfomation(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInfomation(pageNumber);
    }

    delete = async (contract) => {
        swal({ title: "Atenção", text: "Deseja remover este contrato?", icon: "warning", buttons: ["Cancelar", "OK"], dangerMode: false, })
        .then(async (res) => {
            if (res) {
                await api.delete(`/contract/${contract._id}`)
                    .then(response => {
                        this.getInfomation(this.state.page);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };

    save = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const contract = this.state.contract;
        const formData = new FormData();
        const config = { headers: {'content-type': 'multipart/form-data'} }
        if(contract.file !== undefined){
            formData.append('file', contract.file);
        }
        if(contract._id === undefined){
            formData.append('client', contract.client);
        }
        formData.append('valueOfPayment', contract.valueOfPayment.replace(/^\D+/g, '').replace('.', '').replace(',', '.'));
        formData.append('typeOfContract', contract.typeOfContract);
        formData.append('typeOfPayment', contract.typeOfPayment);
        if(contract.dateOfPayment !== undefined){
            formData.append('dateOfPayment', contract.dateOfPayment);
        }
        formData.append('startDate', contract.startDate);
        formData.append('endDate', contract.endDate);
        if(contract.company === undefined || this.isObject(contract.company) === true){
            let session = this.state.session;
            formData.append('company', session.user.company._id);
            contract.company = session.user.company._id;
        }else{
            formData.append('company', contract.company);
        }
        if(contract._id !== undefined){
            if(this.isObject(contract.client) === true){
                formData.append('client', contract.client._id);
            }else{
                formData.append('client', contract.client);
            }
            await api.put(`/contract/${contract._id}`, formData, config)
                .then(response => {
                    this.getInfomation(this.state.page);
                    if(this.state.session.user.role === "admin"){
                        this.getClients()
                    }
                    this.toggleModal();
                    swal({ icon: "success", title: "Sucesso!", text: "Contrato editado com sucesso." });
                })
                .catch(err => {
                    this.getInfomation(this.state.page);
                    if(this.state.session.user.role === "admin"){
                        this.getClients()
                    }
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o contrato, tente novamente mais tarde." });
                    this.setState({load: false})
                    console.log(err);
                });
        }else{
            await api.post("/contract", formData, config)
                .then(response => {
                    this.getInfomation(this.state.page);
                    if(this.state.session.user.role === "admin"){
                        this.getClients()
                    }
                    this.toggleModal();
                    swal({ icon: "success", title: "Sucesso!", text: "Contrato cadastrado com sucesso." });
                })
                .catch(err => {
                    this.getInfomation(this.state.page);
                    if(this.state.session.user.role === "admin"){
                        this.getClients()
                    }
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar o contrato, tente novamente mais tarde." });
                    this.setState({load: false})
                    console.log(err);
                });
        }
    }

    render() {
        const {clients, contract, modal} = this.state
        const contracts = this.state.arr;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Row className="mb-4">
                            <Col xs={12} sm={12} className="text-left">
                                <Header title="Contratos" btn={true} callbackParent={this.toggleModal}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                        <Breadcrumb.Item href="#">Administrativo</Breadcrumb.Item>
                                        <Breadcrumb.Item href={`/administrativo/contratos`} active>Contratos</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Header>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={3}>Cliente</Col>
                                        <Col xs={12} sm={2}>Tipo de Contrato</Col>
                                        <Col xs={12} sm={2}>Tipo de Pagamento</Col>
                                        <Col xs={12} sm={2}>Data de Fim</Col>
                                        <Col xs={12} sm={2}>Contrato</Col>
                                        <Col xs={12} sm={1}></Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                            {(this.state.arrInfo.total > 0) ? 
                            contracts.map((contracts, index) => (
                                <CardBody key={index}>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={3}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Cliente:</span>{contracts.client.name}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo de Contrato:</span>{contracts.typeOfContract}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Tipo de Pagamento:</span>{contracts.typeOfPayment} {(contracts.typeOfPayment === "Pós") ? `- Todo dia ${contracts.dateOfPayment}` : <></>}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Data de Fim:</span>{new Date(`${contracts.endDate} 01:00:00`).toLocaleDateString()}
                                        </Col>
                                        <Col xs={12} sm={2}>
                                            <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Acessar:</span>
                                            {(contracts.hasOwnProperty('file') !== false && contracts.file !== '') ? <a href={contracts.file} target="_blank" rel="noopener noreferrer" className="not-underline">Clique aqui</a> : "Ignorado"}
                                        </Col>
                                        <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                            <span className="c-pointer ml-3" title="Editar" onClick={() => this.toggleModal(contracts)}><MaterialIcon icon="edit" /></span>
                                            <span className="c-pointer ml-3" title="Remover" onClick={() => this.delete(contracts)}><MaterialIcon icon="close" /></span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            ))
                            :
                                <CardBody>
                                    <Row className="align-items-center text-muted">
                                        <Col xs={12} sm={12} className="text-center">
                                            Não há contratos cadastrados.
                                        </Col>
                                    </Row>
                                </CardBody>
                            }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(contract._id !== undefined) ? "Editar contrato" : "Adicionar contrato"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>                       
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Cliente <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="client" value={(contract.client !== undefined) ? ((contract.client._id !== undefined) ? contract.client._id : contract.client) : contract.client} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {clients.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Valor do pagamento <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <NumberFormat className='form-control' name="valueOfPayment" value={contract.valueOfPayment || null} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} prefix={'R$ '} onChange={this.myChangeHandler} required/>
                            </Col>
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo de Contrato <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="typeOfContract" value={contract.typeOfContract} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Gestão", "Processos"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={(this.state.dateOfPayment) ? 3 : 6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Tipo de Pagamento <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control as="select" name="typeOfPayment" value={contract.typeOfPayment} onChange={this.myChangeHandler} required >
                                    <option></option>
                                    {["Pós", "Pré"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        {(this.state.dateOfPayment) && 
                            <Col xs={12} sm={3} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data de Pagamento</Form.Label>
                                <Form.Control type="text" name="dateOfPayment" defaultValue={contract.dateOfPayment} onChange={this.myChangeHandler} required />
                            </Col>
                        }
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data de Início <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="startDate" defaultValue={contract.startDate} onChange={this.myChangeHandler} required />
                            </Col>
                            <Col xs={12} sm={6} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Data do Fim <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="date" name="endDate" defaultValue={contract.endDate} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Arquivo do Contrato <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <label htmlFor="file" className="c-pointer form-label w-100 border mb-0 d-flex align-items-center justify-content-between">
                                    <span className="ml-2 color-grey" style={{ fontSize: '12px' }}>{(contract._id === undefined) ? contract.fileName : ((contract.fileKey !== undefined) ? contract.fileKey.substring(0, 30) : contract.fileName.substring(0, 30))}</span>
                                    <span className="p-2 bkg-primary color-white">Selecionar Arquivo</span>
                                    <input type="file" name="file" id="file" accept=".pdf" className="d-none" onChange={(e) => this.myChangeHandler(e)} />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" className="small" type="submit">
                                    {(contract._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}
import React, { Component } from "react";
import { decodeToken } from "../../services/auth";
import api from "../../services/api";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";

export default class ChangeCompany extends Component {

    state = {
        session: decodeToken(),
        companies: [],
        company: ""
    }

    componentDidMount(){
        this.getUserCompanies();
    }

    myChangeHandler = async (event) => this.setState({ [event.target.name]: event.target.value });

    selectCompany = async (event) => {
        event.preventDefault();
        const { company = "" } = this.state;
        await api.post("/select-company", { company })
            .then(response => {
                localStorage.setItem("TOKEN_KEY", response.data.token);
                window.location.href = "/dashboard";
            }).catch(error => {
                console.log({ error });
            })
    }

    getUserCompanies = async () => {
        const { user } = this.state.session;
        const response = await api.get(`/user/${user._id}`);
        this.setState({ companies: response.data.company })
    }

    render() {
        const { companies = [] } = this.state;

        return (
            <Container fluid className="d-flex justify-content-center align-items-center h-100 w-100">
                <Col xs={12} sm={6} className="row d-flex justify-content-center align-items-center">
                    <h2 className="mb-5 text-primary">Selecione a agência que deseja visualizar:</h2>
                    <Form className="w-100" onSubmit={this.selectCompany}>
                        <Col xs={12} className="d-flex align-items-center mb-3">
                            <Form.Control as="select" name="company" onChange={this.myChangeHandler} required>
                                <option></option>
                                {companies.map((item, index) => (
                                    <option key={index} value={item._id}>{item.name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" type="submit" className="text-uppercase">
                                Selecionar agência
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Container>
        );
    }
}
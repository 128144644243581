import React, { Component } from "react";
import { decodeToken } from "../../../services/auth";
import Layout from "../../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Form from "react-bootstrap/Form";
import Modal from "../../../components/Modal";
import MaterialIcon from "material-icons-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CardHeader from "../../../components/CardHeader/index";
import CardBody from "../../../components/CardBody";
import Pagination from "../../../components/Pagination/index";
import swal from "sweetalert";
import Loading from "../../../components/Loading/index";

export default class Agencies extends Component {

    state = {
        session: decodeToken(),
        arr: [],
        agency: [],
        arrInfo: {},
        page: 1,
        message: 'Token ou Key inválidos.',
        modal: false,
        error: false,
        load: false
    }

    componentDidMount() {
        this.getCompanies();
    }

    getCompanies = async (page = 1) => {
        this.setState({ load: true });
        const response = await api.get(`/company?paginate=true&page=${page}`);
        const { docs, ...arrInfo } = response.data;
        this.setState({ load: false, arr: docs, arrInfo, page });
    }

    myChangeHandler = (event) => {
        event.persist();
        const { name, value } = event.target;
        this.setState({
            agency: {
                ...this.state.agency,
                [name]: value
            }
        });
    }

    toggleModal = (agency = {}) => {
        document.getElementById("myForm").reset();
        this.setState({ modal: !this.state.modal, error: false, agency: agency });
        document.body.classList.toggle("overflow-hidden");
        document.getElementById("wrapper").classList.toggle("blur");
    }

    prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.getInfomation(pageNumber);
    }

    nextPage = () => {
        const { page, arrInfo } = this.state;
        if (page === arrInfo.pages) return;
        const pageNumber = page + 1;
        this.getInfomation(pageNumber);
    }

    save = async (event) => {
        event.preventDefault();
        this.setState({ load: true, error: false });
        let agency = this.state.agency;
        if (agency._id !== undefined) {
            await api.put(`/company/${agency._id}`, agency)
                .then(() => {
                    this.getCompanies(this.state.page);
                    swal({ icon: "success", title: "Sucesso!", text: "Agência editada com sucesso." });
                    this.toggleModal({});
                }).catch(error => {
                    this.getCompanies(this.state.page);
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar a agência, tente novamente mais tarde." });
                    console.log(error)
                    this.toggleModal({});
                });
        } else {
            await api.post(`/company`, agency)
                .then(() => {
                    this.getCompanies(this.state.page);
                    this.toggleModal({});
                    swal({ icon: "success", title: "Sucesso!", text: "Agência cadastrada com sucesso." });
                }).catch(error => {
                    this.getCompanies(this.state.page);
                    console.log(error);
                    this.toggleModal({});
                    swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar a agência, tente novamente mais tarde." });
                });
        }
    }

    render() {
        const { agency, modal } = this.state
        const agencies = this.state.arr;

        return (
            <>
                <Layout>
                    <Container fluid>
                        <Header title="Agências" btn={true} callbackParent={this.toggleModal}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/administrativo/agencias`} active>Agências</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header>
                        <Row>
                            <Col xs={12}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={11}>Nome</Col>
                                        <Col xs={12} sm={1}></Col>
                                    </Row>
                                </CardHeader>
                            </Col>
                            <Col xs={12}>
                                {(this.state.arrInfo.total > 0) ?
                                    agencies.map((agencies, index) => (
                                        <CardBody key={index}>
                                            <Row className="align-items-center text-muted">
                                                <Col xs={12} sm={11}>
                                                    <span className="d-inline-flex d-sm-none text-primary font-weight-bold small mr-1">Nome:</span>{agencies.name}
                                                </Col>
                                                <Col xs={12} sm={1} className="d-flex justify-content-end align-items-center">
                                                    <span className="c-pointer ml-3" title="Editar" onClick={() => this.toggleModal(agencies)}><MaterialIcon icon="edit" /></span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    ))
                                    :
                                    <CardBody>
                                        <Row className="align-items-center text-muted">
                                            <Col xs={12} sm={12} className="text-center">
                                                Não há agências cadastradas.
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Col>
                        </Row>
                        <Pagination page={this.state.page} arrInfo={this.state.arrInfo} callbackNext={this.nextPage} callbackPrev={this.prevPage} />
                    </Container>
                </Layout>
                <Modal show={modal}>
                    <Row className="mb-4">
                        <Col xs={6} className="d-flex align-items-center justify-content-start">
                            <h5 className="mb-0 text-primary font-weight-bold">{(agency._id !== undefined) ? "Editar Agência" : "Adicionar Agência"}</h5>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-end">
                            <span className="c-pointer" onClick={() => this.toggleModal()}><MaterialIcon icon="close" /></span>
                        </Col>
                    </Row>
                    <Form onSubmit={this.save} id="myForm">
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <Form.Label className="text-primary font-weight-bold small mb-2">Nome <sup className="ml-1 text-danger">*</sup></Form.Label>
                                <Form.Control type="text" name="name" defaultValue={agency.name} onChange={this.myChangeHandler} required />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="d-flex align-items-center justify-content-end">
                                {(this.state.error) ? (
                                    <small className="text-danger mr-3">{this.state.message}</small>
                                ) : <></>}
                                <Button variant="primary" className="small" type="submit">
                                    {(agency._id !== undefined) ? "Salvar" : "Adicionar"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Loading show={this.state.load} />
            </>
        );
    }
}